import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import beltconveyor from '../images/beltconveyor.png';

function BeltConveyor() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={beltconveyor} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BELT CONVEYOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The belt conveyor is used to transfer the product form a working line to another into the processing plant. The frame is made in stainless steel and the belt is in non-toxic plastic</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">2.00</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,37 - 1,5</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">100</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default BeltConveyor
