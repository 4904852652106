import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import vegetablecuttingmachine from '../images/vegetablecuttingmachine.png';

function VegetableCuttingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>VEGETABLE CUTTING MACHINE</h1>
            <img src={vegetablecuttingmachine} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>DESCRIPTION</h2>
            <h5>This machine is a commercial stainless steel high-efficiency vegetable cutting machine, which is used in the canteen. It is used in the hotel and canteen for onions, green onions, purple cabbage, potatoes, carrots,cucumber and other vegetables. It can be used for shred, sliced, diced and sliced vegetables.High efficiency and fast, multi-purpose, cost-effective, high cutting effect.And it has beautiful appearance, easy to clean, easy to operate, safe and reliable.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default VegetableCuttingMachine

