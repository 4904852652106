import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import shrinktank from '../images/shrinktank.png';
import diptank from '../images/diptank.png';

function ShrinkDipMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>SHRINK TANK</h1>
            <img src={shrinktank} />
            <h5>Our compact ST-10 is a semi-automatic shrink dip tank. Used in conjunction with a vacuum chamber machine and shrink bags, this machine is ideal for small to medium-sized operations. By means of hot water submersion, the shrinking process is performed with the simple push of a button. Switch into automatic mode and our machines will cycle on their own with a preset delay to allow time for loading. An easy-to-use temperature control and timer provides trouble-free operation. Quality is evident in the robust construction of our machines which are built with a stainless steel frame and body. Promarks puts our best into every machine we make, including the ST-10.</h5>
            <br/>
            <br/>
            <h5>Standard Features:</h5>
            <h5>* Stainless Steel Construction</h5>
            <h5>* Efficient Electric Heating</h5>
            <h5>* Programmable Temperature Control</h5>
            <h5>* Simple Push Button Cycle Operation</h5>
            <h5>* Low Water Shut-Off Protection and Warning Light</h5>
            <h5>* Easy Access for Cleaning and Maintenance</h5>
            <h5>* High Efficiency Insulation for Heat Retention</h5>
            <h5>* Easy Fill and Drain Tank</h5>
            <br/>
            <br/>
            <h5>Note – Power Requirements:</h5>
            <h5>* 230/60Hz, 3-phase</h5>
            <h5>* 50 Amp</h5>
            <br/>
            <br/>
            <h5>Options:</h5>
            <h5>* Custom Voltage</h5>
            <br/>
            <br/>
            <h1>DIP TANK</h1>
            <img src={diptank} />
            <h5>The ST-700 is a fully automatic hot water dip tank for higher output applications. Designed to be used in line with our BT series of belted vacuum chamber machines or as a stand alone unit, the ST-68 offers the performance you want in a dip tank. Maximum shrinkage is achieved by means of complete submersion in the temperature controlled water.</h5>
            <h5>The touchscreen interface and PLC controls allow for simple adjustment and monitoring of current settings. Fully enclosed and sealed electrical boxes insure years of trouble-free operation and the stainless steel construction provides superior corrosion resistance. The shock-assisted hinged lid gives access to the removable stainless steel conveyor for easy cleaning and inspection.</h5>
            <br/>
            <br/>
            <h5>Standard Features:</h5>
            <h5>•  Stainless Steel Construction for Durability</h5>
            <h5>•  Electric Heating for Trouble-Free and Simple Operation</h5>
            <h5>•  Adjustable Temperature PLC/HMI Controls</h5>
            <h5>•  Low Water Detection with Auto Fill</h5>
            <h5>•  Pivoting control arm for efficiency</h5>
            <h5>•  High Efficiency Insulation</h5>
            <h5>•  Removable Conveyor</h5>
            <h5>•  Adjustable Leveling Feet</h5>
            <br/>
            <br/>
            <h5>Note – Power Requirements:</h5>
            <h5>•  220/60Hz, 3-phase</h5>
            <h5>•  80 Amp</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ShrinkDipMachine;
