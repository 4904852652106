import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import headcutter from '../images/headcutter.png';

function HeadCutter() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={headcutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>HEAD CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine cuts the head of the chickens. It can be installed on any kind of killing line and it is suitable for any capacity. It does not require to be synchronised with the overhead conveyor. The structure guide-bars are made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">8.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.150</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">650</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,55</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">110</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">0,6</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default HeadCutter
