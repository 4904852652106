import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import metaldetector from '../images/metaldetector.png';
import metaldetector1 from '../images/metaldetector1.PNG';
import metaldetector2 from '../images/metaldetector2.PNG';

function MetalDetectorMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>METAL DETECTOR</h1>
            <img src={metaldetector} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Description</h2>
            <h5>Metal Detector meets the various needs of our customers in all fields and has a long track record and trust.We can design and manufacture not only general-purpose conveyor type but also special types corresponding to granule, powder and liquid inspection according to inspection products and installation environment.</h5>
            <h5>We can also offer various types of rejecter and options that are combined to our Checkweigher and magnet removal equipment.</h5>
            <h5>•  Operating status and Detection alert are clearly indicated with color touch screen.</h5>
            <h5>•  Multiple frequency makes higher sensitivity.</h5>
            <h5>•  100,000 of historical record can be saved and managed by USB flash memory.</h5>
            <h5>•  Sanitary design for water environment</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Slandered Measurements</h2>
            <img src={metaldetector1} />
            <br/>
            <h5>1. Effective Height can be adjusted upon request with order</h5>
            <h5>2. Sensitivity may depend upon inspection product and environment, etc.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Press Measurements</h2>
            <br/>
            <img src={metaldetector2} />
        </div>
     </div>
      <Footer/>
    </div>
  )
}

export default MetalDetectorMachine
