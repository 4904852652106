import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import bridsconscutupline from '../images/bridsconscutupline.png';

function BirdsConesCutUpLine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={bridsconscutupline} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BIRDS CONE CUT UP LINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The cones cut up line used for cutting and deboning the chickens is complete with electrical panel, securities and CE certification. the lenght of the line can be extended to increase the capacity. The frame is made of stainless steel and the cones in PVC food approved. </h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">1.000</div>
                            <div class="box len">B / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">5.730</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">750</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,37</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">310</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">3,5</div>
                            <div class="box len">M<span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BirdsConesCutUpLine
