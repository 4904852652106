import React from 'react';
import '../styles/AboutSection/aboutsection.css';

function AboutSection() {
  return (
    <div className='aboutsection'>
      <div className='aboutsection-inner'>
        <div className='one'>About Us</div>
        <div className='two'>
            <div className='two-inner'>
                <div className='inner'>
                   <h2>Our Beginning</h2>
                    <div className='para'>
                        <h5>Founded In 2014, Euromate Foodtech Pvt Ltd, Sri Lanka is located in No 399/3, Kandy Rd, Kirillawala, Kadawatha. 
                                It Is the Machinery Importer of Meat / Food Processing & Packaging Equipment Supplier in Sri Lanka and has earned 
                            a Good Reputation as a Reliable and the Dependable Machinery and Material Supplier. 
                            It Has Qualified And Experienced Sales & Marketing Team Backed Up By Efficiently Skilled, Trained & Expert 
                            Engineering Team. 
                            We Also Undertake Plant And Equipment Installation, Supply Of Spares, To Do After Sales Service, And More..... 
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div className='three'>
            <div className='three-inner'>
                <div className='inner'>
                   <h2>Our Intention</h2>
                    <div className='para'>
                        <h5>
                            It is the Machinery Importer of Meat / Food 
                            Processing and packaging equipment supplier in 
                            Sri Lanka. We are reliable and the dependable 
                            machinery and material supplier. <br/> We have experience and fully trained team and we are fully 
                            supported by our mother company and brand partners from 
                            overseas.
                        </h5>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
