import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import tubesealer from '../images/tubesealer.png';

function TubeSealer() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>TUBE SEALER</h1>
            <img src={tubesealer} />
            <h5>The Promarks Heat PTS-36 Production Tube Sealer features a worm gear system for rapid/precise adjustment from the smallest tubes to the largest tubes. Heat and cooling cycles are accurately controlled by solid state analog timers and the built in air pressure regulator insures proper jaw pressure. The fed through design allow tube racks to be inserted or removed from either direction. With available options such as a self-contained compressed air system, temperature controlled seal system as well as water or air cooled seal bars there is no doubt that this machine can be configured to meet your specific tube scaling needs.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Seal Type: Single Element</h5>
            <h5>•  Seal Head: Fixed Connection</h5>
            <h5>•  Control System: Variable Range Analog Timers</h5>
            <h5>•  Construction</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Seal Width: Up to 1/2”/12mm</h5>
            <h5>•  Seal Type: Bi-Active</h5>
            <h5>•  Seal Head: Quick Disconnect</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default TubeSealer
