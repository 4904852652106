import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import retort from '../images/retort.png';
import hotretort1 from '../images/hotretort1.PNG';
import hotretort2 from '../images/hotretort2.PNG';
import hotretort3 from '../images/hotretort3.PNG';
import foodretort from '../images/foodretort.PNG';
import foodretort2 from '../images/foodretort2.PNG';
import foodretort3 from '../images/foodretort3.PNG';
import pilotretort1 from '../images/pilotretort1.PNG';
import miniretort1 from '../images/miniretort1.PNG';
import miniretort2 from '../images/miniretort2.PNG';

function RetortMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
          <div class="inner-canwashermachine">
              <h1>RETORT MACHINE</h1>
              <img src={retort} />
              <br/>
              <h5>* Counter Pressure Retort</h5>
              <h5>* Steam Air Retort Sterilizer</h5>
              <h5>* Hot Water Spray Retort</h5>
              <h5>* Food Processing Retort Machine</h5>
              <h5>We, at Super Combustion Techonology, are manufacturing retorts for ready to eat, ready to cook foods in India. Presently we are the most trusted and reliable suppliers of steam air counter pressure retorts(steam sterilizers). We strive to provide quality retorts that provides performance par excellence at most affordable cost for our Customers. Our product range includes, Counter Pressure Retort, Steam Air Retort Sterilizer, Hot Water Spray Retort and Food Processing retort Machine.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>(1.) Counter Pressure Retort Machine</h2>
              <h5>The Retort Pouches / tray are part of Food Processing Machinery. A Retort pouch is made of Plastic and Metal foil laminated pouch with 3 or 4 wide seals usually created by aseptic processing allowing for the Sterile packaging of a wide variety of drinks, that can range from water to fully cooked, thermo-stabilized meals such as Ready to Eat meals that can be eaten cold, warmed by submersing in Hot water or through the use of heater or microwave. In this Counter Pressure Retort food product which is first prepared (Raw or cooked) is sealed into a retort pouch. The pouch is then heated to 116C - 121C for several minutes (Depending on food recipes) under high counter pressure inside Retort / Autoclave.
  The Counter Pressure Retort reliably kills all commonly occurring microorganisms (particularly clostridium botulinum) preventing it from spoiling.
  Therefore Shelf life of Retort products will be 12 months to 36 months in ambient conditions without refrigeration.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>(2.) Hot Water Spray Retort</h2>
              <img src={hotretort1} />
              <br/>
              <h5>We can provide you with the premium hot water spray retorts. The batch Hot water Spray Retort heated with water under pressure is perfect for beverages and other food industries.</h5>
              <h5>In this Hot Water Spray Retort Steam boils the water in a separate tank and make it 121 C inside the food / bottles center area. Since it is indirect methods of making the product sterilize. It will give good texture and taste.Boiler will remain active during making water 121 C and then product at 121 C.</h5>
              <img src={hotretort2} />
              <br/>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Special Features</h2>
              <h5>•  The Hot Water Spray Retorts allow for pasteurization with minimum process water, direct heating and indirect cooling. These will be no touch between cooling water and process water, successfully avoiding secondary contamination.</h5>
              <h5>•  The Hot Water Spray Retorts also boast perfect pressure control. During the whole processing procedure, the pressure is constantly adjusted to adapt to the changing pressure inside product package. They ideally keep package deformation to a minimum, well suitable for Gas packaging and two piece can packaging.</h5>
              <h5>•  The noise of Hot Water rain retorts is extremely low creating a quiet and comfortable working environment.</h5>
              <h5>•  The advanced and suitable DELTA PLC control system ensures our clients are able to timely locate replacement parts of the batch retorts across the world reducing down time.</h5>
              <h5>•  The Hot Water Spray Retort / Sterilization equipment makes it convenient to configure automatic corollary equipment for unmanned food workshops in the future.</h5>
              <h5>•  The whole sterilization process adopts PLC fully automatic linearity control via the PLC control, the temperature difference of thermal distribution is limited within ± 0.5C. The Hot Water Spray Retort Steriliser allow for multi-stage heating, effectively saving energy. It also has an additional F value recorder (optimal) capable of storing 100 scheduled processes.</h5>
              <br/>
              <img src={hotretort3} />
              <br/>
              <h5>•  Construction in SS316/304</h5>
              <h5>•  Mineral Wool insulation.</h5>
              <h5>•  SS cladding</h5>
              <h5>•  Unique distribution lines for Air and steam.</h5>
              <h5>•  Unique spray system for Coolant water.</h5>
              <h5>•  Fo value monitoring</h5>
              <h5>•  Mist  like  spray  results  in  quick cooling (8-10 mins).</h5>
              <h5>•  Most Efficient.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>(3.) Food Retort</h2>
              <img src={foodretort} />
              <br/>
              <h5>A food processing machine works towards preservation of food, and thermal sterilization is the most widely available process.</h5>
              <h5>A food processing machine for sterilization and preservation extends the shelf life of food products. A food processing machine is a unit/chamber where food and beverages are heated at high temperature and then held at that temperature for the time it takes to kill microorganisms. A sterile product does not contain any microorganisms. The process involved in the food processing machine includes preparing the food, filing the container/pouches, sealing of the container/pouches, placing the batch in the retort for adequate heat, and cooling of the container.</h5>
              <h5>The Counter Pressure Retort reliably kills all commonly occurring microorganisms (particularly clostridium botulinum) preventing it from spoiling.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Full Automatic Static Steam HotWater Spray Retort</h2>
              <img src={retort} />
              <br/>
              <h5>This kind of retort sterilizer is suitable for various of heat resistant packages and we strongly
  recommend you to buy this type. Applicable Scope,</h5>
              <h5>•  Glass container: glass jar, glass bottle</h5>
              <h5>•  Metal container: tin can</h5>
              <h5>•  Plastic container: PP bottle, HDPE bottle</h5>
              <h5>•  Soft package: Aluminum foils bag, transparent bag, vacuum bag, retort pouches, doy-packs ect.</h5>
              <img src={foodretort2} />
              <h5>•  Suitable for all heat resistant package material including glass container;</h5>
              <h5>•  B. Safety interlock Device for quick closure of retort, to ensure the safety of operator;</h5>
              <h5>•  Quick heating, only need about 6-12 minutes to reach the sterilization temperature;</h5>
              <h5>•  Uniform heat distribution, no blind angle;</h5>
              <h5>•  Adopting the multi-phase process for rising and dropping of temperature, minimize the temperature difference between the surface and center of packed food, maintain the flavor and nutrition of food. Mild heating and cooling process can protect the fragile container and glass container;</h5>
              <h5>•  Direct heating by steam and indirect cooling by continually recycling the water through the heat exchanger, avoiding secondary contamination to the food package;</h5>
              <h5>•  Employing soft water for sterilization to avoid pollution to the surface of package; no special requirements for cooling water which reduce the cost of sterilization;</h5>
              <h5>•  Siemens PLC automatic control system and touch screen, easy to operate;</h5>
              <h5>•  Pressure control system can adjust the pressure in the tank automatically and precisely in case the packages break or deformation during sterilization process;</h5>
              <h5>•  Monitoring temperature ensures food safety, saves energy, reduces production time and assists in providing the best quality product.</h5>
              <h5>•  System will make alarm calls automatically when the temperature, the pressure, the water level, or any other kinds of error occur.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Working Principle</h2>
              <h5>First filling the retort with a small amount of process water and the water level is maintained below the basket bottom level. Then the process water is drawn from the bottom of the retort by a high capacity circulation pump and spray from nozzles located along the top and sides of the retort.
  Simultaneously the steam valve opens and the steam is introduced into the retort through the steam spreader on the bottom of the retort directly heating that process water spraying from nozzles and making the temperature inside retort evenly.
  And when the temperature inside retort reaches the programmed sterilization temperature, it will be kept during the sterilization process. At the same time the pressure in the retort also will be kept by adding or venting compressed air as needed. Finally the cooling water through heat exchanger will continually make that sterilization water cool down until the sterilization process is finished.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Process Flow Chart</h2>
              <img src={foodretort3} />
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>(4.) Pilot Retort</h2>
              <img src="../images/pilotretort.png" />
              <h5>Super Combustion Technology Retorts brings to you R&D Retort, Pilot Retort, and Mini Retort: portable, cost-effective and energy efficient.</h5>
              <h5>In order to cater to innovation and training needs of enterprises in the field of food and beverage processing, we bring our European technology-based food retort in compact form: the R&D Retort, Pilot Retort, and Mini Retort.</h5>
              <h5>This R&D Steam Air Retort, R& D Retort, Pilot Retort, Mini Retort is manufactured for your training, innovations, educational and lab needs and is now available for the first time in India.</h5>
              <h5>The portability and compact designing of the2100 series saves floor space. Owning to its nature of use, the R&D Steam Air Retort, R & D Retort, Pilot Retort, and Mini Retort is easy to move, set-up and use. Cutting-edge designing which saves on space allows it to be maneuverer through doors for easy transport. The retort is stable and locked in position for operations. Post-use, the Retort can be stowed away.</h5>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Techincal Parameter</h2>
              <img src={pilotretort1} />
              <br/>
              <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Mini Retort</h2>
              <img src={miniretort1} />
              <br/>
              <h5>Super Combustion Technology Retorts brings another landmark innovation in the field of food processing. In our decades of experience in the industry, we have had first-hand understanding of the various kinds of needs of different manufacturers or food processors based on their scale of production and requirement.</h5>
              <h5>In order to cater to small and medium enterprises foraying or already existing in the field of food processing, we bring our European technology based food retort in smaller variants: the Mini Retort.</h5>
              <br/>
              <img src={miniretort2} />
              <br/>
              <h5>This steam air retort manufactured for small and medium enterprises and educational/training purposes is now available for the first time in India.
  The specifications of this Mini Retort make it ideal for smaller batches. The total volume of this retort is 670 liters.
  It can process close to 100 kg of food/beverages in one batch.
  The batch time is less than an hour.
  The retort is fully automatic and PLC operated.
  It is easy to use and does not require specialized technical expertise.
  At present we are manufacturing this Mini Retort for clients in the India.</h5>
          </div>
      </div>
      <Footer/>
    </div>
  )
}

export default RetortMachine
