import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import vaccumpump from '../images/vaccumpump.png';
import '../styles/css/birdsproducts.css';


function VaccumPump() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
    <div class="birds-inner">
        <div class="left">
            <div class="img">
                <img src={vaccumpump} alt="product image"/>
            </div>
        </div>
        <div class="right">
            <div class="name">
                <h2>VACUUM PUMP</h2>
            </div>
            <div class="description">
                <h2>Description</h2>
                <h5>These vacuum pumps, a vane type impeller, are used to produce the vacuum needed into the plant. The rotor is supported by grease lubricated ball bearings.The pump is assembled on a “safety tank” to prevent that solid parts enter into the pump. The casing in cast iron, impellers in bronze and shafts in stainless steel.</h5>
            </div>
            <div class="data">
                <h2>Technical Data</h2>
                <div class="table">
                    <div class="row">
                        <div class="box">Model</div>
                        <div class="box len">23</div>
                        <div class="box len">25</div>
                        <div class="box len">l</div>
                    </div>
                    <div class="row">
                        <div class="box">Vaccum capacity</div>
                        <div class="box len">535</div>
                        <div class="box len">810</div>
                        <div class="box len">m <sup>3</sup>/h</div>
                    </div>
                    <div class="row">
                        <div class="box">Water cons. at 3 bar</div>
                        <div class="box len">1,9</div>
                        <div class="box len">2,5</div>
                        <div class="box len">m <sup>3</sup>/h</div>
                    </div>
                    <div class="row">
                        <div class="box">Power Installed</div>
                        <div class="box len">15</div>
                        <div class="box len">22</div>
                        <div class="box len">kw</div>
                    </div>
                    <div class="row">
                        <div class="box">Total weight</div>
                        <div class="box len">330</div>
                        <div class="box len">445</div>
                        <div class="box len">kg</div>
                    </div>
                    <div class="row">
                        <div class="box">Shipping volume</div>
                        <div class="box len">0,22</div>
                        <div class="box len">0,40</div>
                        <div class="box len">M <sup>3</sup></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      <Footer/>
    </div>
  )
}

export default VaccumPump
