import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import containerwasher from '../images/containerwasher.png';

function ContainerWasher() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={containerwasher} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CONTAINER WASHER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic container washer is used to wash the containers or the stacked empty crates used to transport the live birds. At the first washing stage the water is recycled after being pass throught a filter. then the crates are sprayed with clean disinfected water. The frame is made of hot dip galvanized steel or upon request in st.st. while teh filter is made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Dimentione 4 floor</div>
                            <div class="box len">1.160 * 1.200 * 1.210</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Net Weight 4 floor</div>
                            <div class="box len">170</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Dimensione 5 floor</div>
                            <div class="box len">1.160 * 1.200 * 1.460</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Net Weight 5 floor</div>
                            <div class="box len">195</div>
                            <div class="box len">kg</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ContainerWasher
