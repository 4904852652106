import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import img from '../images/brinemixer.png';

function MetalDetector() {
  return (
    <div>
      <Navbar/>
      <Footer/>
    </div>
  )
}

export default MetalDetector
