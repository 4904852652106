import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import fillertingmachine from '../images/fillertingmachine.png';

function FilletingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={fillertingmachine} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>FILLETING MACHINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic breast filleting machine C15 is specially developed to debone the breast caps. The machine is manually loaded and operates mechanically. On the machine can be add two extra modules, an automatic deskinning unit and a half breast two blades splitter. To get the best yield the weight difference of the birds in the flock cannot exceed 500gr and the cap should be cut at the wings joint. The machine is made of stainless steel and food approoved PVC. The breast deboner is complete with electric control panel, safety device and CE certificate.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">2.500</div>
                            <div class="box len">B / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">3.700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">900</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">6</div>
                            <div class="box len">M<span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FilletingMachine
