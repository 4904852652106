import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css'; 
import brinemixer from '../images/brinemixer.png';
import bandsaw1 from '../images/band saw.png';
import meatflattenmachine1 from '../images/meatflattenmachine1.PNG';
import foamingmachine1 from '../images/foamingmachine1.PNG';
import brineinjector from '../images/brineinjector.PNG';
import mdmmachine3 from '../images/mdmmachine3.png';
import meatballmachinemeat1 from '../images/meatballmachinemeat1.PNG';
import metaldetector from '../images/metaldetector.png';
import mixergrinder from '../images/mixergrinder.PNG';
import sausagecutter from '../images/sausagecutter.png';
import sausageloadingmachine from '../images/sausageloadingmachine.png';
import slicer from '../images/slicer.png';
import fishsmokingchamber from '../images/fishsmokingchamber.png';
import tumbler from '../images/tumbler.png';
import vaccumfillers3 from '../images/vaccumfillers3.PNG';
import { Link } from 'react-router-dom';

function MeatProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1>Meat Products</h1>
        </div>
        <div className='products'>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={brinemixer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Brine Mixer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/brinemixer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={bandsaw1}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Band Saw
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3.5} />
                <CardActions>
                    <Link to='/bandsaw'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={meatflattenmachine1}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Meat Flattern Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/meatflattenmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={foamingmachine1}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Foaming, Coating Processing Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4.5} />
                <CardActions>
                    <Link to='/foamingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={brineinjector}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Brine Injector
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/brineinjector'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={mdmmachine3}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    MDM / MSM Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/mdmmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={meatballmachinemeat1}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Meat Ball Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/meatballmachinemeat'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={metaldetector}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Metal Detector Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={1} />
                <CardActions>
                    <Link to='/metaldetector'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={mixergrinder}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Mixer Grinder
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/mixergrinder'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={sausagecutter}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Saussage Cutter
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/sausagecutter'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={sausageloadingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Sausage Loading Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/sausageloadingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={slicer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Slicer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/slicer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishsmokingchamber}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Smoking, Scalding Chamber
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/fishsmokingchamber'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={tumbler}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Tumbler
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/tumbler'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={vaccumfillers3}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Vaccume Fillers
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={1} />
                <CardActions>
                    <Link to='/vaccumfillers'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
      </div>
    </div>
  )
}

export default MeatProducts
