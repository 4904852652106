import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import constantheatsealer from '../images/constantheatsealer.png';

function ConstantHeatSealer() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>CONSTANT HEAT SEALER</h1>
            <img src={constantheatsealer} />
            <br/>
            <h5>Promarks’ HDCH Heavy Duty Constant Heat Sealer is an ideal for laboratory testing of thermoplastic and laminated materials. Each unit is available with a bar sealer, heat platen or machined tooling for contour shapes. Standard features include pressure regulator and gauge, a variable timer, digital temperature control and a low-pressure safety descent. All construction is heavy duty for high-pressure sealing applications. Powder coat finish makes the HDCH clean room compatible. 110 or 220 Volt is available.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features</h2>
            <h5>•  Stainless Steel Housing</h5>
            <h5>•  Vacuum Nozzle</h5>
            <h5>•  Solid State Timers</h5>
            <h5>•  220 Volts/1Ph/60HZ</h5>
            <h5>•  Custom Options Available</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options</h2>
            <h5>•  Gas Flush</h5>
            <h5>•  Self-Contained Compressed Air System</h5>
            <h5>•  Bi-Active Seal</h5>
            <h5>•  Gas Flush</h5>
            <h5>•  20″ or 30″ Seal Models</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ConstantHeatSealer
