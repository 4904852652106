import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';
import '../styles/Blog/blog.css';
import image1 from '../images/daniel-hooper-PaaboPF3dVY-unsplash.jpg';
import image2 from '../images/jez-timms-DVRXFIH42d0-unsplash.jpg';
import image3 from '../images/imgbg.jpg';

function Blogs() {
  return (
    <div className='blog'>
      <div className='blog-inner'>
        <div className='header'>
            <h5>Blog & News</h5>
            <h1>Blog Posts</h1>
        </div>
        <div className='blogs'>
            <div className='article'>
                <img src={image3} alt="" />
                <h2 className='header'>Advantages and disadvantages of using machinaries.</h2>
                <h5  className='para'>Innovation is the central part of business change and primary compelled to use modern technology, processes and products, the type of change that can influence competitiveness significantly. Pay-on-demand for equipment could be a functional approach for change for small/medium-sized manufacturers.</h5>
                <hr/>
                <a href='https://blog.prophetic-technology.com/the-advantages-and-disadvantages-of-machine-as-a-service#:~:text=Some%20advantages%3A&text=Efficiency%20%E2%80%93%20Machines%20generate%20more%20production,and%20within%20a%20limited%20time.&text=Worker%20might%20be%20more%20dedicated,they%20make%20use%20of%20machines.&text=Improvement%20in%20the%20quality%20and,high%20and%20large%20production%20rate.' className='learn-more-btn'>
                    <h5 className='learnbtntext'>Read More</h5>
                    <ArrowRightAltIcon fontSize='large' style={{
                        color: 'red'
                    }} className='arrow'/>
                </a>
            </div>
            <div className='article'>
                <img src={image2} alt="" />
                <h2 className='header'>Meat Production and Supply Chain Under COVID-19 Scenario</h2>
                <h5 className='para'>The COVID-19 pandemic adversely affected many sectors of life, taking a huge toll not only on the economy but the livestock industry, such as global meat production and supply chain. Several countries' preventive measures included travel restrictions, border controls, and country lockdowns, developed harsh consequences affecting production and supply chain.</h5>
                <hr/>
                <a href='https://www.frontiersin.org/articles/10.3389/fvets.2021.660736/full' className='learn-more-btn'>
                    <h5 className='learnbtntext'>Read More</h5>
                    <ArrowRightAltIcon fontSize='large' style={{
                        color: 'red'
                    }} className='hero-arrow'/>
                </a>
            </div>
            <div className='article'>
                <img src={image1} alt="" />
                <h2 className='header'>5 Tips To Prevent Workplace Hazards</h2>
                <h5 className='para'>Workplace hazards refer to situations that have the potential to cause injury or adverse health effects. They can hurt people or cause damage to equipment or the facility. Hazards are in every workplace – but that doesn’t mean they can’t be prevented or eliminated.</h5>
                <hr/>
                <a href='https://readymachinery.com/workplace-hazard-prevention/' className='learn-more-btn'>
                    <h5 className='learnbtntext'>Read More</h5>
                    <ArrowRightAltIcon fontSize='large' style={{
                        color: 'red'
                    }} className='hero-arrow'/>
                </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
