import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import one from '../images/1.PNG';
import canfishprocessingline from '../images/canfishprocessingline.png';
import two from '../images/2.PNG';
import three from '../images/3.PNG';
import four from '../images/4.PNG';

function CanFishProcessingOutline() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>CAN FISH PROCESSING OUTLINE</h1>
            <img src={canfishprocessingline} />
        </div>
        <div className="images" style={{
          maxWidth: '1200px',
          height: '12vh',
          padding: '20px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
            <img src={one}/>
            <br/>
            <img src={two}/>
            <br/>
            <img src={three}/>
            <br/>
            <img src={four}/>
            <br/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CanFishProcessingOutline
