import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import blendingtrough from '../images/blendingtrough.png';

function BleedingThorugh() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={blendingtrough} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BLEEDING THROUGH</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This trough is used to collect the blood from the bleeding of the birds. The trough is made of stainless steel sheet. By joining several sessions, any plant capacity can be covered.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">SR</div>
                            <div class="box len">DR</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">2.0000</div>
                            <div class="box len">2.0000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">600</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">20</div>
                            <div class="box len">35</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,25</div>
                            <div class="box len">35</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default BleedingThorugh
