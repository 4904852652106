import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import '../styles/css/wastetreatementproducts.css';
import receivingcyclone from '../images/receivingcyclone.png';

function RecyclingCyclone() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
    <div class="birds-inner">
        <div class="left">
            <div class="img">
                <img src={receivingcyclone} alt="product image"/>
            </div>
        </div>
        <div class="right">
            <div class="name">
                <h2>RECEIVING CYCLONE</h2>
            </div>
            <div class="description">
                <h2>Description</h2>
                <h5>This cyclone is used to receive and discharge at regular times the offal coming from the various hoppers. The operating is regulated from an electrical control panel. The cyclone is made of stainless steel.</h5>
            </div>
            <div class="data">
                <h2>Technical Data</h2>
                <div class="table">
                    <div class="row">
                        <div class="box">Capacity</div>
                        <div class="box len">120</div>
                        <div class="box len">l</div>
                    </div>
                    <div class="row">
                        <div class="box">Lenght</div>
                        <div class="box len">480</div>
                        <div class="box len">mm</div>
                    </div>
                    <div class="row">
                        <div class="box">Width</div>
                        <div class="box len">480</div>
                        <div class="box len">mm</div>
                    </div>
                    <div class="row">
                        <div class="box">Height</div>
                        <div class="box len">1.250</div>
                        <div class="box len">mm</div>
                    </div>
                    <div class="row">
                        <div class="box">Total Weight</div>
                        <div class="box len">55</div>
                        <div class="box len">kg</div>
                    </div>
                    <div class="row">
                        <div class="box">Shipping Volume</div>
                        <div class="box len">0,5</div>
                        <div class="box len">M <sup>3</sup></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      <Footer/>
    </div>
  )
}

export default RecyclingCyclone
