import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer/footer.css';
import phone from '../icons/phone-call.png';
import address from '../icons/marker.png';
import email from '../icons/email (1).png';
import tele from '../icons/telegram.png';
import fb from '../icons/facebook.png';
import whatsapp from '../icons/whatsapp.png';
import insta from '../icons/instagram.png';
import logo from '../images/Euromate_Logo.jpeg';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-inner'>
        <div className='up-footer'>
            <div className='left'>
                <img src={logo} alt="logo" />
                <h5>Our Well Experienced Service Team Had Well Skilled Well 
                Individually Trained And Fully Supported By Our Mother 
                Company And Our Brand Partners From Overseas. We Fully 
                Committed To Ensure That Our Team Provides The Final Levels Of 
                Technical Knowledge, Service And Support. Our Technicians Will 
                Offer Fully “Customer Care During 24hrs” That Covers All Kind Of 
                Solutions From Industry Needs From Customer Requirements.</h5>
            </div>
            <div className='right'>
                <div className='up'>
                    <div className='left'>
                        <img src={phone} alt="" />
                    </div>
                    <div className='right'>
                        <h5>Call Us</h5>
                        <a href='tel:+94 (11) 428 4545'>+94 (11) 428 4545</a> <br/>
                        <a href='tel:+94 (11) 428 4546'>+94 (11) 428 4546</a> <br/>
                        <a href='tel:+94 76 424 4545'>+94 76 424 4545</a>
                    </div>
                </div>
                <div className='middle'>
                    <div className='left'>
                        <img src={email} alt="" />
                    </div>
                    <div className='right'>
                        <h5>Email Us</h5>
                        <h5>sanka@euromatefoodtech.com</h5>
                        <h5>trading@euromatefoodtech.com</h5>
                    </div>
                </div>
                <div className='middle'>
                    <div className='left'>
                        <img src={address} alt="" />
                    </div>
                    <div className='right'>
                        <h5>Visit Us</h5>
                        <h5>No. 399/3, <br/>
                            Kandy Road, <br/>
                            Kirillawala, <br/>
                            Sri Lanka. 
                        </h5>
                    </div>
                </div>
                <div className='down'>
                    <a href=''><img src={fb} alt="" /></a>
                    <a href=''><img src={tele} alt="" /></a>
                    <a href=''><img src={whatsapp} alt="" /></a>
                    <a href=''><img src={insta} alt="" /></a>
                </div>
            </div>
        </div>
        <div className='down-footer'>
            <hr/>
            <div className='details'>
                <h5>&copy; {new Date().getFullYear()} Copyrights. All Rights Reserved.</h5>
                <h5>Designed by M.N.Jeral Sandeeptha</h5>
                <div className='links'>
                    <Link to='/' className='link'>Home | &nbsp;</Link>
                    <Link to='/products' className='link'>Products | &nbsp;</Link>
                    <Link to='/about' className='link'>About | &nbsp;</Link>
                    <Link to='/contacts' className='link'>Contact | &nbsp;</Link>
                    <Link to='/services' className='link'>Services</Link>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
