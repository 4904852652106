import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import rollerconveyor from '../images/rollerconveyor.png';

function RollerConveyor() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={rollerconveyor} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>ROLLER CONVEYOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The roller conveyors designed to easily transfer all type of crates trough the processing plant. the frame is made of stainless steel, while the rollers are made of plastic with stainless steel bearings.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800-1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">4.000</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">42</div>
                            <div class="box len">M <sup>3</sup> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default RollerConveyor
