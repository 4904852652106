import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/cansealermachine.css';
import cansealermachine from '../images/cansealermachine.png';
import cansealermachine2 from '../images/cansealermachine2.png';

function CanSealingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="cansealermachine">
        <div class="inner-cansealermachine">
            <h1>CAN SEALING MAHINES</h1>
            <img src={cansealermachine} />
            <div class="section">
                <h2>Can Sealing / Closing Machines</h2>
                <h5>For the food and beverage business to generate higher profits through an automatic canning system, they need to buy the newest technology. </h5>
                <h5>You can choose from our following various for sale sealing machines for round cans in your productions:</h5>
                <h5>•  Semi-auto can seamer for small production</h5>
                <h5>•  High volume fully automatic seamer</h5>
                <h5>•  Aluminum foil induction sealing machine</h5>
                <h5>We also support can sealer machine troubleshooting, spare parts supply, training, and any other technical services you need. Our experts are professional in the field.</h5>
            </div>
            <div class="section">
                <h2>What is a Can Sealing Machine?</h2>
                <h5>A can sealing machine is also known as a can seaming/closing or a can sealer/seamer/closer machine. It is used to hermetically seal the lid to the body of paper cans, aluminum cans, tin cans, PET cans, glass cans, plastic cans, pots, jars, and others. The can lid is usually made of tin-plated steel while the can's body can be:</h5>
                <h5>•  Metal (such as food and beverage cans)</h5>
                <h5>•  Paperboard (popcorn cans)</h5>
                <h5>•  Plastic</h5>
                <h5>Can seamers could be assembled on an automatic packaging line or used alone. They are very common in the food industries and other product varieties.</h5>
            </div>
            <div class="section">
                <h2>Can Sealer / Closer Machine Classification</h2>
                <h5>The mechanical composition and design of can seamers vary depending on the type and number of cans (usually steel or aluminum) being sealed per unit time. They can either have automatic or semi-automatic and contact or non-contact (with respect to the can being sealed) operation.</h5>
            </div>
            <div class="section">
                <h2>Automatic Can Sealers</h2>
                <h5>Automatic can sealing machines are fully automated and does not need any human interaction during the sealing process.</h5>
            </div>
            <div class="section">
                <h2>Semi-Automatic Can Seamers</h2>
                <h5>Semi-automatic can sealer/sealing machines, on the other hand, require some manual help for the operation.</h5>
            </div>
            <div class="section">
                <h2>Contact Can Sealing Operation</h2>
                <h5>Can sealers that do double seaming (such as nitrogen gas flushing vacuum seamers) come into contact with the can? Double seaming is the process of connecting the can’s lid to its body.</h5>
            </div>
            <div class="section">
                <h2>Non-Contact Can Sealing Operation</h2>
                <h5>Contrastingly, induction cap sealers do not come into contact with the cans. This is because induction sealing only uses heat to bond an aluminum foil to the can to seal the can’s opening</h5>
            </div>
            <div class="section">
                <h2>Semi Auto Can Seaming Machine</h2>
                <h5>We also manufacture semi-automatic can seamers with 2 rollers able to seal aluminum cap to plastic or metal round can body. This sealing machine is suitable for small production or home use. Find more can sealers in Levapack to meet your special needs.</h5>
                <h5>•  Model: LPE-SLV20</h5>
                <h5>•  Semi-automatic single head seamer with 304# stainless steel material.</h5>
                <h5>•  Can body non rotary when seaming, suitable for liquid and other material easy to fall out or broken.</h5>
                <h5>•  Seaming rollers are made of Cr12 with high hardness, rust-proof and excellent sealing performance.</h5>
                <img src={cansealermachine2} alt="product image"/>
            </div>
            <div class="section">
                <h2>High Volume Fully Auto Round Can Sealing Machine</h2>
                <h5>This fully-automatic can sealer equipped with 4 rollers can achieve double seam via 2 operations to avoid leakage. One of the top sealing machines for sealing tuna, sardine & other cannery food in the tin, aluminum, ferrum and other round metal cans.</h5>
                <h5>•  Model: LPE-FHV50V</h5>
                <h5>•  High volume Fully-automatic single head seame.</h5>
                <h5>•  Adjustable production capacity,seam Up to 50 cans/minute.</h5>
                <h5>•  4 rollers for consistent & leak-proof seam.</h5>
                <h5>•  Perfect for sealing tin, aluminum, PET& other paper cans with a maximum diameter of 130mm.</h5>
            </div>
            <div class="section">
                <h2>Aluminum Foil Induction Sealing Machine for Screw Cap</h2>
                <h5>Our factory also manufactures the most efficient screw cap sealing machine. Fast sealing speed, suitable for large-volume production for different industries. The sealing head style can be customized according to the customer sample bottle. Find more customizable can sealing machines according to your special applications.</h5>
                <h5>•  Model: LPE-FIS100</h5>
                <h5>•  Digital display of working voltage and current, intuitive and clear.</h5>
                <h5>•  Knob adjustment, button switch, easy to use.</h5>
                <h5>•  Over-current and over-voltage, automatic protection, safe and reliable.</h5>
                <h5>•  The sealing head adopts handwheel type lifting adjustment, which is easy to use.</h5>
            </div>
            <div class="section">
                <h2>Can Sealer Parts and Function</h2>
                <h5>Can seamer machine have the following major components?</h5>
            </div>
            <div class="section">
                <h2>#1 Seamer Chuck/Head</h2>
                <h5>The seamer head holds the can’s top or lid tightly and serves as a support material from the rollers’ pressure. The dimensions of the chuck depend on the diameter of the round can/tin can/jar being sealed. An appropriate chuck should be installed for different can diameters.</h5>
            </div>
            <div class="section">
                <h2>#2 Seamer Turntable (Base Plate)</h2>
                <h5>The spring-loaded seamer turntable is a disc that spins freely and gives support to the body of the can being sealed.</h5>
            </div>
            <div class="section">
                <h2>#3 Seaming Rollers</h2>
                <h5>Can sealing machines normally have one set of seaming rollers but some can seamer machines used for high volume production have more. The first roller interconnects the can’s lid to its body while the second one tightens the seam and irons it out.</h5>
            </div>
            <div class="section">
                <h2>#4 Sealing Chamber</h2>
                <h5>The sealing chamber is where the sealing process of the can takes place.</h5>
            </div>
            <div class="section">
                <h2>How Can Sealing Machines Work</h2>
                <h2>Can Sealing Machine Designs</h2>
                <h5>Can sealing machines work depending on the sealing machine design – rotary or non-rotary.</h5>
                <h2>Rotary Machine Design</h2>
                <h5>In rotary seamers, the can spins while the seamer does the sealing process. Automatic can seamers typically have rotary machine designs and are usually used in big operations in industries. Fully Auto Nitrogen Filling Vacuum Sealing Machines and High Volume Fully Auto Round Can Sealing Machines have rotary machine designs.</h5>
                <h2>Non-Rotary Machine Design</h2>
                <h5>Non-rotary seamers keep the can stationary and only the seamer spins. This avoids damages to the can being sealed especially to the product inside it. These are ideal for products that contain any kind of liquid to prevent spilling. Semi-automatic seamers have normally non-rotary machine designs and are meant for the home market. Semi-Auto Vacuum And Gas Nitrogen Flushing Round Can Sealing Machine, Semi-Auto Can Seamer, and Servo Fixed Speed Sealing Machine have non-rotary machine designs.</h5>
            </div>
            <div class="section">
                <h2>The Can Sealing Process</h2>
                <h5>When you buy a can body, the flange in it is noticeable and its end has a curl. Once the can goes in the seamer, the can is lifted up and the lid is attached to the can’s top by the seaming chuck. The can enters the seamer either through a conveyor if it is automatic or by manually putting it on the sealing chamber if its semi-automatic.
After the can is lifted up, it undergoes three operation stages in the can sealing process: compression, first roller operation stage, and second roller operation stage.</h5>
            </div>
            <div class="section">
                <h2>Stage 1. Compression</h2>
                <h5>The compression stage provides the force necessary to hold the can being sealed against the seamer chuck. It feeds the can body flange towards the first roller. The chuck holds and keeps the lid to be attached to the can in place while the seamer turntable moves the can upwards against the chuck.</h5>
                <h5>For vacuum can sealing machines, the oxygen inside the can is removed (vacuumed) first and then nitrogen gas is flushed into it before the sealing process is resumed. After which the lid is placed into the top of the can.
Next, the can with the lid undergoes the folding process known as double seaming. The seam is made by interlocking the lid’s “curl” (outside end of the lid) with the “flange” (the can body’s top part). This is accomplished in two roller operation stages.</h5>
            </div>
            <div class="section">
                <h2>Stage 2. First Roller Operation</h2>
                <h5>The first roller operation stage is the most critical one as it affects the seam’s integrity. A successful first roller operation stage ensures that tightness issues (or wrinkles) and leaks are avoided. In this stage, the first seamer roller comes in to fold the lid’s curl towards the can’s flange. The first roller stage must not be too tight or too loose as this cannot be corrected later.
First, the lid’s curl (which is wider than the can’s flange) is pushed by the first roller and turns around first. Next, the can’s flange follows while the roller advances closer to the chuck. Lastly, the lid interlocks with the body of the can but is not sealed hermetically yet.</h5>
            </div>
            <div class="section">
                <h2>Stage 3. Second Roller Operation</h2>
                <h5>After the first roller operation stage, the lid and the can body are now loosely held in place and “hooks” is formed. The hooks’ parameters are considered and monitored in checking the seam’s integrity under various conditions.
In the second roller operation stage, the loose seam is ironed out by a second roller to ensure that the sealing is made tight. It compresses the interlocked hooks of the lid and the flange of the can body. Then a sealing substance is squeezed into the remaining open spaces which completes the hermetic seal. The sealing substance ensures that the product inside the can is protected from leaks and contaminants by guaranteeing a tight seam seal. You can also learn how aluminum cans are sealed in this guide with a video.
The double seam’s shape is dependent on the first and second rollers’ shape along with:</h5>
                <h5>The induction sealing machine’s head contains a coiled conductor and generates an electromagnetic current when a power supply energizes it. The capped containers pass under this electromagnetic current through a conveyor. The inner seal’s foil produces electrical resistance which heats the foil and in turn dissolves the inner seal’s polymer coating.
The heat from the foil along with the cap pressure makes the inner seal bond with the container’s lip resulting in a hermetic seal. Our Aluminum Foil Induction Sealing Machine for Screw Cap works the same way.</h5>
            </div>
            <div class="section">
                <h2>Applications</h2>
                <h5>As the requirements of various industries grow higher, We are response is to offer a wide range of customizable can sealing machines to meet the market’s demands. We can seamers are suitable for sealing aluminum, steel, plastic, tin, paper, glass, PET, and plastic cans among other container types.
LPE understands the challenges that every industry faces may it be in the food, beverage, pharmaceutical, and chemical industry. The integrity of double seaming is critical to ensure that no leaks (especially when dangerous liquids are canned) and contamination of the product inside will happen. For beverage can seaming and liquid sealing solution, you can learn more in this beginners’ guide to liquid packaging. We make sure that all your requirements are met through our expertise.</h5>
            </div>
            <div class="section">
                <h2>Industries you can use LPE’s</h2>
                <h5>Beverage Industry  Canned beer sealing, Soda sealing, Cider, Water, Juice, Coffee and Tea, Wine, Carbonated Beverages</h5>
                <h5>Food Industry  Tuna, Jam, Sauce, Cannabis, Nutrition Powder, Honey, Dry Food (such as nuts, cereals, rice, etc), Soup, Vegetables, Fruits, Meat, Seafood</h5>
                <h5>Pharmaceutical Industry  Pharmaceutical raw materials, Powders, Hand sanitizer, Pills, Medical supplies, Veterinary supplies</h5>
                <h5>Chemical Industry  Automotive Fluids (petrol, oil, and diesel), Nuclear waste and radioactive substances, Ink and lacquers, Farm chemicals, Cleaning liquid, Paint, Lube oil, Glue, Cooking oil</h5>
            </div>
            <div class="section">
                <h2>Customizing Your Can Sealing Machine</h2>
                <h5>Our engineers will be working and consulting closely with you for the customization of the ideal packaging solution for your business also including
can filling machines, can capping machines and labeling machines. We help you understand and determine the following factors before we start manufacturing your can seamers:</h5>
                <h5>•  The materials you intend to pack</h5>
                <h5>•  Your machine type needs whether it is single or multiple lines of machines</h5>
                <h5>•  Your product yield demand</h5>
                <h5>•  Your plant’s size and layout</h5>
                <h5>•  Other special requirements such as vacuum packing</h5>
                <h5>For the manufacturing of your customized can seamer, below are the following machine components that you can customize:</h5>
            </div>
            <div class="section">
                <h2>#1 The Seamer Mould</h2>
                <h5>You can customize the seamer mould based on your can size with dimensions Diameter x Height. This includes the seaming chuck, turntable, and lid feeder.</h5>
            </div>
            <div class="section">
                <h2>#2 The Seaming Rolls</h2>
                <h5>Customize your seaming machine’s rollers according to your package material such as:</h5>
                <h5>•  Tin can</h5>
                <h5>•  PET can</h5>
                <h5>•  Aluminum can</h5>
                <h5>•  Paper tube</h5>
            </div>
            <div class="section">
                <h2>#3 The Brand of the Seamer’s Electrical Parts</h2>
                <h5>Choose the brand for your can sealing machine’s electrical parts as per your special requirements.
#4 The Seamer Machine’s Size
Small or big factory space, we can tailor your seaming machines for you to suit your factory’s available space.</h5>
            </div>
            <div class="section">
                <h2>Seal without Leakage</h2>
                <h5>For years, we've been working to bring you can seamers with the highest level of tightness.</h5>
            </div>
            <div class="section">
                <h2>Automatic Operation</h2>
                <h5>Our aluminum foil sealing machines are quipped with conveyors to steamline the seaming process. You can seam by simply pressing machine buttons.</h5>
            </div>
            <div class="section">
                <h2>Tailored Size</h2>
                <h5>Seaming head of our aluminum foil machine is tailored made to fit the size of you can, container or bottle. So you can achieve exact seams effortlessly.</h5>
            </div>
            <div class="section">
                <h2>Leak-proof Sealing</h2>
                <h5>Application of heart induction tech allows our machines to seal the foil tightly. They‘re perfect for industries where leaking issues are concerns.</h5>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CanSealingMachine
