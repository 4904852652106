import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import AboutBanner from '../components/AboutBanner';
import AboutSection from '../components/AboutSection';

function About() {
  return (
    <div>
      <Navbar/>
      <AboutSection/>
      <AboutBanner/>
      <Footer/>
    </div>
  );
}

export default About;
