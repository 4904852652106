import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import hopper from '../images/hopper.png';

function Hopper() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={hopper} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>HOPPER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This equipment is used for receive and send by vacuum at regular intervals the offal from the various processing (head, legs, viscera, etc.). Upon the hopper there is a guillotine valve operated from a pneumatic cylinder tire that is opened at regular time. The cycle is setting and regulating up on a special electronic panel. The hopper is made in stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">550</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Air consumption 6bar</div>
                            <div class="box len">0,36</div>
                            <div class="box len">m <sup>3</sup>/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">30</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,4</div>
                            <div class="box len">M <sup>3</sup></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Hopper
