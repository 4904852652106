import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import '../styles/HeroSection/herosection.css';

function HeroSection() {
  return (
    <div className='hero'>
      <Navbar/>
      <Hero/>
    </div>
  );
}

export default HeroSection;
