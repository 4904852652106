import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import birdunloader from '../images/birdunloader.png';

function BirdUnloader() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={birdunloader} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BIRD UNLAODER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic bird unloader is a machine drive by the overhead conveyor and placed at the end of the eviscerating line. A special toothed rubber belts, block and drive the shackles during the unloading of the birds. The machine is made of stainless steel.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">8000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">950</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.250</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">140</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">1</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default BirdUnloader
