import React from 'react';
import '../styles/ServiceBanner/servicebanner.css';
import { Link } from 'react-router-dom';

function ServiceBanner() {
  return (
    <div className='servicebanner'>
      <div className='servicebanner-inner'>
        <div className='up'>
            Our Services
        </div>
        <div className='middle'>
            <h1>LET'S WORK <span className='span'>TOGETHER</span></h1>
            <Link className='btn' to='/contacts'>
                CONTACT US
            </Link>
        </div>
        <div className='down'>
            <div className='down-inner'>
                <div className='inner'>
                   <h2>Our Services</h2>
                    <div className='para'>
                        <h5>Our Well Experienced Service Team Had Well Skilled Well 
                            Individually Trained And Fully Supported By Our Mother 
                            Company And Our Brand Partners From Overseas. We Fully 
                            Committed To Ensure That Our Team Provides The Final Levels Of 
                            Technical Knowledge, Service And Support. Our Technicians Will 
                            Offer Fully “Customer Care During 24hrs” That Covers All Kind Of 
                            Solutions From Industry Needs From Customer Requirements.
                        </h5>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceBanner
