import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import mixergrinder from '../images/mixergrinder.PNG';
import mixergrinder1 from '../images/mixergrinder1.PNG';

function MixerGrinder() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>MIXER GRINDER</h1>
            <img src={mixergrinder} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>PM-130</h2>
            <h5>· High performance Mixer-Grinder, ideal for use in Supermarkets and Meat Industries, combining good mixing and grinding.</h5>
            <h5>· Supplied with two motors that can work independently adapting to product’s needs.</h5>
            <h5>· Integrated mixer grinder made in Stainless Steel 18/10.</h5>
            <h5>· Gear drive transmission.</h5>
            <h5>· Threephase motor for grinding:-PM 32-98: 5,5 HP (4 KW)-PM 42-130: 13 HP (9,2 KW)</h5>
            <h5>· Threephase motor for mixing:- PM 32-98:1 HP (0,75 KW)- PM 42-130: 1,5 HP (1,1 KW)</h5>
            <h5>· Cutting head entirely in stainless steel. Available in: ENTERPRISE single cut (1) in PM 32 and PM 42, UNGER double cut (2) in models PM 98 and PM 130 and UNGER triple cut (3) in model PM 130.</h5>
            <h5>· Automatic feeding:- PM 32-98: 90 litre bowl – PM 42-130: 130 litre bowl</h5>
            <h5>· Auto-reverse Mixing Paddles.</h5>
            <h5>· Option nut cover with sensor.</h5>
            <h5>· Option foot switch.</h5>
            <h5>· Option shock absorbers in the cover por PM 32-98.</h5>
            <br/>
            <img src={mixergrinder1} />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default MixerGrinder
