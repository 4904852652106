import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import gibletpump from '../images/gibletpump.png';

function GobletPump() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={gibletpump} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>GIBLET PUMP</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This diaphragm pump moves giblets from evisceration to chilling line. The pump is complete with stainless steel hoppers and air connection. It’s made of casting aluminium body.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">5.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">400</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">750</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Pump head pressure</div>
                            <div class="box len">6</div>
                            <div class="box len">m</div>
                        </div>
                        <div class="row">
                            <div class="box">Inlet-outlet</div>
                            <div class="box len">3"</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Air pressure</div>
                            <div class="box len">50</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">50</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">0,35</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default GobletPump
