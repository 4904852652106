import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import meatballmachinemeat1 from '../images/meatballmachinemeat1.PNG';
import meatballmachinemeat2 from '../images/meatballmachinemeat2.PNG';
import meatballmachinemeat3 from '../images/meatballmachinemeat3.PNG';

function MeatMeatBallMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>MEAT BALL MACHINE</h1>
            <h5>Commercial Automatic Restaurant Beef Pork Fish Food Make Electric Meat Ball Meatball Maker Making Processing Forming Machine</h5>
            <br/>
            <img src={meatballmachinemeat3} />
            <br/>
            <h5>Meatball forming machine is used in chicken balls,fish balls,shrimp balls,beef balls,mutton balls,vegetable balls etc. </h5>
            <h5>It is made of small density, good roundness and smooth appearance.As long as you put the pulp into the hopper,you can turn it on for 2-3 minutes to make the meatballs.The products are crisp,delicious and elastic.</h5>
            <h5>1.This meatball making machine/fish ball machine is used to make meatballs, fish balls,shrimp balls,beef balls,mutton
            balls,vegetables,etc.</h5>
            <h5>2.This meatball making machine/fish ball machine can make 280~300 pieces meatballs in one minute,the revolving speed is 1420r/min.</h5>
            <h5>3.The meatball size can be adjusted by changing mould,it can make 1.6cm, 1.8cm,2.2cm,2.6cm,3.5cm and other size meatballs
            according to clients needs. </h5>
            <h5>4.This meatball making machine/fish ball machine is made of stainless steel 304.It meets safety and health standards,with more
            than 8 years service life.</h5>
            <br/>
            <img src={meatballmachinemeat2} />
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Features:</h2>
            <h5>1.This machine is made of stainless steel 304, meet food hygiene standards.</h5>
            <h5>2.The size of meatball can be adjusted according to needs between 5~20g.</h5>
            <h5>3.The meat ball shaping parts use a spoon shape mold makes the meatball more round, smoother, and better appearance. </h5>
            <h5>4. It is easy to operate, only need one operator, saving labor cost.</h5>
            <h5>5.It is an excellent machine for restaurant, canteen, school, and meat producing factory.</h5>
            <br/>
            <img src={meatballmachinemeat1} />
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default MeatMeatBallMachine
