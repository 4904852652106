import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css';
import blanchingmachine from '../images/blanchingmachine.PNG';
import blastfrezzingmachine from '../images/blastfrezzingmachine.PNG';
import dehydratemachine1 from '../images/dehydratemachine1.png';
import frenchfriesmachine from '../images/frenchfriesmachine.png';
import fruitscuttingmachine from '../images/fruitscuttingmachine.png';
import fruitpeelingmachine from '../images/fruitpeelingmachine.png';
import potatopeelingmachine from '../images/potatopeelingmachine.png';
import vegetablecuttingmachine from '../images/vegetablecuttingmachine.png';
import cassavapeelingmachine from '../images/cassavapeelingmachine.png';
import rottimakingmachine from '../images/rottimakingmachine.png';
import vegecuttingmachine from '../images/vegecuttingmachine.png';
import { Link } from 'react-router-dom';

function VegeFoodProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1 style={{textAlign: 'center'}}>Vegetable & Food Products</h1>
        </div>
        <div className='products'>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={blanchingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Blanching Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/blanchingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={blastfrezzingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Blast Freezing Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/blastfrezzingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={dehydratemachine1}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Dehydtrator Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/dehydratemachine1'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={frenchfriesmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    French Fires Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/frenchfriesmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fruitscuttingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fruits Cutting Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/fruitscuttingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fruitpeelingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fruits Peeling Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/fruitpeelingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="120"
                    image={potatopeelingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Potato Peeling Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={1} />
                <CardActions>
                    <Link to='/potatopeelingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={vegetablecuttingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Vegetable Cutting Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/vegetablecuttingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={cassavapeelingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Cassava Peeling Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/cassavapeelingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="120px"
                    image={rottimakingmachine}
                    style={{width: '100px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Rotti Making Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/rottimakingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={vegecuttingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Vegetable Dehydrator Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/vegecuttingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

        </div>
      </div>
    </div>
  )
}

export default VegeFoodProducts
