import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import irottimakingmachine from '../images/rottimakingmachine.png';
import irottimakingmachine1 from '../images/rottimakingmachine1.PNG';
import irottimakingmachine2 from '../images/rottimakingmachine2.PNG';

function RottiMakingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>ROTTI MAKING MACHINE</h1>
            <img src={irottimakingmachine} /> 
            <br/>
            <img src={irottimakingmachine1} />
            <br/>
            <h5>1. Automatic Spring Roll Sheet Making Machine can make round-shape sheet, and rectangle-shape,can heat the thin bread, and the temperature can be adjust by users. </h5>
            <h5>2. Automatic Spring Roll Sheet Making Machine is made of Stainless Steel,No Pollution. Instead of traditional fire smoke heating, pancake machine is more environmental. </h5>
            <h5>3.It can use flour as material, you also can add some spice to make round rice wrapper.</h5>
            <h5>4.Thickness is adjustable by operator.</h5>
            <h5>5.This pie press machine is widely used to make Chapatti, Roti, flat bread,thin pancake,etc.</h5>
            <br/>
            <img src={irottimakingmachine2} />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default RottiMakingMachine
