import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import img from '../images/brinemixer.png';

function BrineMixer() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>BRINE MIXER</h1>
            <img src={img} />
            <h2 >Description :</h2>
            <h5>It allows the quick, controlled and efficient preparation of any type of brine, by reducing stirring time, avoiding temperature increase and improving the dissolution of additives. <br/><br/> • Characteristics: Tank with support for turboagitator. Agitator with propeller type head. Platform with access ladder. Built in AISI-304 stainless steel and FDA technical plastics. Push-button control panel with timer. <br/><br/> • Options (not included): Automatic valve system. Double jacket for refrigeration. Pipe with cleaning sprayers for the inside of the tank.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Advantages :</h2>
            <h5>•  Short cycle time for the mixing of the constituent elements of the brine thanks to high-power elements; such as the existing main pump in all tank models.</h5>
            <h5>•  Real-time display of the temperature of the brine to be prepared or stored.</h5>
            <h5>•  Each tank or accumulator will respond to the real needs of each client, for each product and at all times, since our tanks are designed according to the indications of the clients and the different options they can offer.</h5>
            <h5>•  The main definition of the deposit will be given to us by the volume of product that we want to prepare.</h5>
            <h5>•  This can be: 200, 500, 1000, 1500, 2000, 2500 litres.
            Materials of Construction: 304 and 316L stainless steel</h5>
            <h5>•  MANUAL OR AUTOMATIC:  the manual version presents the maneuver of the pipes manually; the loading of the product is carried out by means of a comfortable ladder.The automatic version allows us to choose the most suitable program among those previously programmed for the different mixtures, giving the option that the machine automatically feeds itself with the necessary amount of brine.</h5>
            <h5>•  COLD JACKET:  Thanks to the cold jacket we can ensure that the brine will never exceed a pre-programmed temperature. Achieving in this way a better performance during the injection phase.</h5>
            <h5>•  AGITATOR:  It can be a turbo-agitator intended for very dense brines or a simple agitator to keep the brine particles in suspension.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BrineMixer
