import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Services1 from '../components/Services';
import ServiceBanner from '../components/ServiceBanner';

function Services() {
    
  return (
    <div>
      <Navbar/>
      <ServiceBanner/>
      <Services1/>
      <Footer/>
    </div>
  );

}

export default Services;
