import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import vaccumfillers from '../images/vaccumfillers.PNG';
import vaccumfillers1 from '../images/vaccumfillers1.PNG';
import vaccumfillers2 from '../images/vaccumfillers2.PNG';
import vaccumfillers3 from '../images/vaccumfillers3.PNG';
import vaccumfillers4 from '../images/vaccumfillers4.PNG';
import vaccumfillers5 from '../images/vaccumfillers5.PNG';
import vaccumfillers6 from '../images/vaccumfillers6.PNG';
import vaccumfillers7 from '../images/vaccumfillers7.PNG';
import hbf1 from '../images/hbf1.PNG';
import hbf2 from '../images/hbf2.PNG';
import hbf3 from '../images/hbf3.PNG';
import hcapavakiline1 from '../images/capavakiline1.PNG';

function VaccumeFillers() {
  return (
    <div>
      <Navbar/>

      <div className="canwashermachine">
        <div className="inner-canwashermachine">

            <h1>RVF 220 VACUUM FILLER</h1>
            <img src={vaccumfillers} />
            <h5>Vedio : <a href="https://youtu.be/bVnRq5xKBc8">Click Here</a></h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Cost-effective vacuum filler for everyone</h2>
            <h5>The RVF 220 is a perfect and cost-effective solution for newcomers to vacuum filling technology, yet also suitable as a replacement purchase for your existing filling machine. It comes with all the technical features necessary for the trade and is ideal for frequent product changes.</h5>
            <h5>The machine housing manufactured completely from stainless steel in hygienic design is compact and space saving.
            The hoppers – available in a 50-litre version with cover or 100-litre version with flap – are distinguished by simple filling and easy cleaning. Maximum infeed with minimum residual amounts of meat emulsion are typical here.</h5>
            <h5>The rotary vane conveyor system made of wear-resistant stainless steel guarantees a long service life and the best product quality. It ensures gentle transport of the meat emulsion and minimal air pockets in the final product.</h5>
            <h5>The standard 12” touchscreen control enables easy operation. The optional clipping socket allows various clipping devices and/or attachments to be operated.</h5>
            <h5>The optionally available casing holding device twists all types of natural and artificial casings with ease.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 327 & 327S VACUUM FILLER</h1>
            <img src={vaccumfillers1} />
            <br/>
            <img src={vaccumfillers2} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Simple operation everyone understands</h2>
            <h5>The vacuum filler RVF 327 is designed for small and medium-sized companies with frequently changing varieties and normal quantities. The series 300 is characterised by reliable, quick and easy cleaning, minimal leftovers and low energy costs.
            The rotary vane conveyor system made of wear-resistant stainless steel guarantees a long service life and the highest product quality. It ensures gentle transport of the meat emulsion and minimum air pockets in the final product.</h5>
            <h5>The 12“ touchscreen control is very simple to use and designed to meet the industry's requirements. The clipper socket, which is a standard feature, permits the operation of automatic clipping machines and/or additional machines. The folding hopper is available in volumes of 100 and 160 litres. The RVF 327 vacuum filler can also come with a servo drive system on the holding device which helps prevent tearing of natural casings (RVF 327 S).</h5>
            <h5>The RVF 327 and 330 can also be equipped with a rotating or fixed casing holding device. This makes it easier to twist artificial or natural casings.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 330 & 330S VACUUM FILLER</h1>
            <img src={vaccumfillers3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Simple operation everyone understands</h2>
            <h5>The RVF 330 is designed for small and medium enterprises with frequent changes in the product range and standard quantities. Reliability, fast and easy cleaning, minimization of residue and low energy costs are the main features of the RVF 330.
            The hygienic stainless steel machine body reduces cleaning time to a minimum. The rotary vane conveyor system made of wear-resistant stainless steel guarantees a long service life and the highest product quality. It ensures gentle transport of the meat emulsion and minimum air pockets in the final product.</h5>
            <h5>The touchscreen guarantees simple operation. The clipper socket, which is a standard feature, permits the operation of automatic clipping machines and/or additional machines. The “S” version of the RVF 327 has a servo twisting drive which supports gentle twisting of natural casings.</h5>
            <h5>It goes without saying that the RVF 330 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial and natural casings easier.</h5>
            <h5>Folding hopper variants in volumes of 100 and 160 litres are available.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 420 & 420S VACUUM FILLER</h1>
            <img src={vaccumfillers3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Entry models with lifting device</h2>
            <h5>The RVF 420 and RVF 420 S models are designed with all requirements of a medium-sized business in mind. The various machine configurations along with the optionally available lifting and tipping device make the machine the perfect vacuum filler for medium-sized companies.</h5>
            <h5>The compact and space-saving design, with smooth and hygienic machine housing, as well as the large rotary vane conveyor system for precise portioning, correspond ideally to user needs. It ensures that all kind of emulsions are transported gently, especially with larger pieces of meat or raw sausage applications. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>Both the RVF 420 and the RVF 420 S are designed to be suitable for all applications, e.g. for operating clipping machines, REX hanging lines and attachments.</h5>
            <br/>
            <img src={vaccumfillers4} />
            <br/>
            <h5>The standard folding hopper is available in variant of 100, 160, 250 and 350 litre volume. The flap opening is assisted by gas pressure absorbers. The large fold-out step rounds off the machine’s user-friendliness. The impressive standard 12” touchscreen control makes the machine easy to operate, thanks to its large-sized control elements.</h5>
            <h5>The tried-and-tested "REX Servo drive" rounds off the innovative control concept. The optional case holding device makes it easy to twist artificial and natural casings.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 430 & 430S VACUUM FILLER</h1>
            <img src={vaccumfillers5} />
            <br/>
            <img src={vaccumfillers6} />
            <br/>
            <img src={vaccumfillers7} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Entry models with lifting device</h2>
            <h5>The RVF 430 and RVF 430 S models are designed with all requirements of a medium-sized business in mind. The various machine configurations along with the optionally available lifting and tipping device make the machine the perfect vacuum filler for medium-sized companies.</h5>
            <h5>The compact and space-saving design, with smooth and hygienic machine housing, as well as the large rotary vane conveyor system for precise portioning, correspond ideally to user needs. It ensures that all kind of emulsions are transported gently, especially with larger pieces of meat or raw sausage applications. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>Both the RVF 430 and the RVF 430 S are designed to be suitable for all applications, e.g. for operating clipping machines, REX hanging lines and attachments.
            The standard folding hopper is available in variant of 100, 160, 250 and 350 litre volume. The flap opening is assisted by gas pressure absorbers. The large fold-out step rounds off the machine’s user-friendliness. The impressive standard 12” touchscreen control makes the machine easy to operate, thanks to its large-sized control elements.</h5>
            <h5>The tried-and-tested "REX Servo drive" rounds off the innovative control concept. The optional case holding device makes it easy to twist artificial and natural casings.</h5>
            <h5>Vedio : <a href="https://youtu.be/bVnRq5xKBc8">Click Here</a></h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 436 & 436S VACUUM FILLER</h1>
            <img src={vaccumfillers3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The all-rounder for all sizes of business</h2>
            <h5>The REX RVF 436 is as suitable for the smaller or medium-sized meat-processing operations as it is for larger, highly specialised companies. Its smooth surface, hygienically designed, space-saving machine housing, is self-supporting and made entirely of stainless steel.The machine’s modern design allows us to fulfil the highest standards of hygiene, reducing the daily cleaning effort.</h5>
            <h5>The reinforced model of the large rotary vane conveyor system guarantees highest portioning accuracy for a maximum service life. The vacuum filler does not crush or smear the product ensuring the product is transported gently throughout the filling process. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.
            Thanks to the newly developed lifting device, the hopper of the RVF 400 - available in different sizes - can be filled simply and without any mess.</h5>
            <br/>
            <h5>•  100 litre folding hopper<br/>
            •  160 litre folding hopper<br/>
            •  250 litre folding hopper <br/>
            •  350 litre folding hopper</h5>
            <br/>
            <h5>The impressive standard 12” touchscreen control makes the machine easy to operate, thanks to its large-sized control elements. Both the RVF 436 and the RVF 436 S are designed to be suitable for all applications, e.g. for operating clipping machines, REX hanging lines and attachments.</h5>
            <h5>The tried-and-tested "REX Servo drive" rounds off the innovative control concept. The optional case holding device makes it easy to twist artificial and natural casings.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 436 & 436S VACUUM FILLER</h1>
            <img src={vaccumfillers3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The all-rounder for all sizes of business</h2>
            <h5>The RVF 460 fulfills all the requirements of the meat processing industry. Its smooth surface, hygienically designed, space-saving machine housing is self-supporting and entirely made out of stainless steel.</h5>
            <h5>The reinforced model of the large rotary vane conveyor system guarantees highest portioning accuracy for a maximum service life. No crushing or smearing: It ensures all kind of emulsions transported gently, especially with larger pieces of meat or raw sausage applications. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>Thanks to the newly developed lifting device, the hopper of the RVF 400 - available in different sizes - can be filled simply and without any mess.</h5>
            <br/>
            <h5>•  100 litre folding hopper <br/>
            •  160 litre folding hopper<br/>
            •  250 litre folding hopper<br/>
            •  350 litre folding hopper</h5>
            <br/>
            <h5>The impressive standard 12” touchscreen control makes the machine easy to operate, thanks to its large-sized control elements. Both the RVF 436 and the RVF 436 S are designed to be suitable for all applications, e.g. for operating clipping machines, REX hanging lines and attachments.</h5>
            <h5>The tried-and-tested "REX Servo drive" rounds off the innovative control concept. The optional case holding device makes it easy to twist artificial and natural casings.</h5>
            <br/>
            <h5>The advantages at a glance</h5>
            <h5>•  Large rotary vane conveyor system for gentle transport <br/>
            •  Modern servo drive concept for accurate portioning<br/>
            •  S variant with servo-twisting drive<br/>
            •  Folding hopper variants: 100 L, 160 L, 250 L und 350 L<br/>
            •  Lifting/tipping device for 120- and 200-litre standard lifting trucks<br/>
            •  Very simple operation thanks to 12“ touchscreen control</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 736 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Pure power</h2>
            <h5>The RVF 736 is particularly designed for the processing of very cold or very tough meat emulsions. Thanks to its very strong servo drive, the RVF 736 achieves filling pressures of up to 70 bar; which is particularly useful in conjunction with REX MC-3 in-line grinder technology.</h5>
            <h5>The housing with its smooth and hygienic design is self-supporting and entirely made out of stainless steel. The extra robust rotary vane conveyor system ensures highest portioning accuracy with maximum life time. It ensures gentle transport and minimum air pockets in the final product.</h5>
            <h5>The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>The optional lifting/tipping device is available for 200-litre or 300-litre standard lifting trucks (optional). Two different hopper sizes can be selected according to the needs of the operation. Folding hoppers in volumes of 250 and 350 litres are available.</h5>
            <h5>The standard touchscreen controls make the appliance easy to operate and can be used to drive various clipping machines and attachments. The servo drive technology, used by REX for more than 25 years, has been especially strengthened for the RVF 736 in order to achieve maximum filling pressure. It goes without saying that the RVF 736 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial or natural casings easy.</h5>
            <br/>
            <h5>The advantages at a glance</h5>
            <h5>•  Large infeed system for gentle transport <br/>
            •  Minimal energy consumption<br/>
            •  Modern hygiene design<br/>
            •  Very simple operation thanks to 12“ touchscreen control<br/>
            •  Modern servo drive concept for highly accurate portioning<br/>
            •  The folding hopper allows simple cleaning and variety change</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 740 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Innovative solution</h2>
            <h5>The RVF 740 is designed for medium-sized meat-processing operations. Its smooth surface, hygienically designed, space-saving machine housing is self-supporting and entirely made out of stainless steel.</h5>
            <h5>The reinforced model of the large rotary vane conveyor system guarantees highest portioning accuracy for a maximum service life. No crushing or smearing: It ensures all kind of emulsions transported gently, especially with larger pieces of meat or raw sausage applications. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy. All models of the RVF 700 series can be equipped with a MC-3 in-line grinder.</h5>
            <h5>RVF 740 can be delivered with a lifting/tipping device for 200-litre standard lifting trucks (optional). Two different hopper sizes can be selected according to the needs of the operation. Folding hoppers in volumes of 250 and 350 litres are available.</h5>
            <br/>
            <h5>The advantages at a glance</h5>
            <h5>•  Large rotary vane conveyor system for gentle meat emulsion transport
            •  Modern servo drive concept for accurate portioning <br/>
            •  Very simple operation thanks to 12“ touchscreen operation<br/>
            •  Modern hygiene design<br/>
            •  Minimal energy consumption<br/>
            •  The folding hopper allows simple cleaning and variety change</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 760 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The innovative all-round solution</h2>
            <h5>The RVF 760 is designed for industrial use. The housing with its smooth and hygienic design is self-supporting and entirely made out of stainless steel and ensures efficient cleaning. The conveyor system has been designed in such a way that during cleaning water can flow out of the feed system.</h5>
            <h5>A simple pressure levelling piston ensures highest portioning accuracy. All models of the RVF 700 series can be equipped with a MC-3 in-line grinder.</h5>
            <h5>RVF 760 can be delivered with a lifting/tipping device for 200-litre standard lifting trucks (optional). Two different hopper sizes can be selected according to the needs of the operation. Folding hoppers in volumes of 250 and 350 litres are available.
            The feeder screw in the hopper ensures optimum in-feed for all non-viscous or viscous products into the rotary vane conveyor system.</h5>
            <h5>The standard touchscreen control makes the machine easy to operate and can be used to drive various clipping machines and attachments. There is also the option of attaching the REX hanging system.</h5>
            <h5>It goes without saying that the RVF 760 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial or natural casings easy. The RVF 760 can easily be equipped with the REX in-line grinder technology, thus providing users with a wide range of possible applications.</h5>
            <br/>
            <h5>The advantages at a glance</h5>
            <h5>•  Large rotary vane conveyor system for gentle transport <br/>
            •  Minimal energy consumption<br/>
            •  Modern hygiene design<br/>
            •  Very simple operation thanks to 12“ touchscreen operation<br/>
            •  Modern servo drive concept for accurate portioning<br/>
            •  The folding hopper allows simple cleaning and variety change</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 761 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The industrial power house</h2>
            <h5>The RVF 761 is particularly designed for the processing of very cold or very tough meat emulsions and guarantees to meet the highest performance expectations. Thanks to its extra strong design of the servo drive, the RVF 761 achieves filling pressures of up to 70 bar; which is particularly useful in conjunction with REX MC-3 in-line grinder technology.</h5>
            <h5>The housing with its smooth and hygienic design is self-supporting and entirely made out of stainless steel. The extra robust rotary vane conveyor system ensures highest portioning accurcy with maximum life time. It ensures gentle transport and minimum air pockets in the final product. The rotary vane conveyor system is available with eight, 12 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>The optional lifting/tipping device is available for 200-litre or 300-litre standard lifting trucks (optional). Two different hopper sizes can be selected according to the needs of the operation. Folding hoppers in volumes of 250 and 350 litres are available.</h5>
            <h5>The standard touchscreen controls make the appliance easy to operate and can be use to drive various clipping machines and attachments.The servo drive technology, used by REX for more than 25 years, has been especially strengthened for the RVF 761 in order to achieve maximum filling pressure. It goes without saying that the RVF 761 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial or natural casings easy.</h5>
            
            <br/>
            <br/>
            <br/>

            <h1>RVF 911 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The power pack</h2>
            <h5>RVF 911 is characterised by its almost unbelievable throughput performance which makes it suitable for industrial purposes. The increased pump capacity is the optimum solution for industrial applications. It can also be incorporated in line solutions with various clipping machines and attachments.</h5>
            <h5>The rotary vane conveyor system, whose diameter has been further enlarged compared to the 500 and 700 series, is truly the heart of this machine and ensures highest portioning accuracy. The rotary vane conveyor system is available with 8 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>As an option, the RVF 911 can be supplied with a fully integrated lifting/tipping device for standard 200 or 300 litre trolleys. The large 350 litre hopper version ensures maximum loading, but also the fast and easy cleaning when changing charges. The conveyor system has been designed in such a way that during cleaning water can flow out of the feed system. The feeder screw in the hopper ensures optimum in-feed for all non-viscous or viscous products into the rotary vane conveyor system.</h5>
            <h5>The standard touchscreen controls make the appliance easy to operate and can be use to drive various clipping machines and attachments.The servo drive technology, used by REX for more than 25 years, has been especially strengthened for the RVF 911 in order to achieve maximum filling pressure. It goes without saying that the RVF 911 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial or natural casings easy.</h5>

            <br/>
            <br/>
            <br/>

            <h1>RVF 913 - Vacuum filler</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>For an unbeatable performance</h2>
            <h5>The RVF 913 is designed for industrial use in meat-processing businesses as well as larger, sometimes highly specialized, processing units. Its main feature is its incredible filling capacity.
            A further increase in pump capacity compared with the RVF 913 represents the optimum solution for individual applications, but also for incorporation into complete line solutions with various clipping machines or other attachments. The housing with its smooth and hygienic design is self-supporting and entirely made out of stainless steel.</h5>
            <h5>The rotary vane conveyor system ensures highest portioning accuracy. The rotary vane conveyor system is available with 8 or 14 vanes, alternatively also with blind vanes. A simple pressure levelling piston ensures highest portioning accuracy.</h5>
            <h5>As an option, the RVF 913 can be supplied with a fully integrated lifting/tipping device for standard 200 or 300 litre trolleys. The large 350 litre hopper version ensures maximum loading, but also the fast and easy cleaning when changing charges. The conveyor system has been designed in such a way that during cleaning water can flow out of the feed system. The feeder screw in the hopper ensures optimum in-feed for all non-viscous or viscous products into the rotary vane conveyor system.</h5>
            <h5>The standard touchscreen controls make the appliance easy to operate and can be use to drive various clipping machines and attachments.The servo drive technology, used by REX for more than 25 years, has been especially strengthened for the RVF 913 in order to achieve maximum filling pressure. It goes without saying that the RVF 913 can always be fitted with a rotating or fixed casing holding device to make twisting of artificial or natural casings easy.</h5>

            <br/>
            <br/>
            <br/>

            <h1>Casing Holder 320/400</h1>
            <h5>The rotating casing holder from REX can be used as an attachment with all REX vacuum filling machines of the 300, 400, 700, and 900 series. Having no independent drive, it is attached to and driven by the standard REX clip-off drive module. The REX DHG can deposit the clipped-off sausages on a filling table or transfer them directly to the REX RKS 85 calibration system.
            The rotating REX DHG effortlessly processes all variants – with natural, collagen, and artificial casings – and guarantees precise clip-off points and the highest efficiency.</h5>
            <h5>Two different variants of the DHG are available: Type 320 and Type 400. The only difference between the two is the length of compatible clip-off pipes. The best option can be chosen according to casing type and length (for artificial casing).
            The REX DHG 400 can be expanded with a pneumatically driven feed with an end-of-casing detection system to further increase productivity Optional splash protection can also be installed, significantly increasing not only operating convenience, but also occupational safety, especially during natural sausage casing processing.</h5>

            <br/>
            <br/>
            <br/>

            <h1>HBF 120 - Minced meat burger former</h1>
            <img src={hbf1} />
            <br/>
            <img src={hbf2} />
            <br/>
            <img src={hbf3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The innovative minced meat burger shaper</h2>
            <h5>The new minced meat burger shaper from REX-Technologie is an innovative solution for producing loose, minced meat burgers. The conveyor belt is connected to the MC 3-3 filler of the REX vacuum filler. The minced meat strand is led by the conveyor belt to the newly developed shaping tool and formed into a burger. The shaping tool is available as a simple or triple model.
            Portioning is ensured by the REX vacuum filler; the minced meat is shaped in the filler. The shaping tools are variable and can be adapted in line with the desired product size. The hygienic design enables simple and quick cleaning.</h5>
            <h5>Continual operation is ensured. With a shaping output of up to 40 portions/min. in the simple model and 120 portions/min. in the triple model, the minced meat burger shaper is also suitable to any industrial application in conjunction with the tray loader.
            Optional UV sterilisation for the conveyor belt guarantees the highest possible level of bacterial eradication thus enhancing hygiene standards.</h5>

            <br/>
            <br/>
            <br/>

            <h1>Cevapcici Line RCL 300</h1>
            <img src={hcapavakiline1} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The innovative line solution for cevapcici and grillers</h2>
            <h5>The RCL 300 is the automatic solution for multi-lane forming of cevapcici, grillers or sticks directly deposited on to the baking or drying trays. It is possible to form 2 to 8 lanes at the same time up to a maximum diameter of 30 mm. Rectangular outlets are also available as an option.</h5>
            <h5>The line is directly connected to the vacuum filler, the belt speed for the depositing trays is adapted to the filling speed. The portion is cut off with a pneumatic guillotine at the outlet opening, after which the portion is deposited directly onto the sheet/grid.</h5>
            <h5>Furthermore, the RCL 300 offers an automatic solution by means of an optional sprinkling device for sprinkling the product surface with pepper, chili or other types of spices.</h5>
            <h5>Vedio : <a href="https://youtu.be/-no0Edftqbo">Click Here</a></h5>

            <br/>
            <br/>
            <br/>

            <h1>Patty former RBF 125</h1>
            <img src={hcapavakiline1} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>For perfect forming</h2>
            <h5>The REX RBF 125 burger machine provides the ideal mechanical solution to shape round, oval or square burgers for all small to medium sized businesses. The burger machine is easy and uncomplicated to use and connect to the REX Vacuum filling machine and is controlled by use of the Vacuum Fillers control panel. The Vacuum Filler then fils the appropriate portion size into the selected mould; the mould is then moved horizontally by pneumatic cylinders and ejected onto the conveyor belt. No special tools are required for the easy change of the various moulds depending on the desired size. This ensures a quick changeover between different products.</h5>
            <h5>The RBF 125 burger machine is mounted on to the FB 100 conveyor belt. After moulding, the burgers are pushed onto the belt which transports them away; they can then easily be lifted by hand from the belt. For very sticky mixtures the burger machine comes as standard with a water spray device, thus ensuring a trouble free operation.</h5>
            <h5>The burger sizes from 60-130 mm in diameter and 8-25 mm in height can be easily selected by inserting a different mould disc.</h5>
            <br/>
            <h5>Vedio : <a href="https://youtu.be/7yxMOAhhnBI">Click Here</a></h5>

        </div>
     </div>
     
      <Footer/>
    </div>
  )
}

export default VaccumeFillers
