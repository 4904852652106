import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import gradingline from '../images/gradingline.png';

function GradingLine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={gradingline} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>GRADING LINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The grading and weighing system “Aexi” is today the most technically advanced system to program and control the production into the poultry processing plant. It can be installed on separate weighing lines, or on existing dripping line or on a single bird chilling line. The data coming from the weighing bridge are sent to the computer which give all the information needed. All the parameter regarding the min.-max. weight for each station can be easily changed and showed  on screen.
                    The most important are: Speed of the line. Total daily number of birds process. Total daily weights of birds process. Total number of birds dropped in each beams. Total weight of birds in each drop-off. Capacity up to 10.000 b/h at 8″ center shackles. Drop-off number:  up to 16/32 weight selections.</h5>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default GradingLine
