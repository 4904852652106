import React from 'react';
import '../styles/Testimonials/testimonials.css';
import leg from '../images/leg.png';
import leaf from '../images/leaf.png';
import tomato from '../images/tomato.png';
import beans from '../images/beans.png';
import quote from '../icons/quote.png';
import user from '../images/user.png';

function Testimonials() {
  return (
    <div className='testimonials'>
      <div className='testimonials-inner'>
        <img src={leg} alt="pic" className='leg'/>
        <img src={leaf} alt="pic" className='leaf'/>
        <img src={tomato} alt="pic" className='tomatoes'/>
        <img src={beans} alt="pic" className='beans'/>
        <div className='heading'>
            <h5 className='sub'>Testimonials</h5>
            <h1 className='header'>Why People Beleive in Us!</h1>
        </div>
        <div className='users'>
            <div className='user'>
                <img src={quote} alt="" />
                <h5>"I don't always clop, but when I do, it's because of spare parts. Thanks a lot for you quality service. "</h5>
                <div className='user-details'>
                    <img src={user} alt="" />
                    <h5 className='name'>Bobby Riley</h5>
                </div>
            </div>
            <div className='user'>
                <img src={quote} alt="" />
                <h5>"I was amazed at the quality of machinaries. Buy this now. It's really wonderful. I'm good to go. "</h5>
                <div className='user-details'>
                    <img src={user} alt="" />
                    <h5 className='name'>Jennie Nichols</h5>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
