import React from 'react'
import email from '../icons/email.png';
import telephone from '../icons/telephone.png';
import pin from '../icons/pin.png';
import '../styles/ContactContent/contactcontent.css';

function ContactContent() {
  return (
    <div className='contactcontent'>
        <h1 className='shadow'>Contact</h1>
      <div className='contactcontent-inner'>
        <div className='up'>
            Contact Us
        </div>
        <div className='down'>
            <div className='up'>
                <h1>Quick. <br/> Support</h1>
                <h5>YOU CAN FET ALL THE CONTACT INFORMATION.</h5>
            </div>
            <div className='down'>
                <div className='box'>
                    <img src={pin} alt="icon" />
                    <h2>Visit Us</h2>
                    <h5>Head Office <br/>
                        No. 399/3, <br/>
                        Kandy Road, <br/>
                        Kirillawala, <br/>
                        Sri Lanka. 
                    </h5>
                </div>
                <div className='box'>
                    <img src={telephone} alt="icon" />
                    <h2>Call Us</h2>
                    <a href='tel:+94 (11) 428 4545'>+94 (11) 428 4545</a>
                    <a href='tel:+94 (11) 428 4546'>+94 (11) 428 4546</a>
                    <a href='tel:+94 76 424 4545'>+94 76 424 4545</a>
                </div>
                <div className='box'>
                    <img src={email} alt="icon" />
                    <h2>Email Us</h2>
                    <h5>sanka@euromatefoodtech.com</h5>
                    <h5>trading@euromatefoodtech.com</h5>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ContactContent
