import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Hero/hero.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function Hero() {
  return (
    <div className='hero'>
      <div className='hero-inner'>
        <h1>POWERFUL, SAFE</h1>
        <h1>QUALITY MACHINARIES</h1>
        <h5 className='sub'>MACHINES THAT MATTERES - TO YOU, INDUSTRIES AND TO THE PLANET WE ALL SHARE.</h5>
        <h5 className='sub'>WE TAKE ON EVERY CHALLENGE</h5>
        <Link to='/about' className='learn-more-btn'>
            <h5 className='learnbtntext'>Learn More</h5>
            <ArrowRightAltIcon fontSize='large' style={{
                color: 'white'
            }} className='hero-arrow'/>
        </Link>
      </div>
    </div>
  );
}

export default Hero;
