import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import continuousvaccumpackagingmachine from '../images/continuousvaccumpackagingmachine.png';

function ContinuousVaccumePackgingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>CONTINUOUS VACCUM PACKAGING</h1>
            <img src={continuousvaccumpackagingmachine} />
            <h5>Part of our belted series of vacuum chamber machines is our unique CV line that features an adjustable tilting deck. Both the CV-1000 and CV-1200 are capable of achieving up to a 24-degree tilt that will allow for wet products to be packaged without spillage; cutting down on waste and keeping the area clean.</h5>
            <br/>
            <h5>Equipped with a conveyor that automates the unloading process and automatic lid application, these machines can easily be used with a single operator. Some of the standard features include Busch vacuum pumps, programmable water-resistant controls, 10 mm wide seal and stainless steel construction. The vacuum pumps on these machines are located on a separate stainless steel cart that can be placed in any desired location. Because the pump is not located within the machine, the customer is not limited when selecting the pump size.</h5>
            <br/>
            <h5>In addition to all the other great features, our CV line has water-cooled seal bars that allow for maximum cycle speed while preventing premature seal wire failure. In the rare event that a seal wire does break, the integrated seal wire alert system will sound an alarm and halt operation in order to notify the operator. The fold-down side doors make accessing the drive motors and circuitry very convenient, and cleanup is also easy with the wash-down safe conveyor belt and enclosed electrical components.
            Our CV series provides a number of great features at an amazing value. These machines solve problems associated with packaging products that are not as uniform or as clean as others. While other companies ignore these issues and expect customers to deal with the waste and unsanitary conditions engendered by the lack of a tilting deck, Promarks has solved this problem easily and passes that solution on to our customers. Our cost-effective machines solve the issue of handling foods or other products containing liquids.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>MODEL CV-1200</h2>
            <h5>Stainless Steel Construction</h5>
            <h5>Stainless Steel Chain</h5>
            <h5>PNC-01 Microprocessor Control</h5>
            <h5>Water Cooling Seal Bar</h5>
            <h5>Busch RA0305 (12HP) Vacuum Pump</h5>
            <h5>Gas Flush System</h5>
            <h5>Custom Seal Bar Configurations</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>MODEL CV-1000</h2>
            <h5>Stainless Steel Construction</h5>
            <h5>Stainless Steel Chain</h5>
            <h5>PNC-01 Microprocessor Control</h5>
            <h5>Water Cooling Seal Bar</h5>
            <h5>Busch RA0100 (5HP) Vacuum Pump</h5>
            <h5>Gas Flush System</h5>
            <h5>Custom Seal Bar Configurations</h5>
            <h5>Higher Capacity Vacuum Pump</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ContinuousVaccumePackgingMachine
