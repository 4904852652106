import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/wastetreatementproducts.css';
import screwfilterseparator from '../images/screwfilterseparator.png';

function ScrewFilterSeparator() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={screwfilterseparator} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>SCREW FILTER SEPARATOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine is used to separate from the water and lift into the container the feather and offal coming from the processing plant. At the bottom of the first section of the screw a Jonson filter with 0,5mm clearance separates from the water the feather and the offal. The screw keeps the filter clean thanks to brushes bolted on it. The machine is made of stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">RSU 500</div>
                            <div class="box len">RSU 700</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">140</div>
                            <div class="box len">360</div>
                            <div class="box len">m <sup>3</sup></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">6.300</div>
                            <div class="box len">7.700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">700</div>
                            <div class="box len">1.100</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1650</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">0,1</div>
                            <div class="box len">0,2</div>
                            <div class="box len">m <sup>3</sup>/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">3</div>
                            <div class="box len">4</div>
                            <div class="box len">k2</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">800</div>
                            <div class="box len">1.200</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">4</div>
                            <div class="box len">7,6</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ScrewFilterSeparator
