import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import frozenblockcutter from '../images/frozenblockcutter.png';
import frozenblockcutter02 from '../images/frozenblockcutter02.PNG';
import frozenblockcutter01 from '../images/frozenblockcutter01.PNG';

function FrozenBlockCutter() {
  return (
    <div>
      <Navbar/>  
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FROZEN BLOCK CUTTER</h1>
            <img src={frozenblockcutter} />
            <h5>This machine for cutting frozen blocks of meat or other products at -20o C. The clean cut and size of the pieces obtained from the block make them suitable for subsequent processing in the mincer, thereby reducing the time and effort required by these machines.</h5>
            <h5>The machine cuts the blocks with a guillotine-type blade operated by an extremely powerful hydraulic system. The standard machine is equipped with 1 horizontal blade and 4 vertical blades, so produces 4-centimetre-thinck by 10/12-centimetre-width slices.</h5>
            <h5>The block loading is carry out by an incorporated loader with a loading capacity of 100 kg. The cut product is un- loaded directly onto standard 200-litre trolleys.</h5>
            <h5>The TBG 480 can work with E2 block measures (480x250 mm). It achieves a production of 3000 kg/hour.</h5>
            <h5>The TBG 630 can work with E3 block measures (630x300 mm) in its transverse position. It achieves a production of 7000 kg/hour.</h5>
            <h5>Machines designed and manufactured in accordance with CE hygiene and safety standards.</h5>

            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Hygiene:</h2>
            <h5>· Completely stainless machines.</h5>
            <h5>· Lines and smooth surfaces to facilitate cleaning.</h5>
            
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Hygiene:</h2>
            <h5>· Motors protected against heat and over-intense operation.</h5>
            <h5>· Press-button controls with IP66 protection.</h5>
            <h5>· Operating voltage: 24 V.</h5>
            
            <img src={frozenblockcutter02} alt="product image"/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Technical Data</h2>
            <img src={frozenblockcutter01} alt="product image"/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FrozenBlockCutter
