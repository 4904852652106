import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css';
import shrimporiginal from '../images/shrimporiginal.PNG'; 
import animalfeed2 from '../images/animalfeed2.PNG'; 
import { Link } from 'react-router-dom';

function FeedProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1>Feed Products</h1>
        </div>
        <div className='products'>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={shrimporiginal}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Shrimp Feed Pallet
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/shrimporiginal'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={animalfeed2}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Animal Feed Pallet
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/animalfeed'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
      </div>
    </div>
  )
}

export default FeedProducts
