import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishskinnermachine from '../images/fishskinnermachine.png';
import fishskinnermachine2 from '../images/fishskinnermachine2.PNG';
import fishskinnermachine3 from '../images/fishskinnermachine3.PNG';
import fishskinnermachine4 from '../images/fishskinnermachine4.PNG';

function FishSkinnerMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH SKINNER MACHINE</h1>
            <img src={fishskinnermachine} />
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>FIsh Skinning Machine Ibsreuction</h2>
            <h5>The fish skinning machine is designed to scrape the fish skin from fish fillets. It is weidely suitable for various fish, such as cod, sleeve-fish, catfish,crap, grouper, pike, salmon and tilapia etc.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Technical Parameters</h2>
            <h5>Capacity - 30-50pcs/min</h5>
            <h5>Volatge - 220v/380v</h5>
            <h5>Power - 750w</h5>
            <h5>Weight - 98kg</h5>
            <h5>Dimension - 620*570*900mm</h5>
            <br/>
            <img src={fishskinnermachine2} />
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Fish Skinning Machine Features and Benefits</h2>
            <h5>Made of stainles steel, smooth surface for easy and fast cleaning and the best possible hygiene.</h5>
            <h5>Adopt modular design, convenient to dismantle and clean.</h5>
            <h5>Easy and safe to operate, the workers can learn to use quickly.</h5>
            <h5>The machine is with a qua;ity blade holder, which can ensure the skinning rate reaches 99%.</h5>
            <h5>Hish skinning effiency, excelent peeling effect.</h5>
            <h5>Equip with water cleaning until for steady conrinuous operation when processing fresh fillets.</h5>
            <br/>
            <img src={fishskinnermachine3} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Fish Skinning Machine Application</h2>
            <h5>The fish skinning machine can skin various fish such as sleeve fish, catfish, grouper, salmon, pike , tilapia and snapper etc. The skinned fish fillets and fish all can be used to make delicious food. The skinning machines are ideal for fish markets. Supermarkets, and fish processing factors.</h5>
            <br/>
            <img src={fishskinnermachine4} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Fish Skinning Machine Working Pronciple</h2>
            <h5>The fish is operated by manual. Place the fillets with skin side down on the fish skinning machine. For the machine equiped with water spraying device. The fish fillets can reach the blade holder smoothly. Then the fish skin is scraped off the fillets. The skinning machine scrape the skin without causing any strain on the fish meat or the skin because little water is used in the skinning process. The fillets will generally have a higher quality as bacteria growth is redued.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FishSkinnerMachine
