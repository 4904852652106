import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import lungremover from '../images/lungremover.png';

function LungRemover() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={lungremover} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>LUNG REMOVER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This automatic final control machine is used to suck by vacuum all the loose pieces and lungs from the birds cavity. The machine is built in stainless steel and the cam is made of solid nylon.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">12U</div>
                            <div class="box len">16U</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">3.000</div>
                            <div class="box len">6.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">970</div>
                            <div class="box len">1.150</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.260</div>
                            <div class="box len">1.260</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.250</div>
                            <div class="box len">2.250</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">1,5</div>
                            <div class="box len">1,5</div>
                            <div class="box len">I/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Vaccum usage</div>
                            <div class="box len">400</div>
                            <div class="box len">600</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">450</div>
                            <div class="box len">500</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">2,8</div>
                            <div class="box len">3, 5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default LungRemover
