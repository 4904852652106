import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import frenchfriesmachine from '../images/frenchfriesmachine.png';

function FrenchFiresMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>FRENCH FRIES MACHINE</h1>
            <img src={frenchfriesmachine} />
            <h5>Commodity : French Fries (Stripped) Cutting Machine</h5>
            <h5>Discretion : High Quality </h5>
            <h5>Voltage : 380V/ 50Hz </h5>
            <h5>Power : 1500W </h5>
            <h5>Capacity : 300-600Kg/H </h5>
            <h5>Cutting Size : 8, 10, 12 MM (6MM Can be customized Material : 
            SUS 304 Dimension : 950 x 700 x 900 mm</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FrenchFiresMachine
