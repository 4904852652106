import React from 'react';
import '../styles/Firm/firm.css';
import Group from '../images/Group 1.png';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import home from '../icons/home.png';
import support1 from '../icons/support.png';
import support2 from '../icons/support (1).png';
import { Link } from 'react-router-dom';
import setting from '../icons/setting.png';

function FirmSection() {
  return (
    <div className='firm'>
      <div className='firm-inner'>
        <div className='section1'>
            <div className='inner'>
                <img src={Group} alt="" />
            </div>
        </div>
        <div className='section2'>
            <h5 className='sub'>About Us</h5>
            <h1 className='header'>About Our Team</h1>
            <h5 className='para'>Last past years we provideed services with trust and honestly. We have our customers with best machinaries. We have experts who verify everything to that customers can not face any difficulties.</h5>
            <div className='icons'>
                <div className='icon'>
                    <img src={setting} alt="" />
                    <h5>Best Machines</h5>
                </div>
                <div className='icon'>
                    <img src={home} alt="" />
                    <h5>Our Warehouses</h5>
                </div>
                <div className='icon'>
                    <img src={support1} alt="" />
                    <h5>Customer Service</h5>
                </div>
                <div className='icon'>
                    <img src={support2} alt="" />
                    <h5>Team Support</h5>
                </div>
            </div>
            <hr/>
            <h5>Learn more about out benefits</h5>
            <Link to='/about'>
                <ArrowRightAltIcon fontSize='large' style={{
                    color: 'white',
                    cursor: 'pointer'
                }}/>
            </Link>
        </div>
      </div>
    </div>
  );
}

export default FirmSection;




