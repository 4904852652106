import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ProductSelector from '../components/ProductSelector';
import MeatProducts from '../components/MeatProducts';
import '../styles/Products/products.css';

function Products() {
  return (
    <div style={{
      backgroundColor: '#151618'
    }}>
      <Navbar/>
      <div className='wrapper' style={{
        maxWidth: '1000px',
        minHeight: '50vh',
        margin: '0 auto',
        display: 'flex',
        color: 'white',
        gap: '10px',
      }}>
        <ProductSelector/>
        <MeatProducts/>
      </div>
      <Footer/>
    </div>
  );
}

export default Products;
