import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import onlinefeetunloader from '../images/onlinefeetunloader.png';
import rotatingfeetunloader from '../images/rotatingfeetunloader.png';

function FeetUnloader() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={rotatingfeetunloader} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>ROATATING FEET UNLOADER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic feed unloader, placed next to our leg remover, removes all the feet from the shackles. The machine and guiding bars are made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">12.0000</div>
                            <div class="box len">b/ h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.100</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,75</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">88</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,8</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={onlinefeetunloader} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>ON LINE FEET UNLOADER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic feed unloader is placed next to the leg cutter and removes from the shackles all the feet of the birds. The frame and guiding bars are in stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">850</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">140</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">1,3</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default FeetUnloader
