import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import screwelevator from '../images/screwelevator.png';

function ScrewElevator() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={screwelevator} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>GIZZARD SCREW ELEVATOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine can be used in several points of the processing plant especially for lifting the livers, the gizzards, the heard and the necks to the collecting table. The machine is made of stainless steel.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">9.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.350</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">0,50</div>
                            <div class="box len">m3</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,37</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">60</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">0,8</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default ScrewElevator
