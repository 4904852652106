import React from 'react';
import '../styles/Map/map.css';

function GoogleMap() {
  return (
    <div className='map'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6660.218777767164!2d79.89970006481902!3d6.986912904890683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25809ddc130eb%3A0xf7d6eddd59995b27!2sEuromate%20Foodtech%20Pvt%20Ltd!5e0!3m2!1sen!2slk!4v1664986431255!5m2!1sen!2slk" width="600" height="450" loading="lazy" className='real-map'></iframe>
    </div>
  );
}

export default GoogleMap;
