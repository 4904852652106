import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishfilletingmachine from '../images/fishfilletingmachine.png';

function FishFilletingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH FILLETING MACHINE</h1>
            <img src={fishfilletingmachine} />
            <h5>The machine is designed for cutting fresh or semi-defrosted raw.
            The machine opens the abdominal cavity and cuts the central spine bone, leaving the costal bones.
            The machine produces the filleting process with high precision and high productivity.</h5>
            <h5>• Power characteristics  `   3 phases, 380 V or 220 V, 1.5 kW</h5>
            <h5>• Dimensions, mm: length x width x height; (Weight, kg) 1000 x 700 x 1350, 256 kg</h5>
            <h5>• Productivity, fish / min.    30~40</h5>
            <h5>• Processed fish species     Salmon, trout, pink salmon and other large breeds.</h5>
        </div>
     </div>
      <Footer/>
    </div>
  )
}

export default FishFilletingMachine
