import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import addingoilsausesmachine from '../images/addingoilsausesmachine.PNG';

function EuipmentsForAddingOilAndSause() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>EQUIPMENTS FOR ADDING <br/>OIL AND SAUSES</h1>
            <img src={addingoilsausesmachine} alt="oilandsause" className='image'/>
            <br/>
            <h5>Designed to add oil or sauces in a curtain or overflowing the containers.</h5>
            <h5>The surplus oil or sauce is filtered before returning to the accumulation tank and is subsequently pumped back to the dosing units, using an open impeller centrifugal pump.</h5>
            <h5>Fitted with a mixer in the case of sauces.</h5>
            <h5>An energy efficient machine:</h5>
            <br/>
            <h5>(1.) Fitted with a closed coil and purger to recover condensates.</h5>
            <h5>(2.) Automatic temperature control and modulated steam entrance valves.</h5>
            <br/>
            <h5>Made of AISI-304 stainless steel.</h5>
            <h5>Supplied with EC certificate.</h5>
            <h5>Constructed with one or several dosing units, depending on the required production and the format to be produced.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default EuipmentsForAddingOilAndSause;
