import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css';

function OtherProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1>Other Products</h1>
        </div>
        <div className='products'>

        </div>
      </div>
    </div>
  )
}

export default OtherProducts
