import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import nozzeltypepackagingmachine from '../images/nozzeltypepackagingmachine.png';

function NozzleTypeVaccumPackagingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>NOZZLE TYPE VACCUM PACKAGING</h1>
            <img src={nozzeltypepackagingmachine} />
            <h5>Promarksvac manufactures a complete line of nozzle type vacuum sealing equipment that includes table top machines, freestanding units in a wide variety of seal lengths as well as vertical and custom units. Nozzle type vacuum machines can be the solution to many unique situations, including applications using very large bags and applications where the finished package needs to be in “pillow pack” form among many others. </h5>
            <h5>As a manufacturer of both chamber type vacuum seals as well as nozzle type machines, Promarksvac is ideally placed to provide your company with the vacuum packaging solution that fits your specific needs.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>• MODEL NZ 600/1000/1500/2000/2500/3000 VACUUM</h5>
            <h5>•  Heavy-duty Stainless Steel Construction</h5>
            <h5>•  Busch Vacuum Pump (NZ-1000/1500/2000/2500/3000)</h5>
            <h5>•  Castors</h5>
            <h5>•  Foldable Table</h5>
            <h5>•  PNC-01 Microprocessor Control</h5>
            <h5>•  Gas Flush System</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Bag Holding Clips</h5>
            <h5>•  Top and Bottom Seal</h5>
            <h5>•  Voltage</h5>
            <h5>•  Sensor Control</h5>
            <h5>•  Gas Tank</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default NozzleTypeVaccumPackagingMachine
