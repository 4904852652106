import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/birdsproducts.css';
import cutupline from '../images/cutupline.png';

function CutupLine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={cutupline} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CUT-UP LINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The cut up line model “COMPACT” consists of a stainless steel frame on which are installed the cutup modules to be used for automatic cut up of chickens. To get the best yield the weight difference in the flock cannot exceed 500 gr. All the portioning machines fitted on the line can be adjusted . The standard line is 5,5 m. long and it is supplied with drive, tension unit, stainless steel shackles, electric control panel and safety device. Upon request and according to the nomber of cuts required, the line can be estended up to 16,5 m.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">6.000</div>
                            <div class="box len">B /h</div>
                        </div>
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">5.500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.285</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">1,1 - 1,5</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">550</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">8,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CutupLine
