import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import sausageloadingmachine from '../images/sausageloadingmachine.png';
import sausageloadingmachine1 from '../images/sausageloadingmachine1.PNG';
import sausageloadingmachine2 from '../images/sausageloadingmachine2.PNG';
import sausageloadingmachine3 from '../images/sausageloadingmachine3.PNG';
import sausageloadingmachine4 from '../images/sausageloadingmachine4.PNG';
import sausageloadingmachine5 from '../images/sausageloadingmachine5.PNG';
import sausageloadingmachine6 from '../images/sausageloadingmachine6.PNG';
import sausageloadingmachine7 from '../images/sausageloadingmachine7.PNG';

function SausageLoadingSystem() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>RKS 85 - Calibration system</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>The calibration genius</h2>
            <h5>The REX RKS is the universal solution for the exact calibration of all artificial and natural casings; it can also be adapted or integrated into various process solutions. It was designed to meet the requirements of different-sized operations. The most important aspect of calibration is to define an exact length and best possible weight. The ability to switch easily from artificial to natural casings and vice versa is a further important faction towards efficiency and versatility.</h5>
            <h5>The sausages twisted off by the holding device are directly passed on to the two conveyer belts of the RKS. This arrangement optimizes the twisting-off process and minimizes the number of rejects due to split skins. The conveyor belts are height-adjustable and are thus easy to set for all casing sizes. In the RKS, casings are calibrated to the desired diameter, thus ensuring that the sausages produced are of identical weight and length. This increases the product quality, and allows you to meet your specific quality criteria.</h5>
            <h5>The RKS is equipped with servo drives that are set and controlled via the touchscreen controls of the filling machine. The belt speeds are thus automatically adjusted to the specific product and portion size. This ensures that adjustment times are kept to a minimum.</h5>
            <h5>After transport, the calibrated sausage chain is passed on to the RHS in a controlled fashion, where it is directly placed onto hooks. From there, they can subsequently be removed with a smoke bar.</h5>
            <br/>
            <img src={sausageloadingmachine} />
            <br/>
            <img src={sausageloadingmachine1} />
            <br/>
            <img src={sausageloadingmachine2} />

            <br/>
            <br/>
            <br/>

            <h1>RHS 230 - Hanging system</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Makes hanging easy</h2>
            <h5>The RHS 230 by REX is the effective solution to hang the sausages immediately after the calibrating unit RKS 85 or directly from the casing holder of the vacuum stuffer. From there, the twisted-off sausage chain is taken up immediately by the hooks of the RHS and hung, to allow their removal with the smoke bar.</h5>
            <h5>The RHS 230 is height-adjustable and thus easy to set to the height of the vacuum filler or the RKS 85.</h5>
            <h5>The RHS 230 is fitted with a servo drive which is driven and controlled via the touchscreen controls of the filling machine. It can thus be easily adjusted to the product and the respective portion size, thus guaranteeing very short downtimes for changeover and adjustments. Depending on the length of the casing or the product, different-sized groups with different hook numbers to be positioned at the RHS can be defined at the control for specific programmes.</h5>
            <br/>
            <img src={sausageloadingmachine3} />
            <br/>
            <img src={sausageloadingmachine4} />
            <br/>
            <img src={sausageloadingmachine5} />
            <br/>
            <img src={sausageloadingmachine6} />
            <br/>
            <img src={sausageloadingmachine7} />
            
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default SausageLoadingSystem
