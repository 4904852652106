import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/wastetreatementproducts.css';
import recyclingpump from '../images/recyclingpump.png';

function RecyclingPump() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={recyclingpump} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>RECYCLING PUMP</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This pump is used to recycle the water coming from the feather separator and so it can be used again to carry all the waste and feathers out of the defeathering area.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">2.400</div>
                            <div class="box len">l/min</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">640</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">1,3</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">65</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,2</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default RecyclingPump
