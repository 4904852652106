import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import boxesconveyor from '../images/boxesconveyor.png';

function BoxConveyor() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={boxesconveyor} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BOX CONVEYOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The distribution boxes overhead conveyor has been designed to carry the boxes used for packing into the different areas of the processing plant. This reduce the labor cost to manually carry the boxes or the plastic trays, save space on the floor and keep the production paces. Special hooks are available to carry any kind of box or tray.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table"> 
                        
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default BoxConveyor;
