import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/ProductSelector/productselector.css';

function ProductSelector2() {
  return (
    <div className='selector'>
      <div className='selector-inner'>
        <div className='header'>
            <h1>Categories</h1>
        </div>
        <div className='links'>
            <NavLink className='link' to='/chicken'>Live Birds Handling</NavLink>
            <NavLink className='link' to='/defeathering'>Defeathering</NavLink>
            <NavLink className='link' to='/eviscreation'>Eviscreation</NavLink>
            <NavLink className='link' to='/chilling'>Chilling & Weighing</NavLink>
            <NavLink className='link' to='/cutup'>Cut Up</NavLink>
            <NavLink className='link' to='/waste'>Waste Treatement</NavLink>
            <NavLink className='link' to='/products' style={{background: 'red'}}>Back</NavLink>
        </div>
      </div>
    </div>
  )
}

export default ProductSelector2
