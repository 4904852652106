import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/wastetreatementproducts.css';
import offaltank from '../images/offaltank.png';

function OffalTank() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={offaltank} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>OFFAL TANK</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This tank is used to collect every kind of waste sucked by vacuum (blood, lungs, viscera). It is complete with legs and discharging valve. The tank is made of hot dip galvanized steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">800</div>
                            <div class="box len">1.500</div>
                            <div class="box len">2.200</div>
                        </div>
                        <div class="row">
                            <div class="box">Diameter</div>
                            <div class="box len">800</div>
                            <div class="box len">1.000</div>
                            <div class="box len">1.200</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.800</div>
                            <div class="box len">2.320</div>
                            <div class="box len">2.320</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">230</div>
                            <div class="box len">400</div>
                            <div class="box len">460</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">3</div>
                            <div class="box len">5</div>
                            <div class="box len">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default OffalTank
