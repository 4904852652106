import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import container from '../images/container.png';

function Container() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={container} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CONTAINER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This container has been designed to easily transport all type of live birds to the processing plant. It can easily replace the existing standard plastic crates without loosing space on the track and having more free surface on the floor it can be loaded with more birds. It can be made in two models with 4 or 5 floors. The frame is made of hot deep galvanized steel or, upon request, in stainless steel. The sides are made of st.st. net, the floor in thermoformed plastic material while the front door, created for automatic downloading with Minidumping, is in aluminum casting.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Dimentione 4 piani</div>
                            <div class="box len">1.160 * 1.200 * 1.210</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Peso netto 4 piani</div>
                            <div class="box len">170</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Dimensione 5 piani</div>
                            <div class="box len">1.160 * 1.200 * 1.460</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Peso netto 5 piani</div>
                            <div class="box len">195</div>
                            <div class="box len">kg</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Container
