import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import meatfishseparator from '../images/meatfishseparator.png';
import meatfishseparator1 from '../images/meatfishseparator1.PNG';
import meatfishseparator2 from '../images/meatfishseparator2.PNG';

function MeatFishSeparator() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH BONE SEPARATOR</h1>
            <img src={meatfishseparator} />
            <h5>Our Meat separator solid construction mechanical separators are built for extended use in versatile applications. Its simple design allows for easy teardown and assembly.</h5>
            <img src={meatfishseparator1} />
            <h5>Application: – deboning – soft separation – grinder</h5>
            <h5>Dimensions: A. 87 in. (2210 mm) B. 32 in. (813 mm) C. 69 in. (1753 mm)</h5> 
            <h5>Capacity: – Input 2500-4000kg/hr depending on raw materials – Throughput will vary due to input material</h5>
            <h5>Raw Material: – Chicken – Turkey – Pork – Fish</h5>
            <h5>Technical: – Belt driven 50Hp (37 kW) – Inline Feed/Deboning Auger</h5>
            <img src={meatfishseparator2} />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default MeatFishSeparator
