import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import animalfeed1 from '../images/animalfeed1.PNG';
import animalfeed2 from '../images/animalfeed2.PNG';
import animalfeed3 from '../images/animalfeed3.PNG';

function AnimalFeedPallet() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>ANIMAL FEED PALLET MACHINE</h1>
            <img src={animalfeed1} />
            <br/>
            <h5>pound feed manufacture is undergoing a great technological makeover. One of the Clatest such technologies being, “Modular compound-feed installations”. These installations contain the required process machines, based on module that can fit the size of a 20ft shipping container. The concept offers plenty of benefits, which relate to engineering, pre-assembly, transport, assembly time at the customer's site, and the amount of construction equipment and number of installation personnel required. Building requirements are less stringent for a modular compound-feed installation than for a traditional installation and it allows a great degree of flexibility. The modular character of this installation design offers for modification and expansion of the compound-feed installation, at a later date. The compound-feed installation can be adapted to suit the requirements and growth of the company. Modular installations are available with a wide range of capacities from 1- 45 t/h. Container concept This unique solution provides a perfect installation, by significantly cutting the lead time of a project and reducing the costs, without affecting performance and functionality. It consists in dividing the plant into modules that are each the size of a standard transport container (20 ft.) and these container-modules facilitate transport by road or ship and hence no unnecessary extra costs are incurred for special transport. Moreover, the construction of the containers provides maximum protection for the process installations during transport and assembly.</h5>
            <br/>
            <img src={animalfeed2} alt="product image"/>
            <br/>
            <h5>As these container modules have been officially certified, they can be used as standard shipping containers during shipping. Cost-savings In addition to being used for transport, once the container frame arrives at its destination it can also be used as a steel structure for the tower. This has also been taken into account in the calculations for the container module (building codes). This reduces the number of shipping containers that are normally required for the shipping of a certain amount of steel for a traditional tower. Modular compound-feed plants are built in a more compact way and rarely exceed a height of 10 metres. The low weight of the tower means that there is significantly less load on the floor, and only a small foundation is needed for the stable construction of the tower. This saves on costs for steel, the tower, the assembly and above all the foundation. The low height of the tower, which is assembled indoors, means that the tower is not affected by wind and weather conditions.</h5>
            <br/>
            <img src={animalfeed3} alt="product image"/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AnimalFeedPallet
