import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fruitscuttingmachine from '../images/fruitscuttingmachine.png';

function FruitsCuttingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>FRUITS CUTTING MACHINE</h1>
            <img src={fruitscuttingmachine} />
            <br/>
            <h5>High Quality Fruits Cutting Machine with Competitive Price. Sourcing Fruits Cutting Machine from China Now! SGS Audited Suppliers. Taiwan’s B2B Impact Award. Quality China Products. Leading B2B Portal. Types: Manufacturing Machinery, Processing Machinery. Ideal for cutting fresh Fruits in Industrial Purpose. Hotels and restaurants. Fixed with safety lock, when the machine outer covering is open, will automatically switch off. High in efficiency and low in energy consumption The whole body is made of aluminum, and the parts in touch with food are made of high quality stainless steel. Easy operated and easy to clean.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FruitsCuttingMachine
