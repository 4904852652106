import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import visioncamera from '../images/visioncamera.png';

function VisionCamera() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={visioncamera} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>VISION CAMERA 2</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>CONTROL QUALITY FOR CUT-UP LINE – The vision system inspects and grades poultries analyzing visible quality in cut up line, while the birds hook to the shackles, pass in front of a digital camera. The bird is divided in three parts, separately checked by the system: legs, wings and breast. For each of these parts system checks the presence of blood spot (red) and if the spot area is over a settable threshold, the part is graded as B. Moreover system checks also wings integrity. In case of partial or total broken bones the wings are graded B as well. A signal coming to the camera from a photocell triggers image acquisition and image processing starts immediately.</h5>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default VisionCamera;
