import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import blanchingmachine from '../images/blanchingmachine.PNG';
import blanchingmachine1 from '../images/blanchingmachine1.PNG';

function BlanchingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>BLANCHING MACHINE</h1>
            <img src={blanchingmachine} />
            <br/>
            <h5>This blanching machine is mainly used in vegetables, fruits, and other food materials, such as, potatoes, carrots, spinach, cabbage, onion, chicoree, red bell pepper, asparagus, coconut, sweet corn, apples, mushroom, chicken feet, etc. This blanching machine is with characteristics of fast blancher, inhibition of enzyme and color-protecting, timely dehydration, so as to keep the original nature colors of vegetables and fruits. This machine will blanching the vegetable materials, at the same time, the materials will be cleaned by turning water. The blanching temperature are controlled by solenoid valve, thermo element, and temperature-controlled meter; the blanching time will be controlled by frequency converter, the conveying belt speed can be adjusted. The temperature controller will make the water temperature in water channel to keep the required temperature, the maximum temperature can be 100℃</h5>
            <br/>
            <img src={blanchingmachine1} alt="product image"/>
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Advantages of Equipment:</h2>
            <h5>A. The cooking machine is mainly used for food processing. The water inside the blanching machine is hot water, and the heating method is hot weather heating, so long-term use is more cost-effective. The blanching machine is completely mechanized during use and requires no labor. The material is fed into the blanching machine through the mesh belt/conveyor belt or manually. The equipment inside the blanching machine is controlled by frequency conversion, and the blanching time can be set according to different product processes. <br/><br/> B. The machine adopts SUS 304 stainless steel plate mesh belt transmission. The mesh belt has the characteristics of high strength, small flexibility, no deformation, easy maintenance, etc. The whole machine runs in balance and low noise, which greatly improves the working efficiency. When the material is discharged, the belt is lifted to directly convey the material out of the equipment. <br/><br/>C. The heating source of the machine is natural gas heating, with automatic control steam inlet device and surface insulation. Minimize energy waste. <br/><br/>D. The transmission system of the machine adopts the speed reduction motor to make the conveyor belt transfer speed adjustable, and the blanching time can be adjusted freely. <br/><br/>E. Now the blanching equipment, the machine is equipped with automatic transmission system, anti-floating system, overflow system, heating automatic control system, automatic temperature control system. The speed of the machine is adjustable, the conveying speed is stable, the purpose of automatic continuous production is realized, and the water is controlled at any time. In addition, the conveying net is made of high-strength high-quality stainless steel, the structure is firm, the material is heavy, and the production efficiency is greatly improved. Beautiful appearance, reasonable structure and convenient operation.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Characters of the Equipment: </h2>
            <h5>A. The whole machine is made of 304 stainless steel, which fully meets the national food production safety requirements. The blanching line integrates blanching and cooling in one, easy operation, safe and reliable. <br/><br/>
            B. A variety of heating methods are available.<br/><br/>
            C. The temperature can be adjusted, the transmission uses variable frequency speed control motor the speed is adjustable. Can be adjusted for different products. <br/><br/>
            D. The whole machine is designed as an independent inner liner structure, and the outer shape frame is welded, which is durable and durable, and increases the service life of the equipment. The advantages of the liner structure are firstly easy to clean and ensure the cleanliness of the equipment. Secondly, the mesh belt is not restricted by the frame, even if the serious collision is damaged, and the transmission of the mesh belt is not affected. <br/><br/>
            E. The thickness of the insulation layer of the blanching section is 50mm, which effectively reduces heat loss and saves energy costs.<br/><br/>
            F. Automatic temperature control, circulating water system to ensure material quality. <br/><br/>
            G. Soak all the materials in the cooking water to ensure the cooking effect. <br/><br/>
            H. The equipment is covered with an exhaust gas outlet, and excess exhaust gas is discharged from this outlet. </h5>
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Instructions for use: </h2>
            <h5>
            1) After the equipment is ready, connect 50Hz three-phase four-wire AC to the main switch of the blanching electrical control box, and the grounding wire must be connected. <br/><br/>
            2) Adjust the speed of the mesh belt according to the blanching time required by the food. <br/><br/>3) Set the temperature of the blanched food</h5>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default BlanchingMachine;
