import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishmeaballprocessingmachine from '../images/fishmeaballprocessingmachine.png';
import fishmeaballprocessingmachine2 from '../images/fishmeaballprocessingmachine2.PNG';
import fishmeaballprocessingmachine3 from '../images/fishmeaballprocessingmachine3.PNG';

function FishMeatBallProcessMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH MEAT BALL PROCESS MACHINE</h1>
            <img src={fishmeaballprocessingmachine} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Meatball Making Machine Introduction</h2>
            <h5>This meatball making machine is mainly used to produce fish balls, shrimp meatballs, pork balls, beef balls, mutton balls, vege balls and seafood balls. We are supply two knid of meatball machines. The mechanical speed regulation one and the frequentcy speed control one.</h5>
            <h5>Tips : We can also supply meatballs production line. The main equipment of the line is meat grinding machine. Meat slicing machine , stuffing mixer, bowl cutter, meatball machine, molding tank and frying machin etc.</h5>
            <br/>
            <img src={fishmeaballprocessingmachine2} alt=""/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Meatball Forming Machine Characters</h2>
            <h5>(1.) Available for dirfferent size meatballs by addjusting molds.</h5>
            <h5>(2.) Suitable for various knids of meatballs like seafood balls, beef balls, vege balls etc.</h5>
            <h5>(3.) Can make meat balls with different flavours.</h5>
            <h5>(4.) Can be used to make stuffing and dumping and steamed buns.</h5>
            <h5>(5.) Made of stainless steel, meeting the sanitation standard, durable, and easto learn.</h5>
            <h5>(6.) The meatball produced by this machine has good quality in size, taste and texture.</h5>
            <br/>
            <img src={fishmeaballprocessingmachine3} alt=""/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Meatball Making Machine Use Principle</h2>
            <h5>Ensure the power supply and the ground wire is well-connected and clean the machine before starting.</h5>
            <h5>The meat should be processed by meat grinder before using meatball machine.</h5>
            <h5>To ensure the good size of meatball, the meat in the charging barrel should be in right amount, not too much, not tootime.</h5>
            <h5>After work, the machine should be cleaned and dried.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Techinal Data of Commercial Meatball Maker</h2>
            <h5>Model - RWJ-280</h5>
            <h5>Power (kw) - 1.1kW, 220v/50hz</h5>
            <h5>Voltage (V) - 380</h5>
            <h5>Capacity - 280 -300pc/h</h5>
            <h5>Meat ball size - 5-20g/piece</h5>
            <h5>Weight (kg) - 95kg</h5>
            <h5>Dimension (mm) - 800*405*1240mm</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FishMeatBallProcessMachine
