import React from 'react';
import '../styles/Pic/pic.css';
import group from '../images/Group 2.png';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function Pic() {
  return (
    <div className='pic'>
      <div className='pic-inner'>
        <img src={group} alt="" />
        <div className='box'>
            <h1>Search products</h1>
            <div className='arrowbox'>
                <Link to='/products' className='learn-more-btn'>
                    <ArrowRightAltIcon fontSize='large' style={{
                        color: 'white'
                    }} className='hero-arrow'/>
                </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Pic;
