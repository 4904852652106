import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishheadcutter from '../images/fishheadcutter.png';

function FishHeadCutter() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH HEAD CUTTER</h1>
            <img src={fishheadcutter} />
            <h5>Machine for decapitation of fish.</h5>
            <h5>o  The machine is designed to cut off the head in a different kind of fish.</h5>
            <h5>o  The machine is mobile and easily integrated into the processing lines of fish processing.</h5>
            <h5>o  The geometric shape of the conveyor allows you to fix the fish well during the cutting of the head, which ensures a good and smooth cut.</h5>
            <h5>o  The machine is made of stainless steel. All materials are high-strength and not subject to corrosion.</h5>
            <h5>o  The position of the fish when cutting off its head is regulated depending on its size and adjusted to each breed.</h5>
            <h5>Power characteristics - 3 phases, 380 V, 0.75 kW</h5>
            <h5>Dimensions, mm: length x width x height; Weight, kg - 1200 x 800 x 1100, 280 kg</h5>
            <h5>Productivity - fish / min.50 ~60</h5>
            <h5>Processed fish species - Mackerel, saury, saithe, spotted mackerel, etc.</h5>
            <a href="https://equipment7.com/fish-processing-equipment/filleting-cutting/fish-head-cutter-deheading-gutting-machine/">Visit Website</a>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FishHeadCutter
