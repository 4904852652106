import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css';
import addingoilsausesmachine from '../images/addingoilsausesmachine.PNG';
import canfishprocessingline from '../images/canfishprocessingline.png';
import cansealermachine from '../images/cansealermachine.png';
import canwashermachine from '../images/canwashermachine.png';
import conveyor from '../images/conveyor.png';
import fishblackmeatandskinpeelermachine from '../images/fishblackmeatandskinpeelermachine.png';
import fishfilletingmachine from '../images/fishfilletingmachine.png';
import fishheadcutter from '../images/fishheadcutter.png';
import fishmeaballprocessingmachine from '../images/fishmeaballprocessingmachine.png';
import fishskinnermachine from '../images/fishskinnermachine.png';
import fishsmokingchamber from '../images/fishsmokingchamber.png';
import frozenblockcutter3 from '../images/frozenblockcutter3.PNG';
import frozenblockcutter from '../images/frozenblockcutter.png';
import meatfishseparator from '../images/meatfishseparator.png';
import metaldetector from '../images/metaldetector.png';
import retort from '../images/retort.png';
import { Link } from 'react-router-dom';

function FishProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1>Fish Products</h1>
        </div>
        <div className='products'>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={addingoilsausesmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Equipements for adding sause, oils
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/addingoilsausesmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={canfishprocessingline}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Can fish processing outline
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/canfishprocessingline'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={cansealermachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Can Sealing Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/cansealermachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={canwashermachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Can Washer Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/canwashermachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={conveyor}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Conveyor Line
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/conveyor'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishblackmeatandskinpeelermachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Meat and Skin Peeler
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={1} />
                <CardActions>
                    <Link to='/fishblackmeatandskinpeelermachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishfilletingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Filleting Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/fishfilletingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishheadcutter}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Head Cutter
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/fishheadcutter'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishmeaballprocessingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Meat Ball Process machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/fishmeaballprocessingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishskinnermachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Skinner Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/fishskinnermachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={fishsmokingchamber}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Smoking Chamber
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/fishsmokingchamber'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={frozenblockcutter3}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Block Maker
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/frozenblockmaker'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={frozenblockcutter}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Forzen Block Cutter
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/frozenblockcutter'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={meatfishseparator}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Fish Bone Separator
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/meatfishseparator'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={metaldetector}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Metal Detector
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/metaldetector'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={retort}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Retort Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/retort'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
      </div>
    </div>
  );
}

export default FishProducts;
