import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import canwashermachine from '../images/canwashermachine.png';

function CanWasherMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>CAN WASHER MAHINE</h1>
            <img src={canwashermachine} />
            <h5>Designed for washing cans with hot pressured water. Its design enables recovery of the water-oil emulsion, from which the oil can be recivered by means of a certrifuge pump.</h5>
            <br/>
            <h5>An energy efficient machine:</h5>
            <h5>• Fitted with a closed coil and purger to recover condensates.</h5>
            <h5>• Automatic temperature control and modulated steam entrance values.</h5>
            <br/>
            <h5>Made of AISI-304 stainless steel.</h5>
            <h5>Supplied with EC certificate.</h5>
        </div>
     </div>
      <Footer/>
    </div>
  )
}

export default CanWasherMachine
