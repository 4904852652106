import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fruitpeelingmachine from '../images/fruitpeelingmachine.png';

function FruitsPeelingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>FRUIT PEELING MACHINE</h1>
            <img src={fruitpeelingmachine} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Description</h2>
            <h5>This fruit peeling machine is designed for removing the peel of various kinds of ball-shaped fruits with a diameter of 40-100mm and height of 40-120mm. It is a key equipment of industrial fruits-processing, central kitchens,or distribution centers or the chain supermarkets, etc.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Features</h2>
            <h5>1. This machine has functions of peeling and core removing and end cutting.</h5>
            <h5>2. It can process about 1200 apples per hour, can replace manpower of 17 peoples by manual. </h5>
            <h5>3. Peel, core removal rate can reach 100%, loss is very small. </h5>
            <h5>4. Thickness of peels can be adjustable, can automatically adapt the fruit height, the thickness.</h5>
            <h5>5. The machine can process pear, orange, melon, persimmon, kiwi fruit and other 22 fruits and vegetables. Can make the machine year round fruit and vegetable processing not idle. </h5>
            <h5>6. This machine is manufactured in accordance with European Union standards, with program control and remote control. </h5>
            <h5>7.When operating, click the touch screen, set the parameters, put the fruit into the fruit seat, the machine will automatically complete fruit holding - fruit feeding - fruit setting - peeling - core removing - discharge separately. </h5>
            <h5>8. It is the key equipment for industrial processing of dried, sliced, preserved, diced, strip, block, sauce, juice, powder, canned, quick-frozen diced and fresh cut apples fruits. </h5>
            <h5>9. Blades with three different sizes for coring and sectioning are available for varisized fruits and varisized fruit cutting. </h5>
            <h5>10. The machine spots of lubrication, friction and water drip are separated from the working chambers, preventing products to be spoiled. </h5>
            <h5>11. Made of stainless steel, ensuring a long service life, high efficiency ensures saving cost.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Working Principle</h2>
            <h5>Taking the orange peeling as an example: The peeling thickness, working parameters, and the process should be set up before operation. The operator places fruits on the fruit stand; the infrared positioning light leads the fruits to a right position; the circular conveying chain delivers fruits to the fixation fork; the automatic height recognition device impact and rotate the fruit; then the peeler device starts working according to the move of the fruit. Fruits enter the end cutting station after peeling to cut their end peels, with the assistance of the automatic height recognition device. Every working station has a discharging hole, so the pure sarcocarp, strip peels, and round peels can be discharged respectively.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default FruitsPeelingMachine;
