import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import openingmachine from '../images/openingmachine.png';

function OpeningMachine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={openingmachine} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>OPENING MACHINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic opening machine is designed to open the vent by cutting the skin up to the breast point of the birds. The length of the cut is easily adjustable according to the size of the birds. The machine is made of stainless steel and nylon.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">12U</div>
                            <div class="box len">16U</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">3.0000</div>
                            <div class="box len">6.0000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">900</div>
                            <div class="box len">1.385</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.160</div>
                            <div class="box len">1.280</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.330</div>
                            <div class="box len">2.250</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">1,5</div>
                            <div class="box len">2</div>
                            <div class="box len">I/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">250</div>
                            <div class="box len">350</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">2,3</div>
                            <div class="box len">3,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default OpeningMachine
