import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import belttypevaccumpackaging from '../images/belttypevaccumpackaging.png';

function BeltTypeVaccumePackaging() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>BELT TYPE VACCUME PACKAGING</h1>
            <img src={belttypevaccumpackaging} />
            <br/>
            <h5>The BT series is an inline system for those customers who require high output vacuum chamber packaging. These machines can be paired with our belted ST700 series of dip tanks to create the perfect high speed packaging solution for any customer.</h5>
            <h5>In order to meet output demands, we have built these machines for performance and reliability. Deep levels of vacuum are quickly pulled by means of high capacity, Busch USA vacuum pumps that come standard with the BT800-1100; optional upgrades are available for customers who need more power. Equipped with easy-to-use touchscreen controls for maximum adjustability, our machines can be set up to achieve desired results. The water-cooled seal bars dramatically reduce seal wire failure; this in turn will cut down on costly down time. Built for high speed and reliability, Promarks is ready to meet your vacuum packaging needs.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features</h2>
            <h5>•  Stainless Steel Construction for Dependability and Durability</h5>
            <h5>•  (2) Busch RA0305 (12HP) Vacuum Pumps</h5>
            <h5>•  Touchscreen Controls for Ease of Operation</h5>
            <h5>•  Lid Safety Ring Protects You During Operation</h5>
            <h5>•  Liquid Cooled Seal Bars Reduce Seal Wire Failure</h5>
            <h5>•  Pivoting Control Arm to Handle a Variety of Tasks</h5>
            <h5>•  Infeed and Exit Conveyor Speed Up Handling</h5>
            <h5>•  Casters and Leveling Legs for a Perfect Fit Anywhere</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options</h2>
            <h5>•  Perforation Knife System</h5>
            <h5>•  Perforation Knife System</h5>
            <h5>•  Booster Vacuum Pump</h5>
            <h5>•  Custom Conveyor System</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BeltTypeVaccumePackaging
