import React from 'react';
import HeroSection from '../components/HeroSection';
import HeroDownSection from '../components/HeroDownSection';
import FirmSection from '../components/FirmSection';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import Pic from '../components/Pic';
import Blogs from '../components/Blogs';
import Footer from '../components/Footer';
import FeaturedProducts from '../components/FeaturedProducts';

function Home() {
  return (
    <div>
      <HeroSection/>
      <HeroDownSection/>
      <FirmSection/>
      <Services/>
      <FeaturedProducts/>
      <Testimonials/>
      <Pic/>
      <Blogs/>
      <Footer/>
    </div>
  );
}

export default Home;
