import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import brineinjector from '../images/brineinjector.PNG';
import brineinjector1 from '../images/brineinjector1.PNG';
import brineinjector2 from '../images/brineinjector2.PNG';

function BrineInjector() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
            <div className="inner-canwashermachine">
                <h1>BRINE INJECTOR MACHINES</h1>
                <h5>* BRINE INJECTOR MSK-16</h5>
                <h5>* BRINE INJECTOR MSK-32/56</h5>
                <h5>* BRINE INJECTOR MSK-98/195</h5>
                <br/>
                <h5>FOR YOUR REFERANCE : <a href="https://foodmaxmachinery.com/en/product-category/processing-equipment/brine-injectors/">Click here</a></h5>
                <br/>
                <h5>Brine injecting is a process that adds both weight and in many cases flavor to meat, poultry, fish and other food products by the process of injecting a brine solution into the product. This can be done using a brine injection machine that can be as simple as a small portable brine pump connected to what looks like a large hypodermic needle to very large machines that have a head or multiple heads filled with injection needles. These type of brine injectors range from smaller units with 16 to 20 needle heads that are very adequate for single market’s meat processing operation to very large units with multiple heads and hundreds of needles. The basic process is the same no matter the size of the machine.</h5>
                <h5>•  The product is placed on an infeed conveyor and moved under the injection head</h5>
                <h5>•  The needles in the head enter the product as in moves through the machine.</h5>
                <h5>•  Brine is pumped from a container through the needles into the product</h5>
                <h5>•  The needles retract from the product as the head move upward.</h5>
                <h5>•  The conveyor or walking beam moves the product toward the exit of the machine.</h5>
                <h5>The basic steps shown above are accomplished by different methods depending on the model of brine injector you chose. As is the case with most food processing applications the choice of machine is usually based on the type of product and the throughput required. When considering brine injection a third factor is very important and that is percentage of pickup (how much more will a piece of product weigh when the injection process is complete vs. before the brine injection process.
                Promarksvac Corporation manufactures a complete line of brine injecting machines. Their MSK Series brine injectors range from the MSK-16 model with 16 needles standard in a spring loaded head to their MSK-195 with 195 needles in an air pressure controlled head. Some of the smaller to medium size models feature a walking beam system for product movement where the larger machines use a conveyor system for product movement. The smaller to medium sized models use a mechanical drive system to power the injection heads and product movement systems while the larger units make use of a robust hydraulic power system. All of their models feature # 304 stainless steel construction and a simple to use PLC / HMI control system (except model MSK-16 which uses analog controls).</h5>

                <br/>
                <br/>
                
                <h1>BRINE INJECTOR MSK-16</h1>
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
                <h5>•  Stainless Steel (# 304) Construction</h5>
                <h5>•  Centrifugal Brine Pump</h5>
                <h5>•  Spring Head Design for Needle Force</h5>
                <h5>•  Simple Analog Controls</h5>
                <h5>•  Easy to Remove & Clean Conveyor</h5>
                <h5>•  Easy to Replace Needles</h5>
                <h5>•  Stainless Steel (# 304) Brine Tank</h5>
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
                <h5>•  Tenderizer Knife Set</h5>
                <h5>•  Custom Voltage</h5>
                <br/>
                <h5>Promarksvac Corporation manufactures a complete line of Brine Injectors from our value priced MSK-16 to our impressive MSK-198. All of these units feature all stainless steel housings and high quality European brine pumps.</h5>
                <h5>The MSK-16 features simple to use analog controls and a composite conveyor belt for product movement. The needle force on this unit is supplied by a time tested spring system.
                •  The MSK-32 through the MSK-56 offers an advanced PLC control system as well as a sturdy all stainless steel walking beam system for product movement through the unit. These units also feature an air pressure controlled needle head system. This system allows for processing both boneless as well as bone in product with a simple adjustment in the air pressure settings instead of changing out head springs. These units also feature unique air powered needle cleaning fixture.</h5>
                <h5>The MSK-98 and the MSK-198 boast a robust hydraulic power system that handles both needle head movement as well as driving the product feed conveyor. These units share the same advanced PLC control system and needle cleaning fixture as the MSK-32 and MSK-56</h5>
                <h5>If your processing operation includes brine injection, then you owe it to yourself to take a look at the Promarksvac MSK Series. We think you will agree that they represent the best overall value to be found in the industry.</h5>
                <br/>
                <img src={brineinjector1} />

                <br/>
                <br/>

                <h1>BRINE INJECTOR MSK-32/56</h1>
                <img src={brineinjector} />
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
                <h5>•  Stainless Steel (# 304) Construction</h5>
                <h5>•  Centrifugal Brine Pump</h5>
                <h5>•  Stainless Steel (# 304) Brine Tank</h5>
                <h5>•  Brine Mixer (model MSK-56)</h5>
                <h5>•  PLC / HMI Control System</h5>
                <h5>•  Variable Speed Motor Drive up to 50 Strokes per Minute</h5>
                <h5>•  Air Head Design for Needle Force</h5>
                <h5>•  Walking Beam System for Product Movement</h5>
                <h5>•  Unique Needle Cleaning System</h5>
                <h5>•  Digital Brine Temperature Readout</h5>
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
                <h5>•  Custom Voltage</h5>
                
                <br/>
                <br/>

                <h5>Promarksvac Corporation manufactures a complete line of Brine Injectors from our value priced MSK-16 to our impressive MSK-198. All of these units feature all stainless steel housings and high quality European brine pumps.</h5>
                <h5>The MSK-16 features simple to use analog controls and a composite conveyor belt for product movement. The needle force on this unit is supplied by a time tested spring system.
                The MSK-32 through the MSK-56 offers an advanced PLC control system as well as a sturdy all stainless steel walking beam system for product movement through the unit. These units also feature an air pressure controlled needle head system. This system allows for processing both boneless as well as bone in product with a simple adjustment in the air pressure settings, instead of changing out head springs. These units also feature unique air powered needle cleaning fixture.</h5>
                <h5>The MSK-98 and the MSK-198 boast a robust hydraulic power system that handles both needle head movement as well as driving the product feed conveyor. These units share the same advanced PLC control system and needle cleaning fixture as the MSK-32 and MSK-56</h5>
                <h5>If your processing operation includes brine injection, then you owe it to yourself to take a look at the Promarksvac MSK Series. We think you will agree that they represent the best overall value to be found in the industry.</h5>

                <br/>
                <br/>

                <h1>BRINE INJECTOR MSK-98/195</h1>
                <img src={brineinjector2} />
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
                <h5>•  Stainless Steel (# 304) Construction</h5>
                <h5>•  Centrifugal Brine Pump</h5>
                <h5>•  Stainless Steel (# 304) Brine Tank</h5>
                <h5>•  Brine Mixer (model MSK-56)</h5>
                <h5>•  PLC / HMI Control System</h5>
                <h5>•  Variable Speed Motor Drive up to 50 Strokes Per Minute</h5>
                <h5>•  Air Head Design for Needle Force</h5>
                <h5>•  Hydraulic Powered Composite Conveyor for Product Movemen</h5>
                <h5>•  Unique Needle Cleaning System</h5>
                <h5>•  Digital Brine Temperature Readout</h5>
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
                <h5>•  Custom Voltage</h5>
                <h5>Promarksvac Corporation manufactures a complete line of Brine Injectors from our value priced MSK-16 to our impressive MSK-198. All of these units feature all stainless steel housings and high quality European brine pumps.</h5>
                <h5>The MSK-16 features simple to use analog controls and a composite conveyor belt for product movement. The needle force on this unit is supplied by a time tested spring system.
                The MSK-32 through the MSK-56 offer an advanced PLC control system as well as a sturdy all stainless steel walking beam system for product movement through the unit. These units also feature an air pressure controlled needle head system. This system allows for processing both boneless as well as bone in product with a simple adjustment in the air pressure settings instead of changing out head springs. These units also feature unique air powered needle cleaning fixture.</h5>
                <h5>The MSK-98 and the MSK-198 boast a robust hydraulic power system that handles both needle head movement as well as driving the product feed conveyor. These units share the same advanced PLC control system and needle cleaning fixture as the MSK-32 and MSK-56</h5>
                <h5>If your processing operation includes brine injection, then you owe it to yourself to take a look at the Promarksvac MSK Series. We think you will agree that they represent the best overall value to be found in the industry.</h5>
            </div>
      </div>
      <Footer/>
    </div>
  );
}

export default BrineInjector;
