import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import tabletopvaccumpackagingmachine from '../images/tabletopvaccumpackagingmachine.png';

function TableTopVaccumMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>TABLE TOP VACCUME PACKAGING</h1>
            <img src={tabletopvaccumpackagingmachine} />
            <h5>Promarksvac’s model TC-520F is one of our most popular table top vacuum chamber sealer models. The “F” in the model number lets you know that this machine features a single 21” seal bar in the front of the vacuum cavity area. Like all Promarksvac chamber vacuum sealers this machine is ETL tested, certified and labeled as meeting UL and CSA safety standards for packaging equipment as well as NSF sanitary standards. This rugged functional machine utilizes an all # 304 stainless steel housing, lid, with a viewing port, and vacuum cavity. It features a Busch (model RB0021) vacuum pump for reliable and powerful vacuum performance. The standard PD-01 basic digital control panel allows the operator to quickly set the parameters for vacuum time, gas flush time (with optional gas flush system), seal time and cooling time with the accuracy of a digital readout. If your application requires more advanced control then the optional PNC-01 microprocessor based control panel may be just what you need. The PNC-01 allows for the vacuum to be controlled via time or vacuum pressure and also includes a 14 recipe memory, soft air venting as well as multi-stage gas flush capability just to name a few of the control options it offers the operator. This model comes standard with filler plates that help place the package being sealed at the proper height as well as cut down the cycle time for small packages by forcing excess air out of the vacuum cavity. An optional slant tray can replace the filler boards if you are working with a product that will have liquid inside the package. Other options such as a gas flush system, bi-active seal system and 220V/1PH power allow the TC-420F to handle a wide variety of applications. The model TC-520F is also the smallest table top unit available with the optional “Precise Seal” fully valid table PLC/HMI based control system.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>Busch Vacuum Pump RB0021 (1.25HP)</h5>
            <h5>PD-01 Basic Digital Control Panel</h5>
            <h5>Stainless Steel Construction</h5>
            <h5>Stainless Steel Lid with Clear Viewport</h5>
            <h5>Bladder Activated Sealing</h5>
            <h5>Air Assist Sealing</h5>
            <h5>6mm Seal Wire or Electric Cut-Off Seal Bar</h5>
            <h5>UL, CSA & NSF Certified & Labeled</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>PNC-01 Microprocessor Control</h5>
            <h5>Soft Air</h5>
            <h5>Bi-Active Seal</h5>
            <h5>Gas Flush System</h5>
            <h5>Twin Beam Sealing</h5>
            <h5>220V Single Phase</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Applications:</h2>
            <h5>Sous Vide</h5>
            <h5>Pharmaceutical Lab applications</h5>
            <h5>Test kitchens</h5>
            <h5>Electronic dry pack applications</h5>
            <h5>Pet food packaging</h5>
            <h5>Markets & Delis</h5>
            <h5>Many more</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default TableTopVaccumMachine
