import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import multibirdsshacklewasher from '../images/multibirdsshacklewasher.png';

function MultiBirdsShackleWasher() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={multibirdsshacklewasher} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>MULTI BIRDS SHACKLE WASHER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This unit is installed on air chiller line to wash the multi-birds shackle. The frame is in heavy stainless steel plate with side door for easy inspection. The machine is devised in two sections, in the first section we have filter, recycling water pump, in the second section we have the final cleaning with sanitary dispenser.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table"> 
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">4.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.360</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span>/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">10</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">900</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">9,6</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default MultiBirdsShackleWasher
