import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import waterbathstunnerlite from '../images/waterbathstunnerlite.png';
import waterbathstummer2019ce from '../images/waterbathstummer2019ce.png';

function ElectricalStunner() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={waterbathstunnerlite} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>WATERBATH STUNNER LITE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This type of machine is designed for the poultry processing plants to provide a safe method of stunning for any kind of bird. The frame is made of stainless steel and the cabin in fibreglass. The unit is height adjustable on for any kind of bird.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">4.000</div>
                            <div class="box len">8.000</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Bath Lenght</div>
                            <div class="box len">850</div>
                            <div class="box len">1.700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water Connection</div>
                            <div class="box len">3/8"</div>
                            <div class="box len">3/8"</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">130</div>
                            <div class="box len">130</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">1,7</div>
                            <div class="box len">1,7</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={waterbathstummer2019ce} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>WATERBATH STUNNER 2019 CE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This equipment is used to stun each type of bird by electric shock and has recently been updated to comply with the C.E. which will come into force in 2019. The new load-bearing structure is entirely made of stainless steel and the tank, which can be excluded to have access to the birds in case of failure on the line, is made of polypropylene. The stunner also has the possibility of being adjusted in height to adapt to the various pieces by means of an hydraulic cylinder.</h5>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default ElectricalStunner
