import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import '../styles/Navbar/navbar.css';
import logo from '../images/Euromate_Logo.jpeg';

function Navbar() {

  const [isActive, setIsActive] = useState(false);

  const handleMenu = () => {
    setIsActive(true);
  }

  const handleClose = () => {
    setIsActive(false);
  }

  return (
    <div className='navbar'>
      <div className='navbar-inner'>
        <div className='logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='navlinks'>
          <CloseIcon className='close'/>
          <NavLink to='/' className='links'>Home</NavLink>
          <NavLink to='/products' className='links'>Products</NavLink>
          <NavLink to='/about' className='links'>About Us</NavLink>
          <NavLink to='/contacts' className='links'>Contact</NavLink>
          <NavLink to='/services' className='links'>Services</NavLink>
        </div>
        <div className='navlinks2' style={{
          left: isActive ? '0' : '-80vw',
          clipPath: isActive ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' : 'polygon(0 0, 0 0, 0 100%, 0% 100%)'
        }}>
          <CloseIcon className='close' onClick={handleClose} fontSize='large' style={{
            color: 'white'
          }}/>
          <NavLink onClick={handleClose} to='/' className='links'>Home</NavLink>
          <NavLink onClick={handleClose} to='/products' className='links'>Products</NavLink>
          <NavLink onClick={handleClose} to='/about' className='links'>About Us</NavLink>
          <NavLink onClick={handleClose} to='/contacts' className='links'>Contact</NavLink>
          <NavLink onClick={handleClose} to='/services' className='links'>Services</NavLink>
        </div>
        <div className='icons'>
          <SearchOutlinedIcon className='search' fontSize='medium' style={{
            color: 'white',
            cursor: 'pointer'  
          }}/>
          <AccountCircleOutlinedIcon className='user' fontSize='medium' style={{
            color: 'white',
            cursor: 'pointer'
          }}/>
          <MenuIcon onClick={handleMenu} className='menu' fontSize='large' style={{
            color: 'white'
          }}/>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
