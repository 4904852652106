import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import staticheadpuller from '../images/staticheadpuller.png';

function StaticHeadPuller() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={staticheadpuller} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>STATIC HEAD PULLER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine removes the chickens head. It can be installed on any kind of killing or eviscerating line. It does not require to be synchronised with the overhead conveyor. The structure guide-bars are made of stainless steel.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">4.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.150</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">720</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">30</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">0,2</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default StaticHeadPuller
