import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import conesdeboningforbreastcap from '../images/conesdeboningforbreastcap.png';

function ConesFeboningLineForBreastCap() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={conesdeboningforbreastcap} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CONES DEBONING LINE FOR BREAST CAP</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Manual chicken deboning line with cones for breast cap complete with control panel and safety device. It is made of stainless steel and heavy duty nylon. The line is 5.6 mt. long with cones at 300 mm pitch. The operator can work on both site of the line.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">1.000</div>
                            <div class="box len">B / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">5.720</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">750</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">0,37</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">310</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">2,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ConesFeboningLineForBreastCap
