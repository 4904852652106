import React from 'react'
import { Rating } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Meat/meat.css';
import belttypevaccumpackaging from '../images/belttypevaccumpackaging.png';
import constantheatsealer from '../images/constantheatsealer.png';
import continuousvaccumpackagingmachine from '../images/continuousvaccumpackagingmachine.png';
import doublechambervaccumpackagemachine from '../images/doublechambervaccumpackagemachine.png';
import nozzeltypepackagingmachine from '../images/nozzeltypepackagingmachine.png';
import shrinktank from '../images/shrinktank.png';
import tabletopvaccumpackagingmachine from '../images/tabletopvaccumpackagingmachine.png';
import thermoforming from '../images/thermoforming.png';
import traysealerandmappackagingmachine from '../images/traysealerandmappackagingmachine.png';
import tubesealer from '../images/tubesealer.png';
import vaccumsealer from '../images/vaccumsealer.png';
import medicalvaccumchamber from '../images/medicalvaccumchamber.png';
import singlechamber from '../images/singlechamber.png';
import bagsealer from '../images/bagsealer.PNG';
import { Link } from 'react-router-dom';

function PackagingProducts() {
  return (
    <div className='meat'>
      <div className='meat-inner'>
        <div className='header'>
            <h1 style={{textAlign: 'center'}}>Packaging Solutions</h1>
        </div>
        <div className='products'>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={belttypevaccumpackaging}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Belt Type Vaccume Packaging
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/belttypevaccumpackaging'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={constantheatsealer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Constant Heat Sealer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/constantheatsealer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={continuousvaccumpackagingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Continuous Vaccume Packging Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/continuousvaccumpackagingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={doublechambervaccumpackagemachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Double Chamber Vaccum Packaging Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/doublechambervaccumpackagemachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={nozzeltypepackagingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Nozzle Type Vaccum Packaging Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/nozzeltypepackagingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={shrinktank}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Shrink & Dip Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/shrinktank'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={tabletopvaccumpackagingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Table Top Vaccum Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/tabletopvaccumpackagingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={thermoforming}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Theermorforming Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/thermoforming'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={traysealerandmappackagingmachine}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Tray Sealer & Map Machine
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={1} />
                <CardActions>
                    <Link to='/traysealerandmappackagingmachine'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={tubesealer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Tube Sealer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={4} />
                <CardActions>
                    <Link to='/tubesealer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={vaccumsealer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Vaccume Sealer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/vaccumsealer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={medicalvaccumchamber}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Medical Vaccum Chmaber
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={3} />
                <CardActions>
                    <Link to='/medicalvaccumchamber'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={singlechamber}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Single Vaccume Chamber
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={2} />
                <CardActions>
                    <Link to='/singlechamber'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>

            {/* /////////////////////////////////// */}
            <Card className='card' sx={{ maxWidth: 250 }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
                backgroundColor: '#F0ECE3'
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={bagsealer}
                    style={{width: '150px'}}
                />
                <CardContent>
                    <Typography style={{textAlign: 'center'}} gutterBottom variant="h5" component="div">
                    Thermal Impluse Sealer
                    </Typography>
                </CardContent>
                <Rating name="size-medium" defaultValue={5} />
                <CardActions>
                    <Link to='/bagsealer'>
                        <Button size="small">View Product</Button>
                    </Link>
                </CardActions>
            </Card>
            
        </div>
      </div>
    </div>
  )
}

export default PackagingProducts
