import { Rating } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FeaturedProducts/featuredproducts.css';

function FeaturedProducts() {
  return (
    <div className='featuredproducts'>
      <div className='featuredproducts-inner'>
        <div className='featured-header'>
          <h5>See all products</h5>
          <h1>Featured Products</h1>
        </div>
        <div className='products'>
          <div className='product'>
            <div className='imgbg1'></div>
            <div className='description'>
              <h5 className='header'>Meat Processing Machines</h5>
              <Rating name="half-rating" defaultValue={3.5} precision={0.5} />
            </div>
          </div>
          <div className='product'>
            <div className='imgbg2'></div>
            <div className='description'>
              <h5 className='header'>Packaging Solutions</h5>
              <Rating name="half-rating" defaultValue={4.5} precision={0.5} />
            </div>
          </div>
          <div className='product'>
            <div className='imgbg3'></div>
            <div className='description'>
              <h5 className='header'>Other Machinaries</h5>
              <Rating name="half-rating" defaultValue={4} precision={0.5} />
            </div>
          </div>
        </div>
        <div className='btn'>
          <Link to='/products' className='red-btn'>
            Shop Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProducts;
