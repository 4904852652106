import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import vegecuttingmachine from '../images/vegecuttingmachine.png';

function VegetableDehydratorMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>VEGETABLE DEHYDRATOR MACHINE</h1>
            <img src={vegecuttingmachine} />
            <br/>
            <h5>The vegetable drying machine  is a set of specialized equipment that is used to dehydrate fresh or seasonal vegetable by microwaves. This vegetable drying machine can be used to process many kind of vegetables like carrots, cabbage etc…</h5>
            <h5>This vegetable dehydrator is made of SS with the standard of food security.</h5>
            <h5>Dealing with the fruit by microwave can achieve drying and sterilizing simultaneously, which improve the quality of the products and make it safe to eat and  eco-friendly
            This microwave vegetable dehydrator is extremely efficient and energy- saving.</h5>
            <h5>The PlC TOUCH SCREAN controlling system and the human- computer interface improves its working conditions.
            Large capacity high efficiency and reliable performance.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default VegetableDehydratorMachine
