import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import frozenblockcutter3 from '../images/frozenblockcutter3.PNG';
import frozenblockcutter2 from '../images/frozenblockcutter2.PNG';
import frozenblockcutter1 from '../images/frozenblockcutter1.PNG';

function FishBlockMaker() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FROZEN BLOCK MAKER</h1>
            <img src={frozenblockcutter3} />
            <br/>
            <h5>Designed to form fish blocks with subsequent bagging in a semi-automatic way, with high production.Versatile, as it can be adapted to different types of blocks.</h5>
            <h5>* Considerable reduction in labour.</h5>
            <h5>* Increased productivity.</h5>
            <h5>* Precise weighing.</h5>
            <h5>* Better qua</h5>
            <br/>
            <h5>Better quality final product as there are practically no crumbs.</h5>
            <br/>
            <img src={frozenblockcutter1} alt="product image"/>
            <br/>
            <h5>Creation of homogeneous blocks, which favours freezing, better stowage, greater load capacity per container and a  more homogeneous defrosting process.Made of AISI-304 stainless steel.Supplied with EC certificate.</h5>
            <br/>
            <img src={frozenblockcutter2} alt="product image"/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FishBlockMaker
