import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import dehydratemachine1 from '../images/dehydratemachine1.png';
import dehydratemachine from '../images/dehydratemachine.PNG';

function DehydtratorMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>DEHYDRATOR MACHINE</h1>
            <img src={dehydratemachine1} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Product Description</h2>
            <h5>Mesh belt dryer is a kind of continuous production drying equipment, the main heating way has electric heating, steam heating, hot air heating. Its main working principle is to spread the material evenly in the network, the transmission device drive the belt move in the dryer cylinder, the hot air from the bottom of dryer machine enter into the dryer, rise step by step, the water vapor will be discharge through the dehumidify hole, so as to achieve the purpose of drying.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Application</h2>
            <h5>Our Cassava Drying Machine is suitable for drying every surface of raw material,such as dehydrated vegetable,Petal,grain,herbal industry,spice and so on.After drying,the original color, shape, and nutrient content can be retained completely by the dried material.</h5>
            <br/>
            <img src={dehydratemachine} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Feature</h2>
            <h5>1.Highly automated, large capacity and energy saving</h5>
            <h5>User frinedly, 1-2works needed</h5>
            <h5>Temparature adjusted to best preserve the nutrition</h5>
            <h5>Specialized devvice to spread materials evenly so as to shorten the drying cycle and reduce processing cost</h5>
            <h5>Mutible choices of heating source; such as coal, biomass fuel, natural gas, hot blast stove, steam heat exchanger, air energy heat pump, etc.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default DehydtratorMachine
