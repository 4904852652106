import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import cassavapeelingmachine from '../images/cassavapeelingmachine.png';

function CassavaPeelingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>CASSAVA PEELING MACHINE</h1>
            <img src={cassavapeelingmachine} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>introduction</h2>
            <h5>Cassava peeling machine, also called cassava peeler, is used for peeling the two layers of cassava.It is widely used in garri processing line and cassava flour processing line. In cassava peeling process, the cuticle part of cassava is removed because it will be helpful to improve the taste and quality of the cassava processing products.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Working process</h2>
            <h5>The cassava peeling machine adopts the latest design of emery roller peeling, through the friction between the material and the emery roller to achieve the purpose of clean and thorough peeling, peeling rate can reach more than 95%.Emery roller peeling can peel cassava epidermis and the second layer of skin, so the surface of the material after peeling is smoother and the material loss is low. While the cassava is peeled, the conveyor screw drives the cassava to roll forward and transfer the peeled cassava to the next section. And the peels of cassava and other debris fall into collection tank.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Structure </h2>
            <h5>Cassava peeling machine mainly composed of frame, upper cover, motor, reducer, brush roll, spraying system, transmission system, screw conveyor. And the parts which touch the material are all made of stainless steel material.
            Frame and upper cover are basic structure of cassava peeling machine, the upper cover can be opened, but when working, it is normally closed to ensure cassava not be polluted.
            Motor is used to provide power for cassava peeling mahcine. Reducer can control the running speed of cassava.</h5>
            <br/>
            <h5>1) Emery roller: it is used to rub cassava to peel its gray peels and the 2mm thick cuticle part of cassava.
            Different with cassava peeler equipped with plastic brush, stainless steel ring, nailing roller, it has the advantages of long service life, easy maintenance,stable running.</h5>
            <h5>2) Transmission system and screw conveyor: Transmission system providing power and screw conveyor to transport peeled cassava forward. By adjusting the transmission speed, the cassava peeling effect can be adjusted. Doing Group can dispatch professional engineer for equipment debugging, to ensure better peeling effect.</h5>
            <h5>3) Spraying system: to wash way cassava peels and meanwhile wash peeled cassava to ensure it is clean.</h5>
            <h5>4) Discharging part: to discharge cassava peels and other debris</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Features</h2>
            <h5>a. High peeling rate: the cassava peeling machine developed by our company uses a  peeling machine to peel off the hard cuticle of cassava layer by layer without damaging the inside.Peeling rate can reach more than 95% .</h5>
            <h5>b. Saving water: the spray system design using less water than direct wash.</h5>
            <h5>c. Compact structure: though cassava peeling machine has a capacity of 1/2/3/5 t/h or larger, it only covers a small area.</h5>
            <h5>d. Easy to operate and maintain: only one or two workers are needed for operate cassava peeling machine. It saves a lot of labor and time than manual peeling. Our emery roller is designed with sandpaper wrapped around the round roller. It only needs to change the sandpaper for long-term operation, instead of replacing the entire roller, reducing operating costs.
            Besides, cassava peeling machine has the features of high efficient, stable and reliable operation as well as good motor configuration.</h5>
            <h5>If you also have interests in our cassava peeling machine or other cassava garri processing machine, welcome your consultation.Henan Jinrui Company can not only supply you the best machine at factory price according to your requirements, but also provide you the plant design, technical installation instruction, worker training, factory visiting, etc.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CassavaPeelingMachine
