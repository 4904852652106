import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import hydraulicelevator from '../images/hydraulicelevator.png';

function HydralicElevator() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={hydraulicelevator} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>HYDRAULIC ELEVATOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine is used to have a easly hang of the poultry to the defeathering line. The machine works with Cattaruzzi containers or traditional plastic crates positioned on the platform with a forklift, on a hydrulic elevator can work two people; The function of the machine is to rise the crates, stacked on pallets, at the right position for the operator. With a single elevator two people can hang 2.000 B/h (depending on the labour ability). The elevator is made in hot dip galvanised steel with platform moved up and down by an hydraulic device.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity (load)</div>
                            <div class="box len">900</div>
                            <div class="box len">B / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">1.300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">3.300</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,75</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">320</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">2,5</div>
                            <div class="box len">M <sup>3</sup> </div>
                        </div>
                    </div>
                </div>
                {/* <div class="actions">
                    <div class="download">
                        <img src="../images/pdf.png" alt="download image"/>
                        <a href="" download>Download Pdf</a>
                    </div>
                    <div class="watch">
                        <img src="../images/play-button.png" alt="watch image"/>
                        <a href="https://www.youtube.com/watch?v=0BWQ-6sYyRs">Watch Vedio</a>
                    </div>
                </div> */}
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default HydralicElevator
