import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import sausagecutter from '../images/sausagecutter.png';
import sausagecutter1 from '../images/sausagecutter1.PNG';

function SausageCutter() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>SAUSAGE CUTTER</h1>
            <img src={sausagecutter} />
            <br/>
            <h5>This machine is fully automatic which is suitable for processing all kinds of small and medium-sized animal bones, frozen meat, fish bones, frozen fish, ice cubes, etc. It is used for cutting small pieces of frozen meat and spare ribs and is widely used in large-scale centralized food processing plants, slaughterhouses, meat processing plants and other places.</h5>
            <br/>
            <img src={sausagecutter1} />
            <br/>
            <h5>1.The whole machine is made of high-quality stainless steel, which is sturdy and durable. </h5>
            <h5>2. It can work for a long time, and the cutting surface is smooth. </h5>
            <h5>3. Waterproof design, can be cleaned quickly. </h5>
            <h5>4. The top gripper adopts a double-layer material clamping design, and the bottom end adopts a fixed pin header, which is helpful for stable material transmission and precise portion cutting. </h5>
            <h5>5. Saw band gas spring tensioning device, easy to adjust and install. </h5>
            <h5>6. The design of the double-sided driving wheel prevents the saw band from falling off. </h5>
            <h5>7. Good cutting effect, less waste and low cost of use.</h5>
            <h5>8. Dividing the ingredients equally, dividing the tailings into each slice, reducing waste. </h5>
            <h5>9. The touch screen intelligent operation panel can automatically set the cutting speed.</h5>
            <h5>10. The thickness adjustment adopts a servo motor to set the thickness with high precision. </h5>
            <h5>11. The machine design complies with CE standards.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default SausageCutter
