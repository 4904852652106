import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import thermoforming from '../images/thermoforming.png';

function TheermorformingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>THERMOFORMING PACKAGING MACHINE</h1>
            <img src={thermoforming} />
            <h5>Promarksvac’c model RS-455 thermoforming packaging machine offers an indexing range from 320mm to 540mm and web widths up to 520mm. It can process both flexible and semi-rigid fill with thickness up to 14mil and can provide forming depths up to 120mm. Most of our standard features are options on competitive machines.</h5>
            <h5>The RS-455 can accommodate a wide range of products from liquids and powders to marinated and whole muscle product as well as non-food products. With a wide range of available option the RS-455 can be configured for fully validate able operation for packaging medical and pharmaceutical products.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Stainless steel frame</h5>
            <h5>•  Stainless steel loading area</h5>
            <h5>•  Hinged dies for fast die change</h5>
            <h5>•  All stainless steel chain with Delrin chain guides</h5>
            <h5>•  PLC / HMI control system with large 10″ screen</h5>
            <h5>•  Quick compressed air foorming</h5>
            <h5>•  Servo drive film transport chain</h5>
            <h5>•  Mechanical die lifting stations with air rams</h5>
            <h5>•  Busch RA063 vacuum pump</h5>
            <h5>•  Digital vacuum monitoring for fast troubleshooting and calibration for medical packaging</h5>
            <h5>•  Vacuum calibration ports for audits and medical packaging</h5>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TheermorformingMachine;
