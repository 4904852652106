import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import bagsealer from '../images/bagsealer.PNG';
import circularsealer from '../images/circularsealer.PNG';
import tabletopsealer from '../images/tabletopsealer.PNG';
import ergonichandsealer from '../images/ergonichandsealer.PNG';
import proofsealer from '../images/proofsealer.PNG';
import impulsesealer from '../images/impulsesealer.PNG';
import verticalsealer from '../images/verticalsealer.PNG';
import piuchsealer from '../images/piuchsealer.PNG';
import clamphandsealer from '../images/clamphandsealer.PNG';
import backsealer from '../images/backsealer.PNG';
import scissorhandsealer from '../images/scissorhandsealer.PNG';

function ThermalImpluseSealer() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">

            <h1>BAG SEALER</h1>
            <img src={bagsealer} />
            <br/>
            <h5>Promarks’ Bag Sealers are perfect for budget and space conscious, bag sealing applications and are ruggedly built to provide years of trouble free service. Each pneumatically powered bag sealer unit has sufficient jaw clearance to introduce your package from back to front or side-to-side. Standard controls include a glycerin filled gauge, pressure regulator, separate heat and cool timers and a tap to start foot pedal. This ensures that each bag seal will be airtight and water tight. All Aline bag sealers are powder coated and therefore clean room compatible. A bottom flange allows for this bag sealing unit to be mounted horizontally or vertically. Each unit comes complete with a two-year warranty, detailed operating instructions and a spare parts kit.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Choice of 15, 20 or 25 inch seal length<br/>
            •  All width band elements or wire seal<br/>
            •  Dual heat<br/>
            •  Scrap Blow Off<br/>
            •  Flying Knife<br/>
            •  Low pressure regulator<br/>
            •  Water Cooled bars<br/>
            •  Automatic cycling of seal jaw<br/>
            •  Pneumatic height adjustable stand<br/>
            •  110 or 220 Volts</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  15,20″ and 25″ widths</h5>

            <br/>
            <br/>
            <br/>

            <h1>CIRCULAR SEALER</h1>
            <img src={circularsealer} />
            <br/>
            <h5>Promarks’ is specializes in manufacturing Circular Valve Sealers for industrial and medical applications.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  range in size from ½” to 12″ valves</h5>

            <br/>
            <br/>
            <br/>

            <h1>CONTOUR & HEAVY DUTY TABLETOP SEALER</h1>
            <img src={tabletopsealer} />
            <br/>
            <h5>Promarks’ specializes in designing complex contour sealers. We can provide sealers for multiple, straight, or curved seal patterns and seal circular valves or elliptically shaped fittings. Each sealer is temperature controlled for guaranteed repeat ability and consistent seals. Designs can range from tabletop machines to very large floor models or shuttle equipment. Each machine can be fully customized to meet your application.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Standard or validate-able impulse sealer <br/>
            •  110 or 220 Volts <br/>
            •  Custom Options Available</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  4″, 12″, 20″, 97″ widths</h5>

            <br/>
            <br/>
            <br/>

            <h1>ERGONIC HAND SEALER</h1>
            <img src={ergonichandsealer} />
            <br/>
            <h5>The Promarks Ergonomic Hand Held Impulse Sealer with standard feature including stainless steel housing and seal wand frame. Each sealer is pneumatically operated and only requires the operator to push a button in order to trigger the seal cycle. The sealer is available in a T-clamp style in widths of 8″, 12″, and 15″ and as a scissor sealer in lengths of 6″ and 10″.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Seal Type: Single Element <br/>
            •  Seal Head: Fixed Connection<br/>
            •  Control System: Variable Range Analog Timers<br/>
            •  Construction</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Seal Width: Up to 1/2”/12mm<br/>
            •  Seal Type: Bi-Active<br/>
            •  Voltage: 220V/1Ph/60Hz</h5>

            <br/>
            <br/>
            <br/>

            <h1>EXPLOSION PROOF SEALER</h1>
            <img src={proofsealer} />
            <br/>
            <h5>Promarks’ sealing machines can be adapted for use in explosion proof environments. Equipment can be modified to meet your class division and classification of an explosive environment. This would ensure maximum safety for facilities where explosive gasses or powders may be present. Each Unit is pressurized and comes standard with safety vents, auto shutoff, and optional audible alarm. This guarantees maximum protection for your employees and facility.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Standard or validate-able impulse sealer <br/>
            •  110 or 220 Volts<br/>
            •  Custom Options Available</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  4″, 12″, 20″, 97″ widths</h5>

            <br/>
            <br/>
            <br/>

            <h1>HD SERIES HORIZONTAL IMPULSE SEALER</h1>
            <img src={impulsesealer} />
            <br/>
            <h5>Promarks’ Heavy Duty Thermal Impulse Bag Sealers are industrial quality machines constructed from welded steel. All bag sealers are pneumatically powered and calculated to provide an airtight/watertight seal. Each bag sealer unit features a low-pressure safety close and redundant emergency stop features. The sealers can be constructed as tabletop units or in seal lengths of up to 240 inches. Each bag sealer is ideal for sealing ranges of material including polyethylene, laminated films, nylon and even Teflon. Sealers are available in either standard thermal impulse or with repeatable and verifiable controls for medical or specialty applications</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Control system: PLC/HMI <br/>
            •  Single heating element<br/>
            •  Seal width: 1/4″<br/>
            •  Heavy duty all steel welded frame<br/>
            •  Powder coated finish (white)<br/>
            •  Emergency stop buttons<br/>
            •  Open back design<br/>
            •  Castors<br/>
            •  Low pressure safety jaw close system<br/>
            •  Voltage: 110V/1PH or 220V/1PH<br/>
            •  Foot switch activation</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Seal lengths to 300″<br/>
            •  Seal widths to 2″<br/>
            •  Temperature controlled seal systems<br/>
            •  Fully validate able  Precise Seal System<br/>
            •  Vacuum & gas flush systems<br/>
            •  Wrinkle reduction systems<br/>
            •  Water cooled seal bars<br/>
            •  Many other custom options available</h5>

            <br/>
            <br/>
            <br/>

            <h1>HEAVY DUTY VERTICAL SEALER</h1>
            <img src={verticalsealer} />
            <br/>
            <h5>Heavy Duty Thermal Impulse Bag Sealers are industrial quality machines constructed from welded steel. All bag sealers are pneumatically powered and calculated to provide an airtight/watertight seal. Each bag sealer unit features a low-pressure safety close and redundant emergency stop features. The sealers a be constructed as tabletop units or in seal lengths of up to 240 inches. Each bag sealer is ideal for sealing ranges of material including polyethylene, laminated films, nylon and even Teflon. Sealers are available in either standard thermal impulse or with repeatable and verifiable controls for medical or specialty applications.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  1/4″ wide seal elements <br/>
            •  Single sided seal system<br/>
            •  PLC / HMI control system<br/>
            •  All welded steel frame<br/>
            •  White powder coated finish<br/>
            •  Low presure close safety system<br/>
            •  Foot switch activation<br/>
            •  Seal Lengths from 30″ to 96″<br/>
            •  Voltage: 110V or 220V/1PH (depending on seal length)</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Fully validatable “PreciseSeal” System<br/>
            •  Gas flush system<br/>
            •  Vacuum system<br/>
            •  Flying knife system<br/>
            •  Water cooled seal bars<br/>
            •  Bag spreader system<br/>
            •  Water chiller system<br/>
            •  Many more custom options</h5>

            <br/>
            <br/>
            <br/>
            
            <h1>MEDICAL TABLETOP POUCH SEALER</h1>
            <img src={piuchsealer} />
            <br/>
            <h5>Promarks’ Medical Pouch Sealer unit is pneumatically operated and is available in 15, 20 or 25-inch seal length. A digital temperature controller allows you to program the temperature setting required to provide you with an airtight/watertight seal and the release temperature for your cool cycle. A variable timer ensures precise control over the amount of time heat is applied to your package. With an outstanding level of process controls, you can have confidence in sealing a wide range of materials including most thermoplastics and laminated foils. Each pouch sealer is validated and FDA/NIST traceable. Construction is very heavy duty providing the user years of trouble free operation. Standard safety features guarantee the utmost protection for your operator. The Stainless Steel finish ensures clean room compatibility and options such as the selection of band elements and water-cooling bar are available. All medical pouch sealing units are complete with a two-year warranty, detailed operating instructions and spare parts kit.</h5>

            <br/>
            <br/>
            <br/>

            <h1>PORTABLE T-CLAMP HAND SEALER</h1>
            <img src={clamphandsealer} />
            <br/>
            <h5>The new Promarks Heat Seal T-Clamp Series features all stainless steel housings, both heat & timers controls, and comes in 8”, 12”, 15” & 18” sizes. These rugged machines have available options such as bi-active seal and seal widths up to 1/2”. Whether you seal a large bag occasionally or form heat seal able material around oddly shaped objects for crating on a daily basis, our T-Clamp Series has a machine to meet your specific needs. Ergonomic Hand Held Impulse Sealer is also available
            Each sealer is pneumatically operated and only requires the operator to push a button in order to trigger the seal cycle Give us a call today and let us help you pick out a machine to meet your requirements.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Seal Type: Single Element<br/>
            •  Seal Head: Fixed Connection<br/>
            •  Control System: Variable Range Analog Timers<br/>
            •  Construction:</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Seal Width: Up to 1/2”/12mm <br/>
            •  Seal Type: Bi-Active<br/>
            •  Seal Head: Quick Disconnect<br/>
            •  Voltage: 220V/1Ph/60Hz</h5>

            <br/>
            <br/>
            <br/>

            <h1>SCISSOR TYPE HAND SEALER</h1>
            <img src={scissorhandsealer} />
            <br/>
            <h5>The Promarks Heat Seal Scissor Sealers are the best machines in their category with standard feature including stainless steel housing and seal wand frame.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Seal Type: Single Element <br/>
            •  Seal Head: Fixed Connection<br/>
            •  Control System: Variable Range Analog Timers<br/>
            •  Construction</h5>
                        <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
                        <h5>•  Seal Width: Up to 1/2”/12mm <br/>
            •  Seal Type: Bi-Active<br/>
            •  Seal Head: Quick Disconnect<br/>
            •  Voltage: 220V/1Ph/60Hz</h5>

            <br/>
            <br/>
            <br/>

            <h1>OB SERIES OPEN BACK SEALERS</h1>
            <img src={backsealer} />
            <br/>
            <h5>Open Back (S.O.B.) is a heavy-duty thermal impulse sealer built into a solid aluminum and steel frame. This configuration is ideal for any bag making or sealing operation. This unit is ruggedly built for continual operation and economically priced. Each sealer is pneumatically powered and available with either standard impulse controls (Heat and Cool time) or digital controls, fully validate able for medical applications. Our unique cabinet style can be used to store a water chiller or rolls of your film. Each unit is freestanding on lock down casters. Seal lengths are available in 20 or 30 inches with a choice of round wires up to 1/2 inch wide seals. Every sealer is guaranteed to provide an airtight/watertight seal. We also offer a unique cylinder mounted flying knife should you require cutting behind the line of the seal.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Control system: Solid state analog timers <br/>
            •  Single heating element on the lower jaw<br/>
            •  Seal width: 1/4″<br/>
            •  Seal length: 36″<br/>
            •  Low pressure safety jaw close system<br/>
            •  Footswitch activation<br/>
            •  Emergency stop switch<br/>
            •  Voltage: 110V / 1PH<br/>
            •  All steel frame with a powder coated finish (white)</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  PLC/HMI control system<br/>
            •  Fully vlidatabe PreciseSeal System<br/>
            •  Seal widths to 2″<br/>
            •  Longer or shorter seal lenghts<br/>
            •  Water cooled seal bars<br/>
            •  Built in water chiller system`<br/>
            •  Anti-tiedown opti-touch switch activation<br/>
            •  Other voltages<br/>
            •  Many other custom options available</h5>

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ThermalImpluseSealer
