import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import bandsaw1 from '../images/bandsaw1.PNG';
import bandsaw2 from '../images/bandsaw2.PNG';
import bandsaw3 from '../images/bandsaw3.PNG';
import bandsaw4 from '../images/bandsaw4.PNG';
import bandsaw5 from '../images/bandsaw5.PNG';
import bandsaw6 from '../images/bandsaw6.PNG';
import bandsaw7 from '../images/bandsaw7.PNG';
import bandsaw8 from '../images/bandsaw8.PNG';
import bandsaw9 from '../images/bandsaw9.PNG';

function BandSaw() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
            <div className="inner-canwashermachine">
                <h1>TABLE TOP BANDSAWS</h1>
                <img src={bandsaw1} />
                <br/>
                <h5>•  ST-200 BANDSAWS</h5>
                <h5>•  ST-230 BANDSAWS</h5>
                <h5>•  ST-270 BANDSAWS</h5>
                <h5>•  ST-320 BANDSAWS</h5>
                <br/>
                <img src={bandsaw2} />
                <br/>
                <h5>· Robust 18/10 stainless steel construction. · Construction to work in harsh environment with reliability. · Automatic preset tension control. · Waste pan attached. · Easy cleaning with water pressure. · Detachable cleaners for easy cleaning, without the use of tools. · Protection index IP65. · Machine conforms to Directive 2006/42/EC.</h5>
                <br/>
                <img src={bandsaw3} />
                
                <br/>
                <br/>
                <br/>

                <h1>FLOOR STANDING BANDSAWS</h1>
                <img src={bandsaw4} />
                <br/>
                <h5>•   F (FIX TABLE)</h5>
                <h5>•   M (BEARING TABLE)</h5>
                <h5>•   R (RAMP TABLE)</h5>
                <h5>•   B (BANKED TABLE)</h5>
                <h5>•   3 PULLEYS – FIX TABLE</h5>
                <br/>
                <img src={bandsaw5} />
                <br/>
                <h5>· Bandsaw for the meat and fish industries. Special robustness for large meat processing facilities and easy operating procedure. <br/><br/>
                · The design eases a good integration on the different lines of cutting process. <br/><br/>
                · Robust 18/10 stainless steel construction. <br/><br/>
                · Stainless steel pulleys with double flap. <br/><br/>
                · Automatic process of tension control. <br/><br/>
                · Safety device to stop running blade in 4 seconds. <br/><br/>
                · On-off switch with emergency stop button, IP65. <br/><br/>
                · Safety device when opening door. <br/><br/>
                · No volt release system. <br/><br/>
                · Manufacturing of blade on the right and left hand side. <br/><br/>
                · Easy cleaning with water pressure. <br/><br/>
                · A one piece body construction to ease the better cleaning on a uniform platform. <br/><br/>
                · Detachable cleaners for easy cleaning, without the use of tools. <br/><br/>
                · Scrapers for pulley cleaning purpose on the cutting process.</h5>
                <br/>
                <img src={bandsaw6} />

                <br/>
                <br/>
                <br/>

                <h1>3 PULLEYS – FIX TABLE</h1>
                <img src={bandsaw7} />
                <br/>
                <h5>· Bandsaw for the meat and fish industries. Special robustness for large processing facilities and easy operating procedure.</h5>
                <h5>· Robust 18/10 stainless steel construction.</h5>
                <h5>· Stainless steel pulleys Ø400 with double flap.</h5>
                <h5>· Automatic process of tension control.</h5>
                <h5>· Safety device to stop running blade in 4 seconds.</h5>
                <h5>· On-off switch with emergency stop button, IP65.</h5>
                <h5>· Safety device when opening door.</h5>
                <h5>· No volt release system.</h5>
                <h5>· Detachable cleaners for easy cleaning, without the use of tools.</h5>
                <h5>· Easy cleaning with water pressure.</h5>
                <h5>· Manufacturing of blade on the right and left hand side.</h5>
                <br/>
                <img src={bandsaw8} />
                <br/>
                <img src={bandsaw9} />
            </div>
        </div>  
      <Footer/>
    </div>
  )
}

export default BandSaw
