import React from 'react';
import '../styles/services/services.css';
import home from '../icons/home.png';
import support1 from '../icons/support.png';
import next from '../icons/next.png';
import setting from '../icons/setting.png';
import support2 from '../icons/support (1).png';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <div className='services'>
      <div className='services-inner'>
        <div className='service'>
            <div className='logo'>
                <img src={support2} alt="" />
            </div>
            <h1 className='header'>Amazing Deals</h1>
            <h5 className='sub-header'>We have a great experience in our industry. We run our business with quality products and services with customer feeedbacks.</h5>
            <div className='arrow'>
                <Link to='/about'>
                    <img src={next} alt="" />
                </Link>
            </div>
        </div>
        <div className='service'>
            <div className='logo'>
                <img src={setting} alt="" />
            </div>
            <h1 className='header'>Quality Products</h1>
            <h5 className='sub-header'>Our machines and spare parts can have vast range of ways, including Meat, Fish, Poultry, Packaging etc.</h5>
            <div className='arrow'>
                <Link to='/products'>
                    <img src={next} alt="" />
                </Link>
            </div>
        </div> 
        <div className='service'>
            <div className='logo'>
                <img src={support1} alt="" />
            </div>
            <h1 className='header'>Customer Support</h1>
            <h5 className='sub-header'>We have a great team. So you can contact us any time in 24Hrs. We are giving you a great customer services for our services.</h5>
            <div className='arrow'>
                <Link to='/contacts'>
                    <img src={next} alt="" />
                </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
