import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import medicalvaccumchamber from '../images/medicalvaccumchamber.png';

function MedicalVaccumChmaber() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>MEDICAL VACCUM CHAMBER</h1>
            <img src={medicalvaccumchamber} />
            <h5>Promarks PLC/HMI operated vacuum chamber is the most unique of its kind available for optimum control of your vacuum requirements. Each Stainless Steel vacuum chamber is ideal for all applications including medical, pharmaceutical or industrial applications where precise control over vacuum level is required. The PLC/HMI system allows you to control vacuum draw in inches of HG or time. Either can be maintained to 30 inches of HG or less. The optional Gas flush system can be programmed with pressure or time. The temperature for the seal bar is controlled through a thermocouple employing the same trademarked Precise Seal process used in all of our medical equipment. The accuracy of the preset temperature is +/- 1 Deg. F. Other features include the ability to minimize boil-over of liquids and the regulate the speed of air evacuation and reintroduction of an atmosphere. Each chamber is validate able with calibration ports making this ideal for validation. Multi-stage gas-vac or vac-gas can be easily programmed into the touch screen. Up to six password protected processes are also standard as well as multiple alarms, offset screen and a complete troubleshooting menu. Once you have determined your process parameters the package(s) to be sealed is placed inside the chamber with the open end across the seal bar. The operator closes the lid, the machine then goes through the complete cycle and releases the lid when finished. Typical cycle times range from 15-25 seconds.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  PLC / HMI Control System</h5>
            <h5>•  Stainless Steel Construction</h5>
            <h5>•  Fully Validatable Seal Temperature, Vacuum Pressure & Seal Pressure</h5>
            <h5>•  Bladder Activated Sealing</h5>
            <h5>•  Water Resistant Electrical Box (IP 56)</h5>
            <h5>•  Durable Removable Aluminum Cushion Bar</h5>
            <h5>•  Busch Vacuum Pump</h5>
            <h5>•  Air Assist Sealing</h5>
            <h5>•  6mm Seal Wire or Cut-Off</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Communication Ports</h5>
            <h5>•  Higher Capacity Vacuum Pump</h5>
            <h5>•  Customized Taller Lid</h5>
            <h5>•  Air Cylinder Activated Sea</h5>
            <h5>•  Bi-Active Seal</h5>
            <h5>•  Gas Flush System</h5>
            <h5>•  Twin Beam Sealing</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default MedicalVaccumChmaber
