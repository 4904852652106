import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import tumbler from '../images/tumbler.png';

function Tumbler() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
          <div className="inner-canwashermachine">
            <h1>VACUUM TUMBLER AND MASSAGER</h1>
            <img src={tumbler} />
            <br/>
            <h5>Vedio : <a href=" https://youtu.be/cm1RPaq-84Q">Click here</a></h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Promarksvac TM & TMC Series Vacuum Marinating Tumblers & Massagers</h2>
            <h5>Promarksvac manufactures two series of vacuum marinating tumblers & massagers. Their TMC Series features a non-continuous vacuum system and is their most cost conscious line of tumblers and their TM Series feature continuous vacuum systems in both tumblers and the higher throughput massager models.</h5>
            <h5>We are often asked why choose would I choose one type of vacuum system over the other and that is a good question and the answer lies in what system best meets your needs. In order to find the answer you  need to consider all of your needs, economic, type of product, desired results, ease of use as well as others that may be on your list. With this in mind let us take a closer look at the two different vacuum systems.First let us take a look at what both the TM & TMC Series vacuum marinating machines have in common.</h5>
            <br/>
            <h5>•  Rugged, sanitary # 304 stainless steel frame, housing and drum</h5>
            <h5>•  A taller frame that allows you to place a standard buggy beneath the drum for east unloading</h5>
            <h5>•  A frequency drive for control of the drum speed</h5>
            <h5>•  A harsh environment control cover</h5>
            <h5>•  Operator safety system</h5>
            <br/>
            <h5>The TMC Series non-continuous vacuum system. Bye the way let us clear up some confusion here. If the TMC Series is non-continuous then why the C in TMC. Well the C stands for Classic because in the USA the non-continuous vacuum system type of machines have always been the standard of the industry for all of us economic minded Americans. With that in mind let’s take a look at how well the TMS Series meets the needs listed above.
            Economic: With their simpler control system and drum vacuuming system the TMC units are less expensive to purchase and somewhat easier and less costly to maintain. Kind of the good old pick-up truck vs. the European sports car.
            Type of Product: while both series work very well with a variety of products the TMC machines do not have a vacuum snorkel inside the drum and this can prove to be a plus when processing heavy bone in products. The reason being that this type of product can cause damage to the internal vacuum snorkel on the TM machines even though the snorkel’s was designed to minimize this possibility.</h5>
            <br/>
            <h5>Desired Results: Most processors are looking for maximum pick-up and gentle tenderizing for their products. While both TM & TMC machines offer gentle tenderizing the TMC units fall short of the goal of maximum pickup as they cannot offer the sponge effect that can be produced by the continuous vacuum system used in the TM machines. The reason for this is that it is impractical to vent the drum and then re-evacuate it during the process and impossible to do this while the machine remains in the mixing mode with the drum moving.</h5>
            <br/>
            <h5>Ease of use: The operation of a TMC machines is very simple but also very manual. To evacuate the drum you need to connect the vacuum hose to the vacuum port on the drum then engage the vacuum pump and then monitor the vacuum gauge until the desired vacuum level is obtained. Once the desired vacuum level has been reached you then remove the hose from the port and replace it back in the holder. Once this part of the operation is completed you set the mixing mode timer and then engage the start switch. If you were to want to do any venting of the drum and re-evacuating this would be an all manual operation and is therefore almost never part of the process cycle when using a non-continuous vacuum system based machine.</h5>
            <br/>
            <h5>The TM Series continuous vacuum system machines are the type of vacuum marinating machines that have been the standard of the industry in Europe for many years as they provide not only for greater pick-up for the product being processed but also complete the process cycle more automatically. Let us take a look at the same list of needs as above.</h5>
            <br/>
            <h5>Economic: With their more sophisticated vacuum system and micro-processor control system the continuous vacuum system machines are like that European sports car, somewhat more expensive to purchase and a little more costly to maintain. However the greater pick-up and the set it and forget it control systems can easily offset these slightly higer costs.</h5>
            <br/>
            <h5>Type of Product: As mentioned earlier heavy bone-in product can cause some issues in a continuous vacuum system machines due to the vacuum snorkel inside the drum. However the design of the TM Series tumbler vacuum snorkels goes a long way in eliminating that issue and pay attention to the product load and not overloading the drum can reduce it to a non-issue.
            Desired results: There is no question that the combination of a continuous vacuum system and a sophisticated control system can result in a truly superior product.</h5>
            <br/>
            <h5>Ease of use: The combination of the continuous vacuum system and the PV-03 microprocessor control system allow the TM Series vacuum marinating tumblers and massagers to be very simple to use while obtaining superior results. The vacuum snorkel inside the drum allows the vacuum to be either pulled or vented while the drum remains in motion. The PV-03 takes full advantage of this by allowing the operator to set a process cycle that includes both mixing and rest times as well as venting and re-evacuating the drum automatically. It also allows you to set how many time this process cycle is repeated before the machine stops and is ready to be unloaded. Another benefit of the vacuum snorkel in the drum is noticed if there are any small vacuum leakage during the process cycle as the controls will re-engage the vacuum pump and bring the vacuum level back up to what was originally set in the system. The bottom line is that both the TMC non-continuous vacuum system tumblers and the continuous vacuum system TM vacuum marinating tumblers and massagers have their place in various processing operations and Promarksvac is one of the only companies that manufacture a rugged and dependable line of both types of machines.</h5>
            <br/>
            <h1>MODEL TM-20   -  TM-5000 VACUUM TUMBLING MACHINE</h1>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>standard Features:</h2>
            <h5>•  Stainless Steel Construction</h5>
            <h5>•  BUSCH Vacuum Pump</h5>
            <h5>•  Variable Speed</h5>
            <h5>•  Continuous Vacuum</h5>
            <h5>•  Reverse Discharge Function</h5>
            <h5>•  PV-03 Microprocessor Control Panel</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  PLC / HMI Control System</h5>
            <h5>•  Larger Vacuum Pumps</h5>
            <h5>•  Load Cells</h5>
            <h5>•  Buggy Dumper Systems</h5>
            <h5>•  Suction Tables</h5>
            <h5>•  Load /Unload Conveyor Systems</h5>
            <h5>•  Custom Voltage</h5>
          </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Tumbler;
