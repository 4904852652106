import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import traysealerandmappackagingmachine from '../images/traysealerandmappackagingmachine.png';

function TraySealerMapMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>TRAY SEALER AND MAP PACKAGING MACHINE</h1>
            <img src={traysealerandmappackagingmachine} />
            <h5>The Promarks VT400 and VT570 are modified atmosphere tray sealers that are designed for small- to medium-sized operations. By pulling a vacuum and inserting a pure gas mixture, the optimal shelf life can be reached for a wide range of products. Capable of accepting a variety of trays of all shapes and sizes, our machines can be built for your specific tray and film configurations. Vacuum, gas, and seal times can all be easily adjusted via the fully programmable digital control board. For added reliability we only use Busch USA vacuum pumps on all of our modified atmosphere tray sealers.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Features:</h2>
            <h5>Heavy Gauge Stainless Steel Construction</h5>
            <h5>Semi-Automatic Operation</h5>
            <h5>Automatic Film Advance and Cutting System</h5>
            <h5>Programmable Water Resistant Control Panel (IP56)</h5>
            <h5>Printed Film Registration System</h5>
            <h5>Busch Vacuum Pump</h5>
            <h5>Internal Gas Holding Tank</h5>
            <h5>Auto Tray Lift System for Easy Removal</h5>
            <h5>Film Tensioner</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Specification:</h2>
            <h5>[table id=33 /]</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>Single Phase Vacuum Pump up to 100m3/h(5HP)</h5>
            <h5>Extra Die Sets</h5>
            <h5>Custom Voltage</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default TraySealerMapMachine
