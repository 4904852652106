import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import shacklewasher from '../images/shacklewasher.png';

function ShackleWasher() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={shacklewasher} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>SHACKLE WASHER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The shackle washer cleans continuously the chain and the shackles of the overhead conveyor. The cleaning is carried out by water and by two rotating nylon brushes. Frame and protections are made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">8.0000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.050</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons.</div>
                            <div class="box len">1,5</div>
                            <div class="box len">m <span>3</span>/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,37 * 2</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">140</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">1</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default ShackleWasher
