import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishsmokingchamber from '../images/fishsmokingchamber.png';
import fishsmokingchamber1 from '../images/fishsmokingchamber1.PNG';
import fishsmokingchamber2 from '../images/fishsmokingchamber2.PNG';
import fishsmokingchamber3 from '../images/fishsmokingchamber3.PNG';

function FishSmokingChamber() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH SMOKING CHAMBER</h1>
            <img src={fishsmokingchamber} />
            <h5>The smoking chamber is designed for the thermal processing of cold cuts, meats, poultry, fish and cheese. The basic processes include: drying, smoking, scalding, roasting and chilling.</h5>
            <h5>The devices are made in single or multi-trolley versions, executed entirely of high-quality acid-resistant steel.</h5>
            <h5>Upon the customer’s request, we execute chambers with right-handed or left-handed doors. The chambers can be executed in any selected layout. In addition to standard dimensions, we are also able to execute custom devices according to customer specification, adapting the dimensions to the space available in the plant.</h5>
            <h5>An additional possibility of adapting our chambers to the customer’s requirements is provided by the optional retrofitting of the devices with additional burners or electric heaters, which means the chamber obtains the functionality of an oven. Each chamber is equipped with a smoke generator with a system preventing the ignition of wood chips, an automatic cleaning system and a high class microprocessor controller with the possibility of recording processes.</h5>
            <h5>The modular design of the chambers allows for the construction of multi-trolley devices. Each module is equipped with a separate intake and exhaust unit, which – along with optimally directed air circulation – ensures a uniform temperature as well as a uniform color of smoking is maintained
in the products throughout the chamber. The smoking chamber is built of thermally insulated elements, which together form a fully hermetic body. The insulation layer is 50 mm in the case of side walls, 70 mm in the case of the ceiling and 100 mm in the case of the front surface (the door and the door frame). The doors are equipped with double hinges that support the doors during opening. This system allows for pushing the bottom seal edge against the door frame. This allows for the use of a permanent ramp facilitating daily use.</h5>
            <h5>The security system allows for the chamber to be opened from the inside. Doors available in the right-handed and left-handed version. The floor is built of a frame made of closed profiles, allowing for the entry of even the heaviest trolleys used in the food industry. The floor surface is made of a sheet with a thickness of 5 mm. The total thickness of the floor with insulation is 30 mm.</h5>
            <img src={fishsmokingchamber1} alt="product image"/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Technical Structure</h2>
            <img src={fishsmokingchamber2} alt="product image"/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Technical Data</h2>
            <img src={fishsmokingchamber3} alt="product image"/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default FishSmokingChamber;
