import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import vaccumsealer from '../images/vaccumsealer.png';

function VaccumeSealer() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>VACCUM SEALER</h1>
            <img src={vaccumsealer} />
            <h5>All seals are guaranteed to be airtight and watertight, making the AVS Vacuum Sealers ideal for food, medical and electronic applications. The integrity of each seal of our vacuum sealers is accomplished through the use of dual 1/4″ NiChrome seal bands combined with variable range timers which provide the user with extreme control over seal and cool time. This combination assures faster vacuum seals and outstanding seal integrity</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  Variable Vacuum, Gas, Heat and Cool Timers</h5>
            <h5>•  Dual 1/4 Inch Nichrome Bands</h5>
            <h5>•  Low Pressure Safety Descent</h5>
            <h5>•  Dual Retractable Stainless Steal Nozzles</h5>
            <h5>•  Foot Pedal Activated</h5>
            <h5>•  Adjustable Angle Shelf</h5>
            <h5>•  Clean Room Compatible</h5>
            <h5>•  Exhaust Port</h5>
            <h5>•  Pneumatically Operated</h5>
            <h5>•  High and Low Pressure Gauges with Regulator</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  Gas and Vacuum</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default VaccumeSealer
