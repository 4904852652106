import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ProductSelector from '../components/ProductSelector';
import VegeFoodProducts from '../components/VegeFoodProducts';
import '../styles/Products/products.css';

function VegeFood() {
  return (
    <div style={{
        backgroundColor: '#151618'
      }}>
        <Navbar/>
        <div className='wrapper' style={{
          maxWidth: '1000px',
          minHeight: '50vh',
          margin: '0 auto',
          display: 'flex',
          color: 'white',
          gap: '10px',
        }}>
          <ProductSelector/>
          <VegeFoodProducts/>
        </div>
        <Footer/>
      </div>
  )
}

export default VegeFood
