import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import slicer from '../images/slicer.png';
import slicer2 from '../images/slicer2.PNG';
import slicer1 from '../images/slicer1.PNG';

function Slicer() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>SLICER</h1>
            <img src={slicer} />
            <h5>· Safety device on blade cover.</h5>
            <h5>· Blade protection ring.</h5>
            <h5>· 24 V. low-tension safety system.</h5>
            <h5>· On-Off switches.</h5>
            <h5>· Spikes plate of product holder removable.</h5>
            <h5>· Protection Index IP65.</h5>
            <h5>· Slicer constructed of 18/10 stainless steel.</h5>
            <h5>· German blade G+B.</h5>
            <h5>· Automatic blade sharpener.</h5>
            <h5>· Cutting thickness: 0-25 mm.</h5>
            <br/>
            <img src={slicer1} />
            <br/>
            <img src={slicer2} />
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Slicer;
