import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import finisher from '../images/finisher.png';

function Finisher() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={finisher} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>FINISHER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine carefully clean and washes the birds outside by the combination action of sprayed water and rubber fingers fixed on rotating drums.The machine is made of stainless steel. The drums holding the fingers are driven by a motorgearbox</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">6.0000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">2.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">2.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons.</div>
                            <div class="box len">400 - 2000</div>
                            <div class="box len">l / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Motors</div>
                            <div class="box len">2</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">3</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">900</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">3,0</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default Finisher
