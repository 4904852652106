import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import foamingmachine1 from '../images/../images/foamingmachine1.PNG';
import foamingmachine2 from '../images/../images/foamingmachine2.PNG';

function FoamCoatMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
            <div className="inner-canwashermachine">
                <h5>Chicken/ Fish;  Nugget/Finger/Drumstick/cake  Frying Making Machine & Breading Maker Machine.</h5>
                <h1>FORMING MACHINE</h1>
                <h5>Automatic Multi Forming machine adopts the method of a four-screw feeding system, greatly reduces the cutting of the material and has better features of high production capacity and stable quality.
    Widely handles in the forming procedure of meat, poultry, fish, shrimp, and the potato, tuber crops. And it can be applied in the fields of all kinds of minced/sliced products for premier meat texture taste.</h5>
                <h1>FLOURING MACHINE</h1>
                <h5>The flouring machine is specially designed for coating food processing, it coats the bottom of the products by the layer of the flour on the conveyor belt.
    Using the airflow system which is easy to connect to the preduster, it can collect the flour generated from the falling of the flour inside and recycle it, separate the air and the flour, suck the clean air onto the products to blow off the extra flour.</h5>

                <h1>BATTERING MACHINE</h1>
                <h5>The battering machine
    It is suitable for the processing procedure before breading and flouring.
    The special design of the batter pump can greatly reduce the cutting of the batter.
    Easy to clean, less damage to the viscosity of the batter.Easy and reliable adjustment.
    SIEMENS electric apparatus guarantee safety and reliability.
    Stainless steel made, creative design, reasonable structure, and reliable features.</h5>
                <br/>
                <img src={foamingmachine1} />
                <br/>
                <h1>BREADING MACHINE</h1>
                <h5>Breading machine coats evenly the chicken, beef, meat, and seafood a top layer by outpouring the crumbs from the hopper and a bottom layer while the products are passing the conveyor.
    Excellent crumbs circulation system virtually reduces the cutting damage of the crumbs, easy to realize the standard production</h5>

                <h1>FRYING MACHINE</h1>
                <h5>Automatic frying machine, with electric heating and gas heating, the heating time is short, and the frying speed is fast. There is an interlayer inside the machine, which can play a role in heat preservation and heat insulation. Allow the oil temperature to rise quickly and maintain a certain temperature so that the material can be fried at a constant temperature.</h5>

                <h1>AIR DRYER</h1>
                <h5>The air dryer, the high-power fan can quickly cool the onion rings, and the onion rings can be cooled by multiple fans along with the movement of the mesh belt.</h5>

                <h1>INSTANT FREEZING MACHINE</h1>
                <h5>The quick-freezer, multi-layer mesh belt design can fully freeze the onion rings, the freezing speed is high and the efficiency is high, and the batch production can be completed.</h5>
<br/>
                <img src={foamingmachine2} />
                <br/>
                <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}></h2>
            </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FoamCoatMachine
