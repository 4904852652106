import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import meatflattenmachine1 from '../images/meatflattenmachine1.PNG';
import meatflattenmachine2 from '../images/meatflattenmachine2.PNG';
import meatflattenmachine3 from '../images/meatflattenmachine3.PNG';

function MeatFlatteningMachine() {
  return (
    <div>
        <Navbar/>
        <div className="canwashermachine">
            <div className="inner-canwashermachine">
                <h1>MEAT FLATTENING MACHINE</h1>
                <img src={meatflattenmachine1} />
                <br/>
                <h5>Chicken breast beef pork fish meat press flatten machine</h5>
                <h5>DRB-R1, it is a special machine meat press for fresh mea like pork for pork chop,pork loades, schnitzels, beef for beef brisket, beet steak, beef chop, beef loades, poultry for chicken breast , duck breast , breasts coated with breadcrumbs, chicken Kiev (côtelette de volaille), seasoned grill portions prepared from different meats tenderised to optimum thickness, hamburgers to equal round portions,pizza shells and many others, it is the one of fashion meat processing machine for meat plant. It suitable for all kind of boneless meat and slightly frozen meat without huge bone like beef, mutton and pork meat ... and with thickness of meat can be smoothly and precisely adjusted to 1 to 25 mm.
                    Easy operation and cleaning, the machine is made of full food grade SUS 304.</h5>
                <br/>
                <img src={meatflattenmachine2} />
                <br/>
                <img src={meatflattenmachine3} />
                <br/>
                <h5>Name :  Meat Press Machinery <br/>
                Model :  DRB-R1 <br/>
                Voltage :  220/380V50HZ <br/>
                Power :  1.5KW <br/>
                Weight :  150KG <br/>
                Material :  SUS 304 <br/>
                Capacity :  2-8S/TIME<br/>
                Max Inlet High :  40mm<br/>
                Mini Cutting Thickness :  1-25mm</h5>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default MeatFlatteningMachine;
