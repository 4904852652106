import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/wastetreatementproducts.css';
import featherandoffalseparator from '../images/featherandoffalseparator.png';

function FeatherOffalOperator() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={featherandoffalseparator} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>FEATHER AND OFFAL SEPARATOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine separates the water from the solid products that are pumped here from the slaughtering plant. Thank to an inside screw, the solid is discharged at the exit of the rotating drum while the water is collected into a receiving hopper belaw the perforated drum. The frame and the drum are made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Model</div>
                            <div class="box len">15</div>
                            <div class="box len">20</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">100</div>
                            <div class="box len">150</div>
                            <div class="box len">m <sup>3</sup></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.250</div>
                            <div class="box len">150</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">2.500</div>
                            <div class="box len">1.250</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.700</div>
                            <div class="box len">1.700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">0,7</div>
                            <div class="box len">0,9</div>
                            <div class="box len">m <sup>3</sup>/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">1,1</div>
                            <div class="box len">1.1</div>
                            <div class="box len">k2</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">320</div>
                            <div class="box len">240</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">5,3</div>
                            <div class="box len">6,3</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FeatherOffalOperator
