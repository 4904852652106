import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import ventcutter from '../images/ventcutter.png';

function VentCutter() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={ventcutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>VENT CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The Vent Cutter removes the vent from the chicken by producing a circular cut around the vent. After cutting ,the vent and bursa is lifted out of the bird by a pin and released outside the tail on the back of the chicken. No vacuum is required. The chickens are positioned into the machine below the blade by special clamping unit, self adjusting according to the size of the birds. The rotation of the circular blades is controlled by a set of gears driven by the machine itself. A hand operated stainless steel hydraulic jack raise and lower the machine allowing adjustment for flock variations. Machine is made of stainless steel and food approved nylon.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">6.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.960</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.720</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">2.940</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">350</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">1,5</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">1.400</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">7</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default VentCutter
