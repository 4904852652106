import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import conveyorline from '../images/conveyorline.png';

function ConveyorLine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>CONVEYOR LINE</h1>
            <img src={conveyorline} />
            <br/>
            <h5>This is a group of machines used for sequential unloading of the autoclave carriages when the cans arrive in bulk and subsequent alignment to feed the rest of the line.</h5>
            <br/>
            <h5>•  Carriage turner (driven by the hydraulic unit and cylinder).</h5>
            <h5>•  Buffer conveyor for can reception.</h5>
            <h5>•  Alignment disc fitted with exit conveyor.</h5>
            <h5>•  In addition, depending on the current can type, a can turner may be installed to position them all the right way up.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ConveyorLine
