import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import singlechamber from '../images/singlechamber.png';

function SingleVaccumeChamber() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>SINGLE VACCUM CHAMBER</h1>
            <img src={singlechamber} />
            <h5>Promarksvac’s model SC-800LR is truly a heavy-duty industrial vacuum chamber sealing machine. With features like all # 304 stainless steel construction, a flat working deck for easy cleaning and a robust Busch RA0063 (3HP) vacuum pump as standard the SC-800LR is ready to meet your heavy duty vacuum packaging needs. As with the entire Promarksvac line of vacuum chamber sealing machines the SC-800LR has been ETL tested, certified and labeled as meeting UL and CSA safety standards as well as NSF sanitary standards. This means the SC-680LR is ready to go to work in any inspected facility. Available options include larger vacuum pumps, particulate and moisture trap filters, to protect the vacuum system, per knife bag trim systems and many more. The standard PD-01 basic digital control panel allows the operator to set vacuum time, gas flush time (if your machine is equipped with the optional gas flush system), seal time and cooling time at the touch of a button and with accuracy due to the digital read out. If your applications require more control features including recipe memory the optional PNC-01 microprocessor control panel may be just what you need. The PNC-01 allow the vacuum to be controlled via vacuum pressure or by time and allows multi-stage vacuum / gas flush operations (optional gas flush system required) just to name a few of the capabilities of this control system. For applications that require a temperature controlled seal system and / or a fully validate able control system the SC-800LR can be equipped with our Precise Seal PLC/HMI based control system. This system allows for the seal to be controlled via temperature or time as well as allowing for the vacuum to by very precisely controlled via vacuum pressure. Optional calibration ports are also available allowing a 3rd party calibration lab to easily calibrate and certify your machine.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Applications:</h2>
            <h5>•  Hi volume Sous Vide preparation</h5>
            <h5>•  Cheese packaging</h5>
            <h5>•  Vitamin ingredient bulk packaging</h5>
            <h5>•  Vacuum packaging laser printer cartridges</h5>
            <h5>•  Many more</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Standard Features:</h2>
            <h5>•  PD-01 Basic Digital Control Panel</h5>
            <h5>•  Stainless Steel Construction</h5>
            <h5>•  Flat Chamber for Easy Cleaning</h5>
            <h5>•  Bladder Activated Sealing</h5>
            <h5>•  Water Resistant Electrical Box (IP 56)</h5>
            <h5>•  Durable Removable Aluminum Cushion Bar</h5>
            <h5>•  Busch RB0063 (3HP) Vacuum Pump</h5>
            <h5>•  Air Assist Sealing</h5>
            <h5>•  6mm Seal Wire or Cut-Off</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Options:</h2>
            <h5>•  PNC-01 Microprocessor Control</h5>
            <h5>•  Higher Capacity Vacuum Pump</h5>
            <h5>•  Customized Taller Lid</h5>
            <h5>•  Soft Air</h5>
            <h5>•  Bi-Active Seal</h5>
            <h5>•  Gas Flush System</h5>
            <h5>•  Twin Beam Sealing</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default SingleVaccumeChamber
