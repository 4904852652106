import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import featherpress from '../images/featherpress.png';

function FeatherPress() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
    <div class="birds-inner">
        <div class="left">
            <div class="img">
                <img src={featherpress} alt="product image"/>
            </div>
        </div>
        <div class="right">
            <div class="name">
                <h2>FEATHER PRESS</h2>
            </div>
            <div class="description">
                <h2>Description</h2>
                <h5>This machine is used to reduce the content of the water into the feather after the feather separator.
The compactor has inside a shaft-less screw and is equipped with a loading section with inlet hopper, and a draining zone with johnson filter 0.5 mm  where most part of the water is removed and a second zone equipped with a compacting and unloading unit.</h5>
            </div>
            <div class="data">
                <h2>Technical Data</h2>
                <div class="table">
                    <div class="row">
                        <div class="box">Model</div>
                        <div class="box len">400 RPS</div>
                        <div class="box len"></div>
                    </div>
                    <div class="row">
                        <div class="box">Capacity</div>
                        <div class="box len">4</div>
                        <div class="box len">M <sup>3</sup>/h</div>
                    </div>
                    <div class="row">
                        <div class="box">Lenght</div>
                        <div class="box len">3.010</div>
                        <div class="box len">mm</div>
                    </div>
                    <div class="row">
                        <div class="box">Width</div>
                        <div class="box len">431</div>
                        <div class="box len">mm</div>
                    </div>
                    <div class="row">
                        <div class="box">Total Weight</div>
                        <div class="box len">5,5</div>
                        <div class="box len">kw</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      <Footer/>
    </div>
  )
}

export default FeatherPress
