import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import shrimporiginal from '../images/shrimporiginal.PNG';
import shrimporishrimp1ginal from '../images/shrimp1.PNG';
import shrimp2 from '../images/shrimp2.PNG';

function ShrimpFeedPallet() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>SHRIMP & SINKING FISH FEED</h1>
            <img src={shrimporiginal} />
            <br/>
            <h5>(1) Specially designed to deliver powder material.</h5>
            <h5>(2) Large capacity, safe and reliable.</h5>
            <h5>(3) Strong adaptability, convenient installation and repair, long service life, and can be widely used In many kinds of powdery, granular and small pieces of material level and small angle(0°-20°）delivery.</h5>
            <h5>(4) The machine has the advantages of small volume, low noise, inlet and outlet position of flexible arrangement.</h5>
            <br/>
            <img src={shrimporishrimp1ginal} alt="product image"/>
            <br/>
            <h5>(5) Optional material blocking preventing alarm, speed,</h5>
            <h5>* Siemens motor.</h5>
            <h5>* Mixing period: 180s </h5>
            <h5>* High uniform,patent the ribbon design enhanced material flow,shear,mixing,mixing uniformity very good, CV≦ 5% </h5>
            <h5>* The ribbon and chassis,to ensure the retention rate is very low,to prevent cross-contamination of materials;discharge gate fully open,the material instantly emptied,no residue</h5>
            <h5>* No leakage; long time serving time (6) Convenience and speedy access door,designed for easy cleaning maintenance,saving time</h5>
            <br/>
            <img src={shrimp2} alt="product image"/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ShrimpFeedPallet
