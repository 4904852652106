import React from 'react'
import '../styles/css/aboutbanner.css';
import { Link } from 'react-router-dom'

function AboutBanner() {
  return (
    <div className='aboutbanner'>
      <div className='aboutbanner-inner'>
        <div className='left'>
            <h1>Would you like to contact to our team members !</h1>
            <h5>Just get touch with us...</h5>
        </div>
        <div className='right'>
            <Link to='/contacts' className='btn'>
                CONTACT
            </Link>
        </div>
      </div>
    </div>
  )
}

export default AboutBanner
