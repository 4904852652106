import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import chillinhroomtrolley from '../images/chillinhroomtrolley.png';

function ChillingRoomTrolley() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={chillinhroomtrolley} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CHILLING ROOM TROLLEY</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This trolley is used to carry the birds inside the chilling room. The frame is made of stainless steel with mounted four wheels. The trolley has a capacity of 190 birds.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table"> 
                    <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">190</div>
                            <div class="box len">q</div>
                        </div>
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">1.500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">80</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,6</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ChillingRoomTrolley
