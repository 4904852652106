import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Services from './pages/Services';
import Fish from './pages/Fish';
import Feed from './pages/Feed';
import VegeFood from './pages/VegeFood';
import Packaging from './pages/Packaging';
import Other from './pages/Other';
import Pig from './pages/Pig';
import ChickenTurkey from './pages/ChickenTurkey';
import Waste from './pages/Waste';
import Cutup from './pages/Cutup';
import Evicreation from './pages/Evicreation';
import Defeathering from './pages/Defeathering';
import Chilling from './pages/Chilling';
import BrineMixer from './pages/BrineMixer';
import BandSaw from './pages/BandSaw';
import MeatFlatteningMachine from './pages/MeatFlatteningMachine';
import FoamCoatMachine from './pages/FoamCoatMachine';
import BrineInjector from './pages/BrineInjector';
import MDMMSMMachine from './pages/MDMMSMMachine';
import MeatMeatBallMachine from './pages/MeatMeatBallMachine';
import MetalDetectorMachine from './pages/MetalDetectorMachine';
import MixerGrinder from './pages/MixerGrinder';
import SausageCutter from './pages/SausageCutter';
import SausageLoadingSystem from './pages/SausageLoadingSystem';
import Slicer from './pages/Slicer';
import Tumbler from './pages/Tumbler';
import SmokingScaldingChamber from './pages/SmokingScaldingChamber';
import VaccumeFillers from './pages/VaccumeFillers';
import ShrimpFeedPallet from './pages/ShrimpFeedPallet';
import AnimalFeedPallet from './pages/AnimalFeedPallet';
import BlanchingMachine from './pages/BlanchingMachine';
import BlastFreezingMachine from './pages/BlastFreezingMachine';
import DehydtratorMachine from './pages/DehydtratorMachine';
import FrenchFiresMachine from './pages/FrenchFiresMachine';
import FruitsCuttingMachine from './pages/FruitsCuttingMachine';
import FruitsPeelingMachine from './pages/FruitsPeelingMachine';
import PotatoPeelingMachine from './pages/PotatoPeelingMachine';
import VegetableCuttingMachine from './pages/VegetableCuttingMachine';
import CassavaPeelingMachine from './pages/CassavaPeelingMachine';
import RottiMakingMachine from './pages/RottiMakingMachine';
import VegetableDehydratorMachine from './pages/VegetableDehydratorMachine';
import BeltTypeVaccumePackaging from './pages/BeltTypeVaccumePackaging';
import ConstantHeatSealer from './pages/ConstantHeatSealer';
import ContinuousVaccumePackgingMachine from './pages/ContinuousVaccumePackgingMachine';
import DoubleChamberVaccumPackagingMachine from './pages/DoubleChamberVaccumPackagingMachine';
import NozzleTypeVaccumPackagingMachine from './pages/NozzleTypeVaccumPackagingMachine';
import ShrinkDipMachine from './pages/ShrinkDipMachine';
import TableTopVaccumMachine from './pages/TableTopVaccumMachine';
import TraySealerMapMachine from './pages/TraySealerMapMachine';
import TheermorformingMachine from './pages/TheermorformingMachine';
import TubeSealer from './pages/TubeSealer';
import VaccumeSealer from './pages/VaccumeSealer';
import MedicalVaccumChmaber from './pages/MedicalVaccumChmaber';
import SingleVaccumeChamber from './pages/SingleVaccumeChamber';
import ThermalImpluseSealer from './pages/ThermalImpluseSealer';
import EuipmentsForAddingOilAndSause from './pages/EuipmentsForAddingOilAndSause';
import CanFishProcessingOutline from './pages/CanFishProcessingOutline';
import CanSealingMachine from './pages/CanSealingMachine';
import CanWasherMachine from './pages/CanWasherMachine';
import ConveyorLine from './pages/ConveyorLine';
import FishBlackSkinPeeler from './pages/FishBlackSkinPeeler';
import FishFilletingMachine from './pages/FishFilletingMachine';
import FishHeadCutter from './pages/FishHeadCutter';
import FishMeatBallProcessMachine from './pages/FishMeatBallProcessMachine';
import FishSkinnerMachine from './pages/FishSkinnerMachine';
import FishSmokingChamber from './pages/FishSmokingChamber';
import FishBlockMaker from './pages/FishBlockMaker';
import FrozenBlockCutter from './pages/FrozenBlockCutter';
import MeatFishSeparator from './pages/MeatFishSeparator';
import MetalDetector from './pages/MetalDetector';
import RetortMachine from './pages/RetortMachine';
import HydralicElevator from './pages/HydralicElevator';
import RollerConveyor from './pages/RollerConveyor';
import Dumping from './pages/Dumping';
import ChainConveyor from './pages/ChainConveyor';
import Container from './pages/Container';
import ContainerWasher from './pages/ContainerWasher';
import AirChiller from './pages/AirChiller';
import GradingLine from './pages/GradingLine';
import WeighingBridge from './pages/WeighingBridge';
import DropoffStations from './pages/DropoffStations';
import MultiBirdsShackleWasher from './pages/MultiBirdsShackleWasher';
import BoxConveyor from './pages/BoxConveyor';
import ChillingRoomTrolley from './pages/ChillingRoomTrolley';
import CutupLine from './pages/CutupLine';
import WeighingBridgeCutup from './pages/WeighingBridgeCutup';
import VisionCamera from './pages/VisionCamera';
import FilletingMachine from './pages/FilletingMachine';
import CutupModules from './pages/CutupModules';
import BirdsConesCutUpLine from './pages/BirdsConesCutUpLine';
import ConesFeboningLineForBreastCap from './pages/ConesFeboningLineForBreastCap';
import FeatherOffalOperator from './pages/FeatherOffalOperator';
import ScrewFilterSeparator from './pages/ScrewFilterSeparator';
import RecyclingPump from './pages/RecyclingPump';
import OffalTank from './pages/OffalTank';
import Crusher from './pages/Crusher';
import Hopper from './pages/Hopper';
import RecyclingCyclone from './pages/RecyclingCyclone';
import FeatherPress from './pages/FeatherPress';
import VaccumPump from './pages/VaccumPump';
import Conveyor from './pages/Conveyor';
import ElectricalStunner from './pages/ElectricalStunner';
import Killing from './pages/Killing';
import BleedingThorugh from './pages/BleedingThorugh';
import Scalders from './pages/Scalders';
import Plickers from './pages/Plickers';
import BirdCounter from './pages/BirdCounter';
import Finisher from './pages/Finisher';
import FleetCutter from './pages/FleetCutter';
import FeetUnloader from './pages/FeetUnloader';
import ShackleWasher from './pages/ShackleWasher';
import BeltConveyor from './pages/BeltConveyor';
import HeadCutter from './pages/HeadCutter';
import StaticHeadPuller from './pages/StaticHeadPuller';
import VentCutter from './pages/VentCutter';
import OpeningMachine from './pages/OpeningMachine';
import BirdWasher from './pages/BirdWasher';
import LungRemover from './pages/LungRemover';
import BirdUnloader from './pages/BirdUnloader';
import GizzarfSplitterPeeler from './pages/GizzarfSplitterPeeler';
import GizzardSeparator from './pages/GizzardSeparator';
import GizzardDefeatingMachine from './pages/GizzardDefeatingMachine';
import GizzardControlTable from './pages/GizzardControlTable';
import ScrewElevator from './pages/ScrewElevator';
import GobletPump from './pages/GobletPump';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contacts' element={<Contact/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/fish' element={<Fish/>}/>
        <Route path='/feed' element={<Feed/>}/>
        <Route path='/vegefood' element={<VegeFood/>}/>
        <Route path='/package' element={<Packaging/>}/>
        <Route path='/other' element={<Other/>}/>
        <Route path='/pig' element={<Pig/>}/>
        <Route path='/chicken' element={<ChickenTurkey/>}/>
        <Route path='/waste' element={<Waste/>}/>
        <Route path='/cutup' element={<Cutup/>}/>
        <Route path='/eviscreation' element={<Evicreation/>}/>
        <Route path='/defeathering' element={<Defeathering/>}/>
        <Route path='/chilling' element={<Chilling/>}/>

        {/* meat products */}
        <Route path='/brinemixer' element={<BrineMixer/>}/>
        <Route path='/bandsaw' element={<BandSaw/>}/>
        <Route path='/meatflattenmachine' element={<MeatFlatteningMachine/>}/>
        <Route path='/foamingmachine' element={<FoamCoatMachine/>}/>
        <Route path='/brineinjector' element={<BrineInjector/>}/>
        <Route path='/mdmmachine' element={<MDMMSMMachine/>}/>
        <Route path='/meatballmachinemeat' element={<MeatMeatBallMachine/>}/>
        <Route path='/metaldetector' element={<MetalDetectorMachine/>}/>
        <Route path='/mixergrinder' element={<MixerGrinder/>}/>
        <Route path='/sausagecutter' element={<SausageCutter/>}/>
        <Route path='/sausageloadingmachine' element={<SausageLoadingSystem/>}/>
        <Route path='/slicer' element={<Slicer/>}/>
        <Route path='/fishsmokingchamber' element={<SmokingScaldingChamber/>}/>
        <Route path='/tumbler' element={<Tumbler/>}/>
        <Route path='/vaccumfillers' element={<VaccumeFillers/>}/>

        {/* fish */}
        <Route path='/addingoilsausesmachine' element={<EuipmentsForAddingOilAndSause/>}/>
        <Route path='/canfishprocessingline' element={<CanFishProcessingOutline/>}/>
        <Route path='/cansealermachine' element={<CanSealingMachine/>}/>
        <Route path='/canwashermachine' element={<CanWasherMachine/>}/>
        <Route path='/conveyor' element={<ConveyorLine/>}/>
        <Route path='/fishblackmeatandskinpeelermachine' element={<FishBlackSkinPeeler/>}/>
        <Route path='/fishfilletingmachine' element={<FishFilletingMachine/>}/>
        <Route path='/fishheadcutter' element={<FishHeadCutter/>}/>
        <Route path='/fishmeaballprocessingmachine' element={<FishMeatBallProcessMachine/>}/>
        <Route path='/fishskinnermachine' element={<FishSkinnerMachine/>}/>
        <Route path='/fishsmokingchamber' element={<FishSmokingChamber/>}/>
        <Route path='/frozenblockmaker' element={<FishBlockMaker/>}/>
        <Route path='/frozenblockcutter' element={<FrozenBlockCutter/>}/>
        <Route path='/meatfishseparator' element={<MeatFishSeparator/>}/>
        <Route path='/metaldetector' element={<MetalDetector/>}/>
        <Route path='/retort' element={<RetortMachine/>}/>

        {/* vege food */}
        <Route path='/blanchingmachine' element={<BlanchingMachine/>}/>
        <Route path='/blastfrezzingmachine' element={<BlastFreezingMachine/>}/>
        <Route path='/dehydratemachine1' element={<DehydtratorMachine/>}/>
        <Route path='/frenchfriesmachine' element={<FrenchFiresMachine/>}/>
        <Route path='/fruitscuttingmachine' element={<FruitsCuttingMachine/>}/>
        <Route path='/fruitpeelingmachine' element={<FruitsPeelingMachine/>}/>
        <Route path='/potatopeelingmachine' element={<PotatoPeelingMachine/>}/>
        <Route path='/vegetablecuttingmachine' element={<VegetableCuttingMachine/>}/>
        <Route path='/cassavapeelingmachine' element={<CassavaPeelingMachine/>}/>
        <Route path='/rottimakingmachine' element={<RottiMakingMachine/>}/>
        <Route path='/vegecuttingmachine' element={<VegetableDehydratorMachine/>}/>

        {/* feed */}
        <Route path='/shrimporiginal' element={<ShrimpFeedPallet/>}/>
        <Route path='/animalfeed' element={<AnimalFeedPallet/>}/>

        {/* pig */}

        {/* other */}

        {/* packaging */}
        <Route path='/belttypevaccumpackaging' element={<BeltTypeVaccumePackaging/>}/>
        <Route path='/constantheatsealer' element={<ConstantHeatSealer/>}/>
        <Route path='/continuousvaccumpackagingmachine' element={<ContinuousVaccumePackgingMachine/>}/>
        <Route path='/doublechambervaccumpackagemachine' element={<DoubleChamberVaccumPackagingMachine/>}/>
        <Route path='/nozzeltypepackagingmachine' element={<NozzleTypeVaccumPackagingMachine/>}/>
        <Route path='/shrinktank' element={<ShrinkDipMachine/>}/>
        <Route path='/tabletopvaccumpackagingmachine' element={<TableTopVaccumMachine/>}/>
        <Route path='/thermoforming' element={<TheermorformingMachine/>}/>
        <Route path='/traysealerandmappackagingmachine' element={<TraySealerMapMachine/>}/>
        <Route path='/tubesealer' element={<TubeSealer/>}/>
        <Route path='/vaccumsealer' element={<VaccumeSealer/>}/>
        <Route path='/medicalvaccumchamber' element={<MedicalVaccumChmaber/>}/>
        <Route path='/singlechamber' element={<SingleVaccumeChamber/>}/>
        <Route path='/bagsealer' element={<ThermalImpluseSealer/>}/>

        {/* live birds handling */}
        <Route path='/hydraulicelevator' element={<HydralicElevator/>}/>
        <Route path='/rollerconveyor' element={<RollerConveyor/>}/>
        <Route path='/dumping' element={<Dumping/>}/>
        <Route path='/chainconveyor' element={<ChainConveyor/>}/>
        <Route path='/container' element={<Container/>}/>
        <Route path='/containerwasher' element={<ContainerWasher/>}/>

        {/* defeathering */}
        <Route path='/conveyor' element={<Conveyor/>}/>
        <Route path='/waterbathstunnerlite' element={<ElectricalStunner/>}/>
        <Route path='/automatickiller1blade' element={<Killing/>}/>
        <Route path='/blendingtrough' element={<BleedingThorugh/>}/>
        <Route path='/jetscadlersrs2ways' element={<Scalders/>}/>
        <Route path='/discomaticpluckersp4' element={<Plickers/>}/>
        <Route path='/birdcounter' element={<BirdCounter/>}/>
        <Route path='/finisher' element={<Finisher/>}/>
        <Route path='/feetcutter' element={<FleetCutter/>}/>
        <Route path='/feetcutter2' element={<FeetUnloader/>}/>
        <Route path='/shacklewasher' element={<ShackleWasher/>}/>
        <Route path='/beltconveyor' element={<BeltConveyor/>}/>

        {/* eviscreation */}
        <Route path='/headcutter' element={<HeadCutter/>}/>
        <Route path='/staticheadpuller' element={<StaticHeadPuller/>}/>
        <Route path='/ventcutter' element={<VentCutter/>}/>
        <Route path='/openingmachine' element={<OpeningMachine/>}/>
        <Route path='/birdwasher' element={<BirdWasher/>}/>
        <Route path='/lungremover' element={<LungRemover/>}/>
        <Route path='/birdunloader' element={<BirdUnloader/>}/>
        <Route path='/gizzardsplitterandpeeler' element={<GizzarfSplitterPeeler/>}/>
        <Route path='/gizzardseparator' element={<GizzardSeparator/>}/>
        <Route path='/gizzarddefeatingmachine' element={<GizzardDefeatingMachine/>}/>
        <Route path='/gizzardcontoltable' element={<GizzardControlTable/>}/>
        <Route path='/screwelevator' element={<ScrewElevator/>}/>
        <Route path='/gibletpump' element={<GobletPump/>}/>

        {/* chilling and weighing */}
        <Route path='/monoshackleairchiller' element={<AirChiller/>}/>
        <Route path='/weighingbridge2' element={<WeighingBridge/>}/>
        <Route path='/gradingline' element={<GradingLine/>}/>
        <Route path='/dropoffstation180' element={<DropoffStations/>}/>
        <Route path='/multibirdsshacklewasher' element={<MultiBirdsShackleWasher/>}/>
        <Route path='/boxesconveyor' element={<BoxConveyor/>}/>
        <Route path='/chillinhroomtrolley' element={<ChillingRoomTrolley/>}/>

        {/* cutup */}
        <Route path='/cutupline' element={<CutupLine/>}/>
        <Route path='/weighingbridge' element={<WeighingBridgeCutup/>}/>
        <Route path='/visioncamera' element={<VisionCamera/>}/>
        <Route path='/fillertingmachine' element={<FilletingMachine/>}/>
        <Route path='/precutmachine' element={<CutupModules/>}/>
        <Route path='/bridsconscutupline' element={<BirdsConesCutUpLine/>}/>
        <Route path='/conesdeboningforbreastcap' element={<ConesFeboningLineForBreastCap/>}/>

        {/* waste treatement */}
        <Route path='/featherandoffalseparator' element={<FeatherOffalOperator/>}/>
        <Route path='/screwfilterseparator' element={<ScrewFilterSeparator/>}/>
        <Route path='/recyclingpump' element={<RecyclingPump/>}/>
        <Route path='/offaltank' element={<OffalTank/>}/>
        <Route path='/crusher' element={<Crusher/>}/>
        <Route path='/hopper' element={<Hopper/>}/>
        <Route path='/receivingcyclone' element={<RecyclingCyclone/>}/>
        <Route path='/featherpress' element={<FeatherPress/>}/>
        <Route path='/vaccumpump' element={<VaccumPump/>}/>
      
      </Routes>
    </BrowserRouter>
  );

}

export default App;
