import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import doublechambervaccumpackagemachine from '../images/doublechambervaccumpackagemachine.png';

function DoubleChamberVaccumPackagingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>DOUBLE CHAMBER VACCUM PACKAGING</h1>
            <img src={doublechambervaccumpackagemachine} />
            <h5>Better known as the “workhorses” within the vacuum chamber industry, our double chamber line of equipment is built to handle years of heavy use and abuse. You will not find an aluminum deck on these machines because they are built to last. </h5>
            <br/>
            <h5>Consisting of a fully enclosed stainless steel base with a reinforced chamber deck and heavy-gauge lid, these machines are anything but light duty. Understanding the high demands set forth by the industry no corners were cut in the construction of our DC series of chamber machines.</h5>
            <br/>
            <h5>We build a complete line of double chamber equipment with items that range from 21” seal bars to over 39” and span a wide variety of widths.</h5>
            <br/>
            <h5>For additional protection and increased function, we fit our double chambers with our PNC-01 programmable water-resistant, IP56-rated control panel. Another feature that sets us apart is the quick disconnect seal bars that can be removed and installed in a matter of seconds. With these easy-to-disconnect seal bars, there is no need to wait for maintenance to show up with tools when a seal wire breaks. By simply having a spare bar on hand, you can be back up and running in under a minute without any special tools.</h5>
            <br/>
            <h5>Time is money in heavy usage applications and we have taken this into account when designing our double chamber machines. Easily removable cushion bars and spacer boards make cleaning the deck surface fast and efficient. Our spring design that assists in lifting the lid provides a smooth and easy back and forth movement that operators will love.</h5>
            <h5>All Promarks vacuum chamber sealers are guaranteed to meet the standards of ETL, UL and NSF.</h5>
            <br/>
            <h5>In order to be the best we believe in using the best, and that is why Busch vacuum pumps come standard in all of our double-chamber machines. Find out for yourself what thousands of others around the world have known for years; choose Promarks for your future vacuum packaging needs.</h5>
            <h5>DC-530 comes standard with our PD-01 basic digital control panel. The PNC-01 is optional equipment on this machine.</h5>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>MODEL DC-900 VACUUM CHAMBER</h2>
            <h5>Stainless Steel Construction</h5>
            <h5>Flat Chamber for Easy Cleaning</h5>
            <h5>PNC-01 Microprocessor Control</h5>
            <h5>10mm Wide Seals</h5>
            <h5>Bladder Activated Sealing</h5>
            <h5>High-Density Plastic Filler Plates</h5>
            <h5>Pump Overload Warning Indicator</h5>
            <h5>Oil Change Indicator</h5>
            <h5>Heavy-Duty Casters</h5>
            <h5>Air Assist</h5>
            <h5>Busch RA0305 (12HP) Vacuum Pump</h5>
            <h5>Quick Release Seal Bar – No Tools Required</h5>
            <h5>Automatic Lid Transfer with Safety System</h5>
            <br/>
            <br/>
            <br/>
            <br/>
            <h5>Higher Capacity Vacuum Pump</h5>
            <h5>Chamber Height</h5>
            <h5>Soft Air</h5>
            <h5>Gas Flush System</h5>
            <h5>Twin Beam Sealing</h5>
            <h5>Top / Bottom Seal System</h5>
            <h5>Mechanical Knife Cut-Off System</h5>
            <h5>Operating Voltage</h5>
            <h5>Custom Sized Machine</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default DoubleChamberVaccumPackagingMachine
