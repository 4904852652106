import React from 'react';
import '../styles/HeroDownSection/herodownsection.css';
import chicken1 from '../icons/quality.png';
import chicken2 from '../icons/group.png';
import chicken3 from '../icons/tools.png';

function HeroDownSection() {
  return (
    <div className='herodownsection'>
      <div className='herodownsection-inner'>
        <div className='card'>
          <div className='logo'>
            <img src={chicken1} alt="" />
          </div>
          <div className='description'>
            <h2>Quality Products</h2>
            <h5>Supplying best machinaries or any other <br/> spare parts we provide.</h5>
          </div>
        </div>
        <div className='card'>
          <div className='logo'>
            <img src={chicken2} alt="" />
          </div>
          <div className='description'>
            <h2>Great Team</h2>
            <h5>We have a hard working team. <br/> You can trust out service 100%.</h5>
          </div>
        </div>
        <div className='card'>
          <div className='logo'>
            <img src={chicken3} alt="" />
          </div>
          <div className='description'>
            <h2>Customer Service</h2>
            <h5>We provide a best customer services, <br/>if it is difficult due to hard demand.</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroDownSection;
