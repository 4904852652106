import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import gizzarddefeatingmachine from '../images/gizzarddefeatingmachine.png';

function GizzardDefeatingMachine() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={gizzarddefeatingmachine} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>GIZZARD DEFATTING MACHINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine made of stainless steel removes the fat from the gizzard trough the action of rubber fingers, fixed on the frame and on a rotating shaft, and sprayed water.</h5>
                </div>
                <div class="data"> 
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Capacity</div>
                            <div class="box len">8.000</div>
                            <div class="box len">B/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">450</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Water cons. at 3 bar</div>
                            <div class="box len">1</div>
                            <div class="box len">m3</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,25</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">54</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shippig Volume</div>
                            <div class="box len">0,2</div>
                            <div class="box len">M<super>3</super></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default GizzardDefeatingMachine
