import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import mdmmachine from '../images/mdmmachine.PNG';
import mdmmachine1 from '../images/mdmmachine1.PNG';
import mdmmachine2 from '../images/mdmmachine2.PNG';
import mdmmachine3 from '../images/mdmmachine3.png';

function MDMMSMMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>MDM / MSM MACHINES</h1>
            <img src={mdmmachine3} />
            <h5>Raw Material: – Chicken – Turkey – Pork – Fish</h5>
            <h5>A1025 - Mechanical Separator</h5>
            <h5>A1050 - Mechanical Separator</h5>
            <h5>A1150 - Mechanical Separator</h5>
            <h5>A3400 - Mechanical Separator</h5>
            <h5>A4500 - Mechanical Separator</h5>
            <br/>
            <img src={mdmmachine} />
            <br/>
            <img src={mdmmachine1} />
            <br/>
            <h5>Application: – deboning – soft separation – grinder</h5>
            <h5>Dimensions: - A. 87 in. (2210 mm) B. 32 in. (813 mm) C. 69 in. (1753 mm)</h5>
            <h5>Capacity: – Input 2500-4000kg/hr depending on raw materials – Throughput will vary due to input material</h5>
            <h5>Raw Material: – Chicken – Turkey – Pork – Fish</h5>
            <h5>Technical: – Belt driven 50Hp (37 kW) – Inline Feed/Deboning Auger</h5>
            <br/>
            <img src={mdmmachine2} />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default MDMMSMMachine
