import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import crusher from '../images/crusher.png';

function Crusher() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={crusher} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CRUSHER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>It is used to crush the feet and the carcasses of chickens or turkeys to be evacuated by vacuum. This low-speed crusher is made of stainless steel, with two low-speed counter revolving shafts and counter-wise hooks. It is driven by motor-gearbox.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.100</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">8(2 motors)</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">330</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,8</div>
                            <div class="box len">M <sup>3</sup></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Crusher
