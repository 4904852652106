import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import dumping from '../images/dumping.png';

function Dumping() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={dumping} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DUMPING</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The ” MINI DUMPING ” is use to automatically unload at the processing plant the birds from container, the “double action” tilting ensures that all drawers are completely emptied during unloading. The system include: tilting device with hydraulic power pack; slides from the tilt container to the receiving belt conveyor; the canopy, the variable speed belt control, and the electric control panel with safety devices.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">4.500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">2.450</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">3.45</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">6,6</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">4.000</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">42</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Dumping
