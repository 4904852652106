import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import fishblackmeatandskinpeelermachine from '../images/fishblackmeatandskinpeelermachine.png';

function FishBlackSkinPeeler() {
  return (
    <div>
      <Navbar/>
      <div class="canwashermachine">
        <div class="inner-canwashermachine">
            <h1>FISH BLACK MEAT AND SKIN PEELER MACHINE</h1>
            <img src={fishblackmeatandskinpeelermachine} />
            <h5>• Model - DRB-YM450</h5>
            <h5>• Current Type - 380V, 50HZ, 3P</h5>
            <h5>• Power - 1500W</h5>
            <h5>• Net Weight - 173KG</h5>
            <h5>• Dimension(L*W*H) - 1400*600*1200 MM</h5>
            <h5>304 Stainless Steel</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default FishBlackSkinPeeler
