import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/canwashermachine.css';
import potatopeelingmachine1 from '../images/potatopeelingmachine1.PNG';
import potatopeelingmachine2 from '../images/potatopeelingmachine2.PNG';

function PotatoPeelingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>POTATO PEELING MACHINE</h1>
            <img src={potatopeelingmachine1} />
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Description</h2>
            <h5>This machine is made by absorbing the domestic and foreign processing characteristic of root kind's vegetables. Use brush principle, widely used in circular, oval-shaped fruits and vegetables suach as ginger, carrot, potato, sweet potato, kiwi and other root potato vegetables washing, peeling.</h5>
            <img src={potatopeelingmachine2} />
            <br/>
            <h2 style={{fontSize:'25px', margin: '20px', color: '#d02729'}}>Features</h2>
            <h5>This machine has a beautiful appearance and easy operation, large cleaning peeling volume , high efficiency , small energy consumption , continuous cleaning , simple operation , long life characteristics. Brush roller materials is made by a special treatment process (rolling from nylon cord) , durable , good wear resistance . Box made of high quality stainless steel, does not rust, and clean. Can be customized according to customer requirements.</h5>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default PotatoPeelingMachine
