import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/birdsproducts.css';
import birdcounter from '../images/birdcounter.png';

function BirdCounter() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={birdcounter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>BIRD COUNTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine can be installed on any kind of killing or eviscerating line and it is suitable for any capacity up to 8000 b/h, A guide move towards the sensor only the shackle with the bird. The signal is transmitted to an electronic counting unit with e zero reset. The structure and guide-bars are made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">550</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">0,1</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">30</div>
                            <div class="box len">kg</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  );
}

export default BirdCounter;
