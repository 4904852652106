import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import dropoffstation180 from '../images/dropoffstation180.png';
import dropoffstation232 from '../images/dropoffstation232.png';

function DropoffStations() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={dropoffstation180} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DROP OFF STATION 180</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This unit consists of a pneumatic cylinder activated by an electro-valve. The drop off is complete with frame to be bolted to the overhead conveyor and all necessary pneumatic and electrical fittings.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table"> 
                    <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">685</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">140</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">540</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Air cons.</div>
                            <div class="box len">0,7</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">77</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,42</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={dropoffstation232} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DROP OFF STATION 232</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This unit consists of a pneumatic cylinder activated by an electro-valve. The drop off is complete with frame to be bolted to the overhead conveyor and all necessary pneumatic and electrical fittings.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table"> 
                    <div class="row">
                            <div class="box">Length</div>
                            <div class="box len">385</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">140</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">540</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Air cons.</div>
                            <div class="box len">0,7</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">77</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,42</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default DropoffStations;
