import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import precutmachine from '../images/precutmachine.png';
import wingtipcutter from '../images/wingtipcutter.png';
import wingcutter from '../images/wingcutter.png';
import wingcenterpiececutter from '../images/wingcenterpiececutter.png';
import frontpiecewithbelt from '../images/frontpiecewithbelt.png';
import halvingmachine from '../images/halvingmachine.png';
import automatichocktrimmer from '../images/automatichocktrimmer.png';
import saddlecutter from '../images/saddlecutter.png';
import legprocessor from '../images/legprocessor.png';
import thighanddrumsticksplitter from '../images/thighanddrumsticksplitter.png';
import automaticunloader from '../images/automaticunloader.png';

function CutupModules() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={precutmachine} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>PRE CUT MACHINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used ti cut the chicken vent across. The machine is driven by the overhead conveyor. The opening is made by an adjectable static blade. The machine is made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">900</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">130</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={wingtipcutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>WING TIP CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to cut off the wing tip through the joint. The machine is mounted on the cut-up line and it is manually adjustable in height and width. The cut is achieved thanks to two rotating blades. Each blade is controlled by an electric motor and protected with a proper guard. To get the best yield the weight difference in the flock cannot exceed 500gr The machine is made of stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">815</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,5(2 motors)</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">75</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={wingcenterpiececutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>WING CENTER PIECE CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to cut off the wing part through the second joint, thanks to two circular blades which are easily adjustable in height and width. The machine is stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">815</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,5(2 motors)</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">75</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={wingcutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>WING CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to cut anatomically, at the joint the wings of the chicken. The machine is mounted on the cut-up line and it is manually adjustable in height and width. The cut is achieved thanks to two rotating blades. Each blade is controlled by an electric motor and protected with a proper guard. To get the best yield the weight difference in the flock cannot exceed 500gr The machine is made of stainless steel. </h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">840</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">815</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,5(2 motors)</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">85</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,52</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={frontpiecewithbelt} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>FRONT PIECE CUTTER WITH BELT</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Installed on the cut-up line cuts the front part of the chicken (breast with bone). The cut is achieved through two rotating blades driven by electric motors, while the chicken is kept perfectly positioned by some guards. To get the best yield the weight difference in the flock cannot exceed 500gr The machine is stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">840</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">815</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,87(3 motors)</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">95</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,50</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={halvingmachine} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>HALVING MACHINE</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to cut the backbone after the front piece cutter. The cut is achieved through a rotating blade driven by electric motor. To get the best yield the weight difference in the flock cannot exceed 500gr. The machine is adjustable in height and made off stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,75</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">65</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,2</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={automatichocktrimmer} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>AUTOMATIC HOCK TRIMMER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>This machine trims the hocks of the birds to have a better presentation of the product. It is built in stainless steel and it is height adjustable.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                    <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">400</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">400</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">0,75</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">55</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,2</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={saddlecutter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>SADDLE CUTTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to split the saddle either with the backbone or without (two blades cut). The cut is achieved through a rotating blade, driven by electric motor, (double blades in case of saddle without backbone) which split the saddle. To get the best yield the weight difference in the flock cannot exceed 500gr The machine is made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                    <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">0,37</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">65</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,3</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={legprocessor} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>LEG PROCESSOR</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to anatomically remove the two whole legs from the back saddle. The machine is installed on the cut-up line and it operates through some fixed blades, and  wheel pulling the legs. The oyster remains attached to the thigh. The machine is made of stainless steel and it is supplied with drive unit and variable speed frequency converter.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                    <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">1.000</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">500</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Instaleld</div>
                            <div class="box len">1,1</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">120</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={thighanddrumsticksplitter} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>THIGH AND DRUMSTICK SPLITTER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used to split drumsticks from the tight chicken legs. Thighs automatically fall down and only drumsticks remain hung on the shackles. The drumstick/thigh separation is achieved thanks to a steady blade and a rotating wheel that is synchronized with the line speed. Thanks to its special design, the wheel holds the leg in the anatomic position to make an easier joint cut. All stainless steel and nylon.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                    <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">450</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">700</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">80</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,5</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={automaticunloader} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>AUTOMATIC UNLOADER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>Used for the automatic unloading of drumsticks/legs from the shackles. Made of stainless steel, this unit is installed on the cut up line.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Total Weight</div>
                            <div class="box len">8</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">0,01</div>
                            <div class="box len">M <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CutupModules
