import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/ProductSelector/productselector.css';

function ProductSelector() {
  return (
    <div className='selector'>
      <div className='selector-inner'>
        <div className='header'>
            <h1>Categories</h1>
        </div>
        <div className='links'>
            <NavLink className='link' to='/products'>Meat Processing</NavLink>
            <NavLink className='link' to='/fish'>Fish Processing</NavLink>
            <NavLink className='link' to='/feed'>Feed Industry</NavLink>
            <NavLink className='link' to='/vegefood'>Vegetable & Food Industry</NavLink>
            <NavLink className='link' to='/chicken'>Chicken and Turkey</NavLink>
            <NavLink className='link' to='/package'>Packaging Solutions</NavLink>
            <NavLink className='link' to='/pig'>Pig Processing</NavLink>
            <NavLink className='link' to='/other'>Other Machinaries</NavLink>
        </div>
      </div>
    </div>
  );
}

export default ProductSelector;
