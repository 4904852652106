import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/css/canwashermachine.css';
import blastfrezzingmachine from '../images/blastfrezzingmachine.PNG';

function BlastFreezingMachine() {
  return (
    <div>
      <Navbar/>
      <div className="canwashermachine">
        <div className="inner-canwashermachine">
            <h1>BLAST FREEZING MACHINE</h1>
            <img src={blastfrezzingmachine} />
            <br/>
            <h5>Model : TFL500</h5>
            <h5>Power: 100p</h5>
            <h5>Size:20000*3000*3000mm</h5>
            <h5>The whole is 304 stainless steel, and the polyurethane insulation layer is 150mm. The outside of the board is 0.8mm and the inside is 0.6mm, the mesh belt bracket is 50*50/3mm, and the shield is 2mm. Single layer mesh belt. The lowest temperature can reach ab</h5>
            <h2 ></h2>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BlastFreezingMachine
