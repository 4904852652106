import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/css/birdsproducts.css';
import discomaticpluckersp4 from '../images/discomaticpluckersp4.png';
import discomaticpluckersp5 from '../images/discomaticpluckersp5.png';
import discomaticpluckersp6 from '../images/discomaticpluckersp6.png';
import discomaticpluckersp7t from '../images/discomaticpluckersp7t.png';
import cintromaticplucker from '../images/cintromaticplucker.png';

function Plickers() {
  return (
    <div>
      <Navbar/>
      <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={discomaticpluckersp4} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DISCOMATIC PLUCKER SP4</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic plucker Discomatic SP is composed of single and independent banks, so it is possible to adjust them according to any size of poultry. the plucker is manufactured in different lenght model according to the speed of the line. The machine is made of stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Mdoel</div>
                            <div class="box len">SP72</div>
                            <div class="box len">SP88</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">3.600</div>
                            <div class="box len">4.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.800</div>
                            <div class="box len">1.800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Disks on each bank</div>
                            <div class="box len">18</div>
                            <div class="box len">22</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Water</div>
                            <div class="box len">1.200</div>
                            <div class="box len">1.400</div>
                            <div class="box len">I / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Motors</div>
                            <div class="box len">8</div>
                            <div class="box len">8</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Power</div>
                            <div class="box len">12</div>
                            <div class="box len">18</div>
                            <div class="box len">kw</div>
                        </div>
                    
                        <div class="row">
                            <div class="box">Net weight</div>
                            <div class="box len">1.070</div>
                            <div class="box len">1.135</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">2,7</div>
                            <div class="box len">2,8</div>
                            <div class="box len">m <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={discomaticpluckersp5} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DISCOMATIC PLUCKER SP5</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic plucker Discomatic SP is composed of single and independent banks, so it is possible to adjust them according to any size of poultry. the plucker is manufactured in different lenght model according to the speed of the line. The machine is made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Mdoel</div>
                            <div class="box len">SP55</div>
                            <div class="box len">SP65</div>
                            <div class="box len">SP80</div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">2.500</div>
                            <div class="box len">2.700</div>
                            <div class="box len">3.250</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.800</div>
                            <div class="box len">1.800</div>
                            <div class="box len">1.800</div>
                        </div>
                        <div class="row">
                            <div class="box">Disks on each bank</div>
                            <div class="box len">11</div>
                            <div class="box len">13</div>
                            <div class="box len">16</div>
                        </div>
                        <div class="row">
                            <div class="box">Water</div>
                            <div class="box len">1.200</div>
                            <div class="box len">1.400</div>
                            <div class="box len">I / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Motors</div>
                            <div class="box len">800</div>
                            <div class="box len">1.000</div>
                            <div class="box len">1.200</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Motors</div>
                            <div class="box len">5</div>
                            <div class="box len">5</div>
                            <div class="box len">10</div>
                        </div>
                        <div class="row">
                            <div class="box">Total power</div>
                            <div class="box len">11,25</div>
                            <div class="box len">11,25</div>
                            <div class="box len">15</div>
                        </div>
                        <div class="row">
                            <div class="box">Net weight</div>
                            <div class="box len">800</div>
                            <div class="box len">835</div>
                            <div class="box len">1.190</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">3,8</div>
                            <div class="box len">4</div>
                            <div class="box len">4,5</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={discomaticpluckersp6} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DISCOMATIC PLUCKER SP6</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic plucker Discomatic SP is composed of single and independent banks, so it is possible to adjust them according to any size of poultry. the plucker is manufactured in different lenght model according to the speed of the line. The machine is made of stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Mdoel</div>
                            <div class="box len">SP108</div>
                            <div class="box len">SP132</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">3.650</div>
                            <div class="box len">4.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">1.800</div>
                            <div class="box len">1.800</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Disks on each bank</div>
                            <div class="box len">18</div>
                            <div class="box len">22</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Water</div>
                            <div class="box len">1.200</div>
                            <div class="box len">1.400</div>
                            <div class="box len">I / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Motors</div>
                            <div class="box len">12</div>
                            <div class="box len">12</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Total power</div>
                            <div class="box len">18</div>
                            <div class="box len">27</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Net weight</div>
                            <div class="box len">1.470</div>
                            <div class="box len">1.700</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">5,8</div>
                            <div class="box len">6</div>
                            <div class="box len">m <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={discomaticpluckersp7t} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>DISCOMATIC PLUCKER SP7 T</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The plucker Discomatic SPT for chicken and turkey is composed by 7 single and independent banks, so it is possible to adjust them according to the size of poultry.On the bottom bank special cones with finger is used to pluck the crop and the wings of the turkey. The machine is made of stainless steel.</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Mdoel</div>
                            <div class="box len">SP126</div>
                            <div class="box len">SP154</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">3.650</div>
                            <div class="box len">4.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">2.200</div>
                            <div class="box len">2.200</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Disks on each bank</div>
                            <div class="box len">18</div>
                            <div class="box len">22</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Water</div>
                            <div class="box len">1.200</div>
                            <div class="box len">1.400</div>
                            <div class="box len">I / h</div>
                        </div>
                        <div class="row">
                            <div class="box">Total Motors</div>
                            <div class="box len">16</div>
                            <div class="box len">18</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Total power</div>
                            <div class="box len">27</div>
                            <div class="box len">40,5</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Net weight</div>
                            <div class="box len">1.670</div>
                            <div class="box len">1.880</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">8,3</div>
                            <div class="box len">9,6</div>
                            <div class="box len">m <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="birds-container">
        <div class="birds-inner">
            <div class="left">
                <div class="img">
                    <img src={cintromaticplucker} alt="product image"/>
                </div>
            </div>
            <div class="right">
                <div class="name">
                    <h2>CONTROMATICPLUCKER</h2>
                </div>
                <div class="description">
                    <h2>Description</h2>
                    <h5>The automatic plucker CR 22 is highly recommended as first plucher on hight speed defeathering line. The machine works thanks to two main shafts equipped with 22 aluminum rollers holding fingers; 12 rollers run downwards, driven by rubber gears, 10 run upwards. The frame and the covers are made of stainless steel</h5>
                </div>
                <div class="data">
                    <h2>Technical Data</h2>
                    <div class="table">
                        <div class="row">
                            <div class="box">Mdoel</div>
                            <div class="box len">Chicken</div>
                            <div class="box len">Turkey</div>
                            <div class="box len"></div>
                        </div>
                        <div class="row">
                            <div class="box">Lenght</div>
                            <div class="box len">2.600</div>
                            <div class="box len">2.600</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Width</div>
                            <div class="box len">2.850</div>
                            <div class="box len">2.850</div>
                            <div class="box len">mm</div>
                        </div>
                        <div class="row">
                            <div class="box">Height</div>
                            <div class="box len">1.530</div>
                            <div class="box len">1.530</div>
                            <div class="box len">I/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Cold water cons.</div>
                            <div class="box len">800</div>
                            <div class="box len">800</div>
                            <div class="box len">I/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Hot water cons.</div>
                            <div class="box len">600</div>
                            <div class="box len">600</div>
                            <div class="box len">I/h</div>
                        </div>
                        <div class="row">
                            <div class="box">Motors</div>
                            <div class="box len">4</div>
                            <div class="box len">4</div>
                            <div class="box len">N</div>
                        </div>
                        <div class="row">
                            <div class="box">Power Installed</div>
                            <div class="box len">12</div>
                            <div class="box len">22</div>
                            <div class="box len">kw</div>
                        </div>
                        <div class="row">
                            <div class="box">Total weight</div>
                            <div class="box len">1.620</div>
                            <div class="box len">1.700</div>
                            <div class="box len">kg</div>
                        </div>
                        <div class="row">
                            <div class="box">Shipping Volume</div>
                            <div class="box len">6,5</div>
                            <div class="box len">6,5</div>
                            <div class="box len">m <span>3</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Footer/>
    </div>
  )
}

export default Plickers
